import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";

import Pagination from "react-js-pagination";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../contexts/ContextProvider";

import { useNavigate } from "react-router-dom";

const HawbSearch = () => {
  const [SearchValue, setSearchValue] = useState();
  const [OriginalData1, setOriginalData1] = useState();
  const { user, role } = useStateContext();

  const [Data, setData] = useState();

  const navigate = useNavigate();

  const HandleSearch = async (e) => {
    e.preventDefault();
    const q = query(
      collection(db, "HAWB Reports"),
      where("MainInfo.hawbNo", "==", SearchValue)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        navigate(`/hawbReports/${doc.id}`);
      });
    } else {
      toast.warning("Wrong Invoice Number", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSearchValue();
    }
  };
  const getData = () => {
    if (role == "Air Export & Local Charge(AEXP)") {
      const q = query(collection(db, "HAWB Reports"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let tempArr = [];
        querySnapshot.forEach((doc) => {
          tempArr.push({ ...doc.data() });
        });
        tempArr.sort((a, b) =>
          a.MainInfo.hawbNo.localeCompare(b.MainInfo.hawbNo)
        );
        setData(tempArr);
        setOriginalData1(tempArr);
      });
    }
  };

  useEffect(() => {
    getData();
  }, [role]);

  //Pagination 1
  const [currentAirPage, setCurrentAirPage] = useState(1);
  const AirreportsPerPage = 12;

  const filteredAirReports = Data
    ? Data.filter(
        (data) =>
          !SearchValue ||
          (data.MainInfo.hawbNo &&
            data.MainInfo.hawbNo
              .toLowerCase()
              .includes(SearchValue.toLowerCase()))
      )
    : [];
  const indexOfLastAirUser = currentAirPage * AirreportsPerPage;
  const indexOfFirstAirUser = indexOfLastAirUser - AirreportsPerPage;
  const currentAirList = filteredAirReports.slice(
    indexOfFirstAirUser,
    indexOfLastAirUser
  );

  return (
    <section className="bg-gray-100 dark:bg-gray-900">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
        <div className="w-full bg-slate-200 rounded-lg shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="w-full pt-3 pb-10 pl-10 pr-5 relative overflow-x-auto">
            <p className="text-center font-bold text-xl underline mt-5 mb-3">
              View Reports
            </p>

            <div className="flex flex-row justify-between ">
              <p className="text-14 font-bold pt-1">Report Search: </p>
              <div className="flex gap-3 mb-3">
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={SearchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="bg-gray-50 border w-96 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Hawb No...."
                  required
                />
                <button
                  type="submit"
                  className=" text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={(e) => HandleSearch(e)}
                >
                  Search
                </button>
              </div>
            </div>

            {Data && (
              <>
                <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th className="p-2 border border-gray-400">Hawb Num</th>
                      <th className="p-2 border border-gray-400">Place</th>
                      <th className="p-2 border border-gray-400">
                        Executed On
                      </th>

                      <th className="p-2 border border-gray-400">
                        Flight Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAirList.map((item, index) => (
                      <tr key={index}>
                        <td className="p-2 border border-gray-400">
                          {item.MainInfo.hawbNo}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.MainInfo.Place}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.MainInfo.ExecutedOn}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.MainInfo.FlightDate}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="pagination flex  justify-start mt-4 ">
                  <Pagination
                    activePage={currentAirPage}
                    itemsCountPerPage={AirreportsPerPage}
                    totalItemsCount={filteredAirReports.length}
                    pageRangeDisplayed={5}
                    onChange={setCurrentAirPage}
                    itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
                    linkClass="block"
                    activeLinkClass="text-gray-200"
                    disabledClass="opacity-50 cursor-not-allowed"
                  />
                </div>
              </>
            )}
          </div>
          {/* <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <p className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 dark:text-white">
          Search for Air Import/Export Reports
        </p>
        <form className="space-y-4 md:space-y-6" action="#">
          <div>
            <input
              type="text"
              name="search"
              id="search"
              value={SearchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Invoice No...."
              required
            />
          </div>

          <button
            type="submit"
            className="w-full text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            onClick={(e) => HandleSearch(e)}
          >
            Search
          </button>
        </form>
      </div> */}
        </div>
      </div>
    </section>
  );
};

export default HawbSearch;
