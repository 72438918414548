import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";

import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Tos = () => {
  const [Tos, setTos] = useState();
  const [TosList, setTosList] = useState([]);
  const ToastError = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const AddTos = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "Tos"), {
      desc: Tos,
    }).then(() => {
      setTos("");
      toast.success("Successfully added", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    const q = query(collection(db, "Tos"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data(), id: doc.id });
      });
      setTosList(tempArr);
    });
    return () => unsubscribe();
  }, []);

  const deleteFile = (id) => {
    const currencyRef = doc(collection(db, "Tos"), id);
    deleteDoc(currencyRef).then(() => {
      toast.success("Successfully Deleted", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  return (
    <div className="flex flex-col items-center md:items-start gap-3  mt-20 px-6 py-8 mx-auto lg:py-0">
      <ToastContainer />
      <div className="w-full bg-white rounded-xl shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            Add TOS Details
          </h1>
          <form className="space-y-4 md:space-y-6" action="#">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Tos
              </label>
              <input
                type="text"
                name="tos"
                id="tos"
                value={Tos}
                onChange={(e) => setTos(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="tos..."
                required
              />
            </div>

            <button
              type="submit"
              className="w-full text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={AddTos}
            >
              Add
            </button>
          </form>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <p className="p-2 text-14 font-semibold bg-gray-800 text-white">
            List of Tos Value
          </p>
          <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="p-2 border border-gray-400">SL No.</th>
                <th className="p-2 border border-gray-400">Tos Name</th>
                <th className="p-2 border border-gray-400">Action</th>
              </tr>
            </thead>
            <tbody>
              {TosList &&
                TosList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {index + 1}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {item.desc}
                      </td>

                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        <button
                          className="rounded-full bg-gray-800 text-white p-2 text-xs"
                          onClick={() => deleteFile(item.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Tos;
