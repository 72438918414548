import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";

const EditInvoiceList = () => {
  const [Data, setData] = useState();
  const [Data2, setData2] = useState();

  const { user, role } = useStateContext();

  const [inv1, setInv1] = useState();
  const [inv2, setInv2] = useState();

  const getData = () => {
    if (role === "Air Export & Local Charge(AEXP)") {
      setInv1("AIR Export Invoice");
      setInv2("Local Charge(AEXP)");

      const q = query(
        collection(db, "EditReports"),
        where("invoiceType", "==", "AIR Export Invoice")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const promises = querySnapshot.docs.map((doc) => {
          const q2 = query(collection(db, "AIR Reports"));
          return getDocs(q2).then((querySnapshot) => {
            const tempArr = [];
            querySnapshot.forEach((doc2) => {
              if (doc2.id === doc.data().id) {
                tempArr.push({ id: doc2.id, ...doc2.data() });
              }
            });
            return tempArr;
          });
        });

        Promise.all(promises).then((results) => {
          const mergedArr = results.flat();
          setData(mergedArr);
        });
      });

      const q3 = query(
        collection(db, "EditReports"),
        where("invoiceType", "==", "Local Charge(AEXP)")
      );
      const unsubscribe2 = onSnapshot(q3, (querySnapshot) => {
        const promises = querySnapshot.docs.map((doc) => {
          const q4 = query(collection(db, "AIR Reports"));
          return getDocs(q4).then((querySnapshot) => {
            const tempArr = [];
            querySnapshot.forEach((doc2) => {
              if (doc2.id === doc.data().id) {
                tempArr.push({ id: doc2.id, ...doc2.data() });
              }
            });
            return tempArr;
          });
        });

        Promise.all(promises).then((results) => {
          const mergedArr = results.flat();
          setData2(mergedArr);
        });
      });
    } else if (role == "Air Import & Sea Import") {
      setInv1("AIR Import Invoice");
      setInv2("SEA Import Invoice");
      const q = query(
        collection(db, "EditReports"),
        where("invoiceType", "==", "AIR Import Invoice")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const promises = querySnapshot.docs.map((doc) => {
          const q2 = query(collection(db, "AIR Reports"));
          return getDocs(q2).then((querySnapshot) => {
            const tempArr = [];
            querySnapshot.forEach((doc2) => {
              if (doc2.id === doc.data().id) {
                tempArr.push({ id: doc2.id, ...doc2.data() });
              }
            });
            return tempArr;
          });
        });

        Promise.all(promises).then((results) => {
          const mergedArr = results.flat();
          setData(mergedArr);
        });
      });

      const q3 = query(
        collection(db, "EditReports"),
        where("invoiceType", "==", "SEA Import Invoice")
      );
      const unsubscribe2 = onSnapshot(q3, (querySnapshot) => {
        const promises = querySnapshot.docs.map((doc) => {
          const q4 = query(collection(db, "SEA Reports"));
          return getDocs(q4).then((querySnapshot) => {
            const tempArr = [];
            querySnapshot.forEach((doc2) => {
              if (doc2.id === doc.data().id) {
                tempArr.push({ id: doc2.id, ...doc2.data() });
              }
            });
            return tempArr;
          });
        });

        Promise.all(promises).then((results) => {
          const mergedArr = results.flat();
          setData2(mergedArr);
        });
      });
    } else if (role == "Sea Export & Local Charge(SEXP)") {
      setInv1("SEA Export Invoice");
      setInv2("Local Charge(SEXP)");
      const q = query(
        collection(db, "EditReports"),
        where("invoiceType", "==", "SEA Export Invoice")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const promises = querySnapshot.docs.map((doc) => {
          const q2 = query(collection(db, "SEA Reports"));
          return getDocs(q2).then((querySnapshot) => {
            const tempArr = [];
            querySnapshot.forEach((doc2) => {
              if (doc2.id === doc.data().id) {
                tempArr.push({ id: doc2.id, ...doc2.data() });
              }
            });
            return tempArr;
          });
        });

        Promise.all(promises).then((results) => {
          const mergedArr = results.flat();
          setData(mergedArr);
        });
      });

      const q3 = query(
        collection(db, "EditReports"),
        where("invoiceType", "==", "Local Charge(SEXP)")
      );
      const unsubscribe2 = onSnapshot(q3, (querySnapshot) => {
        const promises = querySnapshot.docs.map((doc) => {
          const q4 = query(collection(db, "SEA Reports"));
          return getDocs(q4).then((querySnapshot) => {
            const tempArr = [];
            querySnapshot.forEach((doc2) => {
              if (doc2.id === doc.data().id) {
                tempArr.push({ id: doc2.id, ...doc2.data() });
              }
            });
            return tempArr;
          });
        });

        Promise.all(promises).then((results) => {
          const mergedArr = results.flat();
          setData2(mergedArr);
        });
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
      <div className="w-full bg-slate-200 rounded-lg shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <p className="text-center text-base p-2 font-bold">Edit Reports</p>
        <div className="w-full pt-3 pb-10 pl-10 pr-5 relative overflow-x-auto gap-3">
          {Data && (
            <>
              <p className="text-14 font-semibold p-2 bg-gray-800  text-white">
                {inv1}
              </p>
              <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="p-2 border border-gray-400">Invoice Num</th>
                    <th className="p-2 border border-gray-400">Invoice Date</th>
                    <th className="p-2 border border-gray-400">Invoice Type</th>

                    <th className="p-2 border border-gray-400">ETA</th>
                    <th className="p-2 border border-gray-400">ETD</th>
                    <th className="p-2 border border-gray-400">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((item, index) => {
                    return (
                      <tr>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceNum}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceDate}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceType}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Eta}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Etd}
                        </td>
                        <td className="p-2 border border-gray-400">
                          <Link
                            to={`/EditSingleReports/${item.id}?invoiceType=${item.GeneralInfo.InvoiceType}`}
                            className="flex w-fit text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}

          {Data2 && (
            <div className="mt-5">
              <p className="text-14 font-semibold p-2 bg-gray-800  text-white">
                {inv2}
              </p>
              <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="p-2 border border-gray-400">Invoice Num</th>
                    <th className="p-2 border border-gray-400">Invoice Date</th>
                    <th className="p-2 border border-gray-400">Invoice Type</th>

                    <th className="p-2 border border-gray-400">ETA</th>
                    <th className="p-2 border border-gray-400">ETD</th>
                    <th className="p-2 border border-gray-400">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Data2.map((item, index) => (
                    <tr key={index}>
                      <td className="p-2 border border-gray-400">
                        {item.GeneralInfo.InvoiceNum}
                      </td>
                      <td className="p-2 border border-gray-400">
                        {item.GeneralInfo.InvoiceDate}
                      </td>
                      <td className="p-2 border border-gray-400">
                        {item.GeneralInfo.InvoiceType}
                      </td>
                      <td className="p-2 border border-gray-400">
                        {item.GeneralInfo.Eta}
                      </td>
                      <td className="p-2 border border-gray-400">
                        {item.GeneralInfo.Etd}
                      </td>
                      <td className="p-2 border border-gray-400">
                        <Link
                          to={`/EditSingleReports/${item.id}?invoiceType=${item.GeneralInfo.InvoiceType}`}
                          className="flex w-fit text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditInvoiceList;
