import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";

import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BankName = () => {
  const [BankName, setBankName] = useState(" ");
  const [Address, setAddress] = useState(" ");
  const [AcName, setAcName] = useState(" ");
  const [AcNo, setAcNo] = useState(" ");
  const [routing, setRouting] = useState(" ");
  const [swift, setSwift] = useState(" ");

  const [selectedBank, setSelectedBank] = useState(null);
  const [BankNameList, setBankNameList] = useState([]);
  const ToastError = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const AddBankName = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "BankName"), {
      name: BankName,
      address: Address,
      ac_name: AcName,
      ac_no: AcNo,
      routing: routing,
      swift: swift,
    }).then(() => {
      setBankName(" ");
      setAddress(" ");
      setAcName(" ");
      setAcNo(" ");

      setRouting(" ");
      setSwift(" ");
      toast.success("Successfully added", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    const q = query(collection(db, "BankName"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data(), id: doc.id });
      });
      setBankNameList(tempArr);
    });
    return () => unsubscribe();
  }, []);

  const handleEditClick = (bank) => {
    setSelectedBank(bank);
    setBankName(bank.name);
    setAddress(bank.address);
    setAcName(bank.ac_name);
    setAcNo(bank.ac_no);
    setRouting(bank.routing);
    setSwift(bank.swift);
  };

  const handleUpdateBankName = async (e) => {
    e.preventDefault();

    const bankRef = doc(db, "BankName", selectedBank.id);
    await updateDoc(bankRef, {
      name: BankName,
      address: Address,
      ac_name: AcName,
      ac_no: AcNo,
      routing: routing,
      swift: swift,
    }).then(() => {
      setSelectedBank(null);
      setBankName("");
      setAddress("");
      setAcName("");
      setAcNo("");
      setRouting("");
      setSwift("");
      toast.success("Successfully updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  return (
    <div className="items-center md:items-start gap-3  mt-20 px-6 py-8 mx-auto md:h-screen lg:py-0">
      <ToastContainer />
      <div className="w-full bg-white rounded-xl shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className=" p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            Add Bank Information
          </h1>
          <form className="" action="#">
            <div className="flex gap-3 py-3">
              <div className="w-1/2">
                <label
                  htmlFor="bankName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Write Bank Name
                </label>
                <input
                  type="text"
                  name="bankName"
                  value={BankName}
                  onChange={(e) => setBankName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Bank Name.."
                  required
                />
              </div>

              <div className="w-1/2">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Write Bank Address
                </label>
                <input
                  type="text"
                  name="address"
                  value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Address..."
                  required
                />
              </div>
            </div>
            <div className="flex gap-3 py-3">
              <div className="w-1/2">
                <label
                  htmlFor="AcName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Account Name
                </label>
                <input
                  type="text"
                  name="AcName"
                  value={AcName}
                  onChange={(e) => setAcName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Account Holder Name.."
                  required
                />
              </div>

              <div className="w-1/2">
                <label
                  htmlFor="AcNo"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Account Number
                </label>
                <input
                  type="text"
                  name="AcNo"
                  value={AcNo}
                  onChange={(e) => setAcNo(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="A/C No..."
                />
              </div>
            </div>
            <div className="flex gap-3 py-3">
              <div className="w-1/2">
                <label
                  htmlFor="routing"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Routing Number
                </label>
                <input
                  type="text"
                  name="routing"
                  value={routing}
                  onChange={(e) => setRouting(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Routing Number.."
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="swift"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Swift Code
                </label>
                <input
                  type="text"
                  name="swift"
                  value={swift}
                  onChange={(e) => setSwift(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Swift Code.."
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-32 text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={selectedBank ? handleUpdateBankName : AddBankName}
            >
              {selectedBank ? "Update" : "Add"}
            </button>
          </form>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl shadow dark:border mt-10 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            List Details
          </h1>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 w-32 font-medium text-center text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                  Bank Name
                </th>
                <th className="px-4 py-2 w-32 font-medium text-center text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                  Account Name
                </th>
                <th className="px-4 py-2 w-32 font-medium text-center text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                  Account Number
                </th>
                <th className="px-4 py-2 w-32 font-medium text-center text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                  Address
                </th>
                <th className="px-4 py-2 w-32 font-medium text-center text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {BankNameList.map((bank, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 w-32 font-medium text-left text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                    {bank.name}
                  </td>
                  <td className="px-4 py-2 w-32 font-medium text-left text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                    {bank.ac_name}
                  </td>
                  <td className="px-4 py-2 w-32 font-medium text-left text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                    {bank.ac_no}
                  </td>
                  <td className="px-4 py-2 w-32 font-medium text-left text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                    {bank.address}
                  </td>
                  <td className="px-4 py-2 w-32 font-medium text-xs text-center tracking-wider uppercase border-1 border-gray-700 bg-gray">
                    <button
                      onClick={() => handleEditClick(bank)}
                      className="text-[#FF5C8E] hover:underline focus:outline-none"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BankName;
