import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import "./App.css";
import Sidebar from "./pages/Sidebar";
import Navbar from "./pages/Navbar";
import { useStateContext } from "./contexts/ContextProvider";
import Login from "./pages/Login";
import SeaEntry from "./pages/SeaEntry";
import Home from "./pages/Home";
import AddUser from "./admin/AddUser";
import InvoiceType from "./admin/InvoiceType";
import Currency from "./admin/Currency";
import BankName from "./admin/BankName";
import To from "./admin/To";
import Hbl from "./admin/Hbl";
import Pol from "./admin/Pol";
import Destination from "./admin/Destination";
import Logistics from "./admin/Logistics";
import JobStatus from "./admin/JobStatus";
import Tos from "./admin/Tos";
import Description from "./admin/Description";
import ContainerSize from "./admin/ContainerSize";
import Etd from "./admin/Etd";
import DraftsEntry from "./admin/DraftsEntry";
import ViewSingleEntry from "./admin/ViewSingleEntry";
import SeaReportSearch from "./pages/SeaReportSearch";
import AirReportSearch from "./pages/AirReportSearch";
import SeaImportExport from "./Reports/SeaImportExport";
import AirImportExport from "./Reports/AirImportExport";
import PaymentMode from "./admin/ContainerSize";
import ShipperName from "./admin/ShipperName";
import Consignee from "./admin/Consignee";
import Hawb from "./pages/Hawb";
import HawbSearch from "./pages/HawbSearch";
import HawbReports from "./pages/HawbReports";
import UserDetails from "./admin/UserDetails";
import EditInvoiceList from "./pages/EditInvoiceList";
import EditInvoice from "./pages/EditInvoice";
import EditHawbPage from "./pages/EditHawbPage";
import HawbEdit from "./pages/HawbEdit";
import AirReportsViewer from "./pages/AirReportsViewer";
import SeaReportView from "./pages/SeaReportView";

const App = () => {
  const { user, role, activeMenu, setActiveMenu } = useStateContext();
  console.log(role);
  return (
    <div>
      <BrowserRouter>
        {user ? (
          <>
            <div className="flex relative dark:bg-main-dark-bg">
              <div
                className="fixed right-4 bottom-4"
                style={{ zIndex: "1000" }}
              >
                <TooltipComponent content="settings" position="Top">
                  <button>
                    <FiSettings />
                  </button>
                </TooltipComponent>
              </div>
              {activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )}
              <div
                className={
                  activeMenu
                    ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                    : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
                }
              >
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                  <Navbar />
                </div>
                <div>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/SeaEntry" element={<SeaEntry />} />
                    <Route path="/addUser" element={<AddUser />} />
                    <Route path="/userDetails" element={<UserDetails />} />
                    {/*  <Route path="/InvoiceType" element={<InvoiceType />} />
                    <Route path="/hbl" element={<Hbl />} />
                    <Route path="/ContainerSize" element={<ContainerSize />} /> */}
                    <Route path="/unit" element={<Etd />} />
                    <Route path="/PaymentMode" element={<PaymentMode />} />
                    <Route path="/CurrancyInfo" element={<Currency />} />
                    <Route path="/bankName" element={<BankName />} />
                    <Route path="/To" element={<To />} />
                    <Route path="/pol" element={<Pol />} />
                    <Route path="/destination" element={<Destination />} />
                    <Route path="/Logistics" element={<Logistics />} />
                    <Route path="/JobStatus" element={<JobStatus />} />
                    <Route path="/tos" element={<Tos />} />
                    <Route path="/shippername" element={<ShipperName />} />
                    <Route path="/consignee" element={<Consignee />} />
                    <Route path="/description" element={<Description />} />
                    <Route path="/draftEntry" element={<DraftsEntry />} />
                    <Route path="/hawb" element={<Hawb />} />
                    <Route path="/hawbsearch" element={<HawbSearch />} />
                    <Route path="/hawbEditList" element={<HawbEdit />} />
                    <Route path="/hawbReports/:id" element={<HawbReports />} />
                    <Route path="/HawbEdit/:id" element={<EditHawbPage />} />
                    <Route path="/EditReports" element={<EditInvoiceList />} />
                    <Route
                      path="/ViewAirReports"
                      element={<AirReportsViewer />}
                    />
                    <Route path="/ViewSeaReports" element={<SeaReportView />} />
                    <Route
                      path="/EditSingleReports/:id"
                      element={<EditInvoice />}
                    />
                    <Route
                      path="/EntryReview/:id"
                      element={<ViewSingleEntry />}
                    />
                    <Route
                      path="/SeaReports/:id"
                      element={<SeaImportExport />}
                    />
                    <Route
                      path="/AirReports/:id"
                      element={<AirImportExport />}
                    />
                    <Route
                      path="/SeaImportExport"
                      element={<SeaReportSearch />}
                    />
                    SeaImportExport
                    <Route
                      path="/AirImportExport"
                      element={<AirReportSearch />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
