import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { collection, doc, addDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ViewFiled from "../components/ViewFiled";
import TextAreaInput from "../components/TextAreaInput";

const ViewSingleEntry = () => {
  const [DetailsInfo, setDetailsInfo] = useState([]);

  const [GeneralInfo, setGeneralInfo] = useState({});

  const { id } = useParams();

  const invoiceType = new URLSearchParams(window.location.search).get(
    "invoiceType"
  );

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (invoiceType.includes("AIR") || invoiceType.includes("AEXP")) {
        await getDoc(doc(collection(db, "AIR Reports"), id)).then(
          async (snapshot) => {
            if (snapshot.exists()) {
              setGeneralInfo(snapshot.data().GeneralInfo);
              setDetailsInfo(snapshot.data().DetailsInfo);
            }
          }
        );
      } else {
        await getDoc(doc(collection(db, "SEA Reports"), id)).then(
          async (snapshot) => {
            if (snapshot.exists()) {
              setGeneralInfo(snapshot.data().GeneralInfo);
              setDetailsInfo(snapshot.data().DetailsInfo);
            }
          }
        );
      }
    };

    getData();
  }, []);

  const sendToEditHandler = async () => {
    try {
      const newCollectionRef = collection(db, "EditReports");
      const newData = {
        id: id,
        invoiceType: invoiceType,
      };
      await addDoc(newCollectionRef, newData).then(() => {
        toast.success("Send Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });

      // Optionally, display a success message using toast or other means
    } catch (error) {
      // Handle any error that occurs during the operation
      console.error("Error sending data:", error);
    }
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className="pt-20 md:pt-2 xl:pt-2">
      <ToastContainer />
      <div className="ml-auto pl-10 pr-5">
        <p className="text-[#FF5C8E] font-bold pt-2 pb-2">
          General Information
        </p>
        <hr />
        <div className="flex flex-wrap pl-10 justify-between items-center">
          <ViewFiled name="Invoice Type" value={GeneralInfo.InvoiceType} />
          <ViewFiled name="Invoice Date" value={GeneralInfo.InvoiceDate} />
          <ViewFiled name="Destination" value={GeneralInfo.Destination} />
          <ViewFiled name="ETA" value={GeneralInfo.Eta} />
          <ViewFiled name="ETD" value={GeneralInfo.Etd} />
          <ViewFiled name="Buying Currency" value={GeneralInfo.Bcurr} />
          <ViewFiled
            name="Buying Currency Rate"
            value={GeneralInfo.BcurrRate}
          />
          <ViewFiled name="Buying Currency Amout" value={GeneralInfo.BcurrAm} />
          <ViewFiled name="Selling Currency" value={GeneralInfo.Scurr} />
          <ViewFiled
            name="Selling Currency Rate"
            value={GeneralInfo.ScurrRate}
          />
          <ViewFiled name="Consignee" value={GeneralInfo.Consignee} />
          <ViewFiled name="Com.Invoice.No" value={GeneralInfo.ComInvNo} />
          <ViewFiled name="To" value={GeneralInfo.To} />
          <ViewFiled name="Shipper Name" value={GeneralInfo.ShipName} />
          <ViewFiled name="Ch.Weight(KG)" value={GeneralInfo.ChWeight} />
          <ViewFiled name="HBL" value={GeneralInfo.Hbl} />
          <ViewFiled name="MBL" value={GeneralInfo.Mbl} />
          <ViewFiled name="FDR Vessel" value={GeneralInfo.FdrVessel} />
          <ViewFiled name="Voy No" value={GeneralInfo.Voy} />
          <ViewFiled name="POL" value={GeneralInfo.Pol} />
          <ViewFiled name="Payment Mode" value={GeneralInfo.PaymentMode} />
          <ViewFiled name="Bank Name" value={GeneralInfo.BName} />
          <ViewFiled name="Bank Address" value={GeneralInfo.BAddress} />
          <ViewFiled name="L/C" value={GeneralInfo.LcNo} />
          <ViewFiled name="Tues" value={GeneralInfo.Tues} />
          <ViewFiled name="Logistic Agent" value={GeneralInfo.Logistics} />
          <ViewFiled name="Job No" value={GeneralInfo.JobNo} />
          <ViewFiled name="Job Status" value={GeneralInfo.JobStatus} />
          <ViewFiled name="TOS" value={GeneralInfo.Tos} />
          <ViewFiled name="Sales Person" value={GeneralInfo.SalePer} />
          <ViewFiled name="EXP No" value={GeneralInfo.ExpNo} />
          <ViewFiled name="Mother Vessel" value={GeneralInfo.MotherVessel} />
          <ViewFiled name="Shipping Lines" value={GeneralInfo.ShippingLine} />
        </div>
      </div>
      <div className="ml-auto pl-10 pr-5">
        <p className="text-[#FF5C8E] font-bold pt-2 pb-2">Detail Information</p>
        <hr />
        <div className="w-full pt-3 pb-3 pl-10 pr-5 relative overflow-x-auto">
          <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              {GeneralInfo.InvoiceType &&
              GeneralInfo.InvoiceType.includes("AIR") ? (
                <tr>
                  <th className="p-2 border border-gray-400">Description</th>
                  <th className="p-2 border border-gray-400">HAWB No</th>
                  <th className="p-2 border border-gray-400">PKG</th>
                  <th className="p-2 border border-gray-400">CH.W.</th>

                  <th className="p-2 border border-gray-400">Amount/KG</th>
                  <th className="p-2 border border-gray-400">Total USD</th>
                  <th className="p-2 border border-gray-400">BDT Amount</th>
                </tr>
              ) : (
                <tr>
                  <th className="p-2 border border-gray-400">Description</th>
                  <th className="p-2 border border-gray-400">Container No</th>
                  <th className="p-2 border border-gray-400">Cotainer Size</th>
                  <th className="p-2 border border-gray-400">Container Qty</th>

                  <th className="p-2 border border-gray-400">USD</th>
                  <th className="p-2 border border-gray-400">Total USD</th>
                  <th className="p-2 border border-gray-400">BDT Amount</th>
                </tr>
              )}
            </thead>
            <tbody>
              {DetailsInfo.map((data, index) => (
                <tr>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.Description}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.ContainerNo}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.ContainerSize}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.ContainerQty}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.Usd}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.TotalUsd}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.Bdt}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex ml-auto pl-10 mt-5 mb-10 pr-5">
        <button
          onClick={sendToEditHandler}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#2e2e2e] bg-[#2e2e2e]  rounded-full hover:bg-[#181818] hover:text-white"
        >
          Send for Edit
        </button>
        <button
          onClick={goBackHandler}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#1b9d80] bg-[#1b9d80]  rounded-full hover:bg-white hover:text-black"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ViewSingleEntry;
