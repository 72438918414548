import React, { useEffect, useState } from "react";
import Dropdown from "../components/Dropdown";
import TextInput from "../components/TextInput";

import {
  AirExport,
  AirImport,
  SeaExport,
  SeaImportExport,
} from "../data/dummy";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../contexts/ContextProvider";

import {
  collection,
  addDoc,
  query,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Datepicker from "../components/Datepicker";
import { AiOutlineClose } from "react-icons/ai";

const SeaEntry = () => {
  const [isPaymentCleared, setIsPaymentCleared] = useState(true);
  //Dropdown Data Load
  const [DraftData, setDraftData] = useState({});
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const [currList, setCurrList] = useState([]);
  const [ToList, setToList] = useState([]);
  const [TosList, setTosList] = useState([]);
  const [ShipperNameList, setShipperNameList] = useState([]);
  const [consinList, setConsinList] = useState([]);
  const [PolList, setPolList] = useState([]);
  const [DestinationList, setDestinationList] = useState([]);
  const [BankNameList, setBankNameList] = useState([]);
  const [logisticsList, setLogisticsList] = useState([]);
  const [JobStatusList, setJobStatusList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const [PaymentModeList, setPaymentModeList] = useState([]);
  const [UnitList, setUnitList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showBdt, setShowBdt] = useState(false);
  const [showUSD, setShowUSD] = useState(false);
  const [Serial, setSerial] = useState(0);
  const { user, role } = useStateContext();
  const [searchMonth, setSearchMonth] = useState();

  const [isInvGenerated, setIsInvGenerated] = useState(false);

  async function getUserData(db, userId) {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  const [InputValues, setInputValues] = useState({
    CreatedBy: user.uid,
    InvoiceType: "",
    InvoiceNum: "",
    InvoiceDate: new Date(),
    Bcurr: "",
    BcurrRate: 0.0,
    BcurrAm: 0.0,
    Scurr: "",
    ScurrRate: 0.0,
    ComInvNo: "",
    To: "",
    AirInv: "",
    ShipName: "",
    Consignee: "",
    ChWeight: 0.0,
    Hbl: "",
    Mbl: "",
    Etd: new Date(),
    FdrVessel: "",
    Voy: "",
    Pol: "",
    Eta: new Date(),
    Destination: "",
    PaymentMode: "",
    BName: "",
    BAddress: "",
    LcNo: "",
    Tues: "",
    Logistics: "",
    JobNo: "",
    JobStatus: "",
    Tos: "",
    SalePer: "",
    ExpNo: "",
    MotherVessel: "",
    Proof: "",
    ShippingLine: "",
    ContainerNum: " ",
    ContainerSize: " ",
  });

  const [DetailsList, setDetailsList] = useState([]);

  const [detailsInfo, setDetailsInfo] = useState({
    Description: "",
    ContainerNo: " ",
    ContainerSize: "",

    ContainerQty: 0.0,
    Currency: "",
    CurrencyRate: 0.0,
    Unit: "",
    Usd: 0.0,
    TotalUsd: 0.0,
    Bdt: 0.0,
  });

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;

    setDetailsInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const SetInvoiceNum = async () => {
    let serial = 0;
    const today = InputValues.InvoiceDate;
    let SearchMonth = today.getMonth() + 1;
    setSearchMonth(SearchMonth);

    const q = query(
      collection(db, "SerialNumber"),
      where("Month", "==", today.getMonth() + 1),
      where("InvoiceType", "==", InputValues.InvoiceType)
    );

    await getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          serial = doc.data().Serial + 1;
        });
      })
      .then(async () => {
        setSerial(serial);
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const year = today.getFullYear().toString().slice(-2);
        let serialPrefix = "000";

        if (serial > 9 && serial < 100) {
          serialPrefix = "00";
        } else if (serial > 99) {
          serialPrefix = "0";
        }
        let InvNum = " ";

        if (InputValues.InvoiceType == "SEA Import Invoice") {
          InvNum = `PAM/SIMP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "SEA Export Invoice") {
          InvNum = `PAM/SEXP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "AIR Export Invoice") {
          InvNum = `PAM/AEXP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "AIR Import Invoice") {
          InvNum = `PAM/AIMP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "Local Charge(SEXP)") {
          InvNum = `PAM/SELC/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "Local Charge(AEXP)") {
          InvNum = `PAM/AELC/${year}${month}${serialPrefix}${serial}`;
        }

        setInputValues({ ...InputValues, InvoiceNum: InvNum });
      });

    /*  await getDoc(doc(collection(db, "SerialNumbers"), InputValues.InvoiceType))
      .then((snap) => {
        serial = snap.data().serial + 1;
      })
      .then(() => {
        setSerial(serial);
        const today = new Date();
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const year = today.getFullYear().toString().slice(-2);
        let serialPrefix = "000";

        if (serial > 9 && serial < 100) {
          serialPrefix = "00";
        } else if (serial > 99) {
          serialPrefix = "0";
        }
        let InvNum = " ";

        if (InputValues.InvoiceType == "SEA Import Invoice") {
          InvNum = `PAM/SIMP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "SEA Export Invoice") {
          InvNum = `PAM/SEXP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "AIR Export Invoice") {
          InvNum = `PAM/AEXP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "AIR Import Invoice") {
          InvNum = `PAM/AIMP/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "Local Charge(SEXP)") {
          InvNum = `PAM/SELC/${year}${month}${serialPrefix}${serial}`;
        } else if (InputValues.InvoiceType == "Local Charge(AEXP)") {
          InvNum = `PAM/AELC/${year}${month}${serialPrefix}${serial}`;
        }
        setInputValues({ ...InputValues, InvoiceNum: InvNum });
      }); */
  };

  useEffect(() => {
    SetInvoiceNum();
  }, [InputValues.InvoiceType, Serial, InputValues.InvoiceDate]);

  const SaveDraft = async (event) => {
    event.preventDefault();

    if (
      InputValues.InvoiceType.includes("SEA") ||
      InputValues.InvoiceType.includes("SEXP")
    ) {
      const invoiceNumRef = collection(db, "SEA Reports");
      const invoiceNumQuery = query(
        invoiceNumRef,
        where("GeneralInfo.InvoiceNum", "==", InputValues.InvoiceNum)
      );
      const existingInvoiceNum = await getDocs(invoiceNumQuery);

      if (existingInvoiceNum.docs.length > 0) {
        // Invoice number already exists, show an error message
        toast.error(
          "There is a problem with your internet connection. Please check and Try again!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      } else {
        // Invoice number does not exist, set it and proceed
        setIsInvGenerated(true);
      }
    } else {
      const invoiceNumRef = collection(db, "AIR Reports");
      const invoiceNumQuery = query(
        invoiceNumRef,
        where("GeneralInfo.InvoiceNum", "==", InputValues.InvoiceNum)
      );
      const existingInvoiceNum = await getDocs(invoiceNumQuery);

      if (existingInvoiceNum.docs.length > 0) {
        // Invoice number already exists, show an error message
        toast.error(
          "There is a problem with your internet connection. Please check and Try again!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      } else {
        setIsInvGenerated(true);
      }
    }

    /* const collectionRef = collection(db, "users");


const documentsData = [
  { name: "John" },
  { name: "Jane" },
  { name: "Bob" },
];

documentsData.forEach(async (data) => {
  try {
    await addDoc(collectionRef, data);
    console.log("Document added successfully.");
  } catch (error) {
    console.error("Error adding document:", error);
  }
}); */

    setDraftData(() => ({
      ...InputValues,
      CreatedBy: user.uid,

      InvoiceDate: InputValues.InvoiceDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
      Eta: InputValues.Eta.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
      Etd: InputValues.Etd.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
    }));

    toast.success("Added", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const AddDetails = (event) => {
    event.preventDefault();
    if (
      InputValues.InvoiceType == "SEA Export Invoice" ||
      InputValues.InvoiceType == "Local Charge(SEXP)"
    ) {
      const newDetails = {
        Description: event.target.Description.value,
        ContainerNo: "none",
        ContainerSize: "none",
        ContainerQty: event.target.ContainerQty.value,
        Currency: event.target.Currency.value,
        CurrencyRate: showBdt ? event.target.Usd.value : 0.0,
        Unit: event.target.Unit.value,
        Usd: event.target.Usd.value,
        TotalUsd: showBdt ? 0.0 : event.target.TotalUsd.value,
        Bdt: event.target.Bdt.value,
      };
      setDetailsList([...DetailsList, newDetails]);
    } else if (InputValues.InvoiceType == "Local Charge(AEXP)") {
      const newDetails = {
        Description: event.target.Description.value,
        ContainerNo: event.target.ContainerNo.value,
        ContainerSize: event.target.ContainerSize.value,
        ContainerQty: event.target.ContainerQty.value,
        Currency: event.target.Currency.value,
        CurrencyRate: showUSD ? event.target.CurrencyRate.value : 0.0,
        Unit: " None",
        Usd: event.target.Usd.value,
        TotalUsd: showUSD ? event.target.TotalUsd.value : 0.0,
        Bdt: event.target.Bdt.value,
      };
      setDetailsList([...DetailsList, newDetails]);
    } else {
      const newDetails = {
        Description: event.target.Description.value,
        ContainerNo: event.target.ContainerNo.value,
        ContainerSize: event.target.ContainerSize.value,
        ContainerQty: event.target.ContainerQty.value,
        Currency: event.target.Currency.value,
        CurrencyRate: event.target.CurrencyRate.value,
        Unit: " None",
        Usd: event.target.Usd.value,
        TotalUsd: event.target.TotalUsd.value,
        Bdt: event.target.Bdt.value,
      };
      setDetailsList([...DetailsList, newDetails]);
    }

    setDetailsInfo({
      Description: "",
      ContainerNo: "",
      ContainerSize: "",
      ContainerQty: 0.0,
      Currency: "",
      CurrencyRate: 0.0,
      Unit: "",
      Usd: 0.0,
      TotalUsd: 0.0,
      Bdt: 0.0,
    });
  };

  const uploadData = async () => {
    setIsInvGenerated(false);
    if (
      InputValues.InvoiceType == "SEA Import Invoice" ||
      InputValues.InvoiceType == "SEA Export Invoice" ||
      InputValues.InvoiceType == "Local Charge(SEXP)"
    ) {
      await addDoc(
        collection(db, "SEA Reports"),

        {
          GeneralInfo: DraftData,
          DetailsInfo: DetailsList,
        }
      ).then(async () => {
        toast.success("Successfully added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        await addDoc(collection(db, "FinalEntry"), {
          GeneralInfo: DraftData,
          DetailsInfo: DetailsList,
        });

        const q = query(
          collection(db, "SerialNumber"),
          where("Month", "==", searchMonth),
          where("InvoiceType", "==", DraftData.InvoiceType)
        );

        await getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach(async (doc1) => {
            const serialNum = doc(collection(db, "SerialNumber"), doc1.id);

            await updateDoc(serialNum, { Serial: Serial });
          });
        });
      });
    } else {
      await addDoc(
        collection(db, "AIR Reports"),

        {
          GeneralInfo: DraftData,
          DetailsInfo: DetailsList,
        }
      ).then(async () => {
        toast.success("Successfully added", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        await addDoc(collection(db, "FinalEntry"), {
          GeneralInfo: DraftData,
          DetailsInfo: DetailsList,
        });

        const q = query(
          collection(db, "SerialNumber"),
          where("Month", "==", searchMonth),
          where("InvoiceType", "==", DraftData.InvoiceType)
        );

        await getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach(async (doc1) => {
            const serialNum = doc(collection(db, "SerialNumber"), doc1.id);
            await updateDoc(serialNum, { Serial: Serial });
          });
        });
      });
    }
    ClearAll();
  };

  const ClearAll = () => {
    setShowBdt(false);
    setShowUSD(false);
    setDetailsInfo({
      Description: "",
      ContainerNo: "",
      ContainerSize: "",
      ContainerQty: 0.0,
      Currency: "",
      CurrencyRate: 0.0,
      Unit: "",
      Usd: 0.0,
      TotalUsd: 0.0,
      Bdt: 0.0,
    });
    setDetailsList([]);
    setInputValues({
      InvoiceType: "",
      InvoiceNum: "",
      InvoiceDate: new Date(),
      Bcurr: "",
      BcurrRate: 0.0,
      BcurrAm: 0.0,
      Scurr: "",
      ScurrRate: 0.0,
      ComInvNo: "",
      To: "",
      ShipName: "",
      Consignee: "",
      ChWeight: 0.0,
      Hbl: "",
      Mbl: "",
      AirInv: "",
      Etd: new Date(),
      FdrVessel: "",
      Voy: "",
      Pol: "",
      Eta: new Date(),
      Destination: "",
      PaymentMode: "",
      BName: "",
      BAddress: "",
      LcNo: "",
      Tues: "",
      Logistics: "",
      JobNo: "",
      JobStatus: "",
      Tos: "",
      SalePer: "",
      ExpNo: "",
      MotherVessel: "",
      Proof: "",
      ShippingLine: "",
      ContainerNum: " ",
      ContainerSize: " ",
    });
  };

  //Dropdown Data Load
  useEffect(() => {
    //Dropdown Data Load
    const invoiceRef = query(collection(db, "InvoiceType"));
    const currencyRef = query(collection(db, "Currency"));
    const ToRef = query(collection(db, "To"));
    const TosRef = query(collection(db, "Tos"));
    const PolRef = query(collection(db, "Pol"));
    const LogisticsRef = query(collection(db, "Logistics"));
    const JobStatusRef = query(collection(db, "JobStatus"));
    const UnitRef = query(collection(db, "Unit"));
    const DestinationRef = query(collection(db, "Destination"));
    const DescriptionRef = query(collection(db, "Description"));
    const EtdRef = query(collection(db, "Etd"));
    const PaymentModeRef = query(collection(db, "PaymentMode"));
    const BankNameRef = query(collection(db, "BankName"));
    const ShipperNameRef = query(collection(db, "ShipperName"));
    const ConsignRef = query(collection(db, "Consigne"));

    getUserData(db, user.uid)
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => {
        alert(`Error retrieving user data: ${error}`);
      });

    //Dropdown Data Load
    const invoiceList = onSnapshot(invoiceRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setInvoiceTypeList(tempArr);
    });

    const Currencylist = onSnapshot(currencyRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setCurrList(tempArr);
    });

    const Tolist2 = onSnapshot(ToRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setToList(tempArr);
    });

    const Unitlist2 = onSnapshot(UnitRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setUnitList(tempArr);
    });

    const ShipperNameList2 = onSnapshot(ShipperNameRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setShipperNameList(tempArr);
    });

    const ConsigneeList2 = onSnapshot(ConsignRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setConsinList(tempArr);
    });

    const PolList2 = onSnapshot(PolRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setPolList(tempArr);
    });

    const DestList = onSnapshot(DestinationRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setDestinationList(tempArr);
    });

    const BNList = onSnapshot(BankNameRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setBankNameList(tempArr);
    });

    const LogList = onSnapshot(LogisticsRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setLogisticsList(tempArr);
    });

    const JSList = onSnapshot(JobStatusRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setJobStatusList(tempArr);
    });

    const TosList2 = onSnapshot(TosRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setTosList(tempArr);
    });

    const DesList = onSnapshot(DescriptionRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setDescriptionList(tempArr);
    });

    const Paymodelist = onSnapshot(PaymentModeRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setPaymentModeList(tempArr);
    });

    return () => {
      //Dropdown Data Load
      invoiceList();
      Currencylist();
      Tolist2();
      Unitlist2();
      PolList2();
      DestList();
      BNList();
      LogList();
      JSList();
      TosList2();
      DesList();
      Paymodelist();
      ShipperNameList2();
      ConsigneeList2();
    };
  }, [user.uid]);

  const handleDeleteRow = (index) => {
    const updatedList = [...DetailsList];
    updatedList.splice(index, 1);
    setDetailsList(updatedList);
  };

  useEffect(() => {
    const getBankDetails = async () => {
      if (InputValues.BName != " ") {
        const q = query(
          collection(db, "BankName"),
          where("name", "==", InputValues.BName)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setInputValues((prevState) => ({
              ...prevState,
              BAddress: doc.data().address,
            }));
          });
        }
      }
    };

    getBankDetails();
  }, [InputValues.BName]);

  return (
    <div className="pt-20 md:pt-2 xl:pt-2">
      <ToastContainer />
      <div className="ml-auto mr-2 mt-2 mb-2">
        <h4 className="w-full flex justify-center font-semibold  p-3 bg-gray-300 rounded-lg hover:bg-gray-400">
          Invoice Entry Form
        </h4>
      </div>
      <div id="MainInfo">
        <div>
          <p className="font-bold ml-2 pt-2">Main Information</p>
          <hr className="border border-[#9e0734] opacity-50" />
        </div>
        <div className="w-full flex justify-between ml-auto pr-3 pl-5 pt-3">
          <div>
            <p className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              USER NAME :{" "}
              <span className="font-normal">
                {userData && userData.username}
              </span>
            </p>
            <p className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Invoice No :{" "}
              <span className="font-normal">
                {DraftData && DraftData.InvoiceNum}
              </span>
            </p>
          </div>
          <div>
            <p className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              user Information :{" "}
              <span className="font-normal">
                {userData && userData.address}
              </span>
            </p>
            {/* <p className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Date : <span className="font-normal">28/2/2023</span>
            </p> */}
          </div>
        </div>
        <hr />

        <form className="w-full pt-3 pb-3" onSubmit={SaveDraft}>
          <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
            {role && role == "Air Export & Local Charge(AEXP)" && (
              <Dropdown
                label="Invoice Type"
                name="InvoiceType"
                value={InputValues.InvoiceType}
                onChange={handleChange}
              >
                {AirExport.map((item, index) => (
                  <option key={index}>{item.title}</option>
                ))}
              </Dropdown>
            )}

            {role && role == "Sea Import & Export" && (
              <Dropdown
                label="Invoice Type"
                name="InvoiceType"
                value={InputValues.InvoiceType}
                onChange={handleChange}
              >
                {SeaImportExport.map((item, index) => (
                  <option key={index}>{item.title}</option>
                ))}
              </Dropdown>
            )}

            {role && role == "Air Import & Sea Import" && (
              <Dropdown
                label="Invoice Type"
                name="InvoiceType"
                value={InputValues.InvoiceType}
                onChange={handleChange}
              >
                {AirImport.map((item, index) => (
                  <option key={index}>{item.title}</option>
                ))}
              </Dropdown>
            )}

            {role && role == "Sea Export & Local Charge(SEXP)" && (
              <Dropdown
                label="Invoice Type"
                name="InvoiceType"
                value={InputValues.InvoiceType}
                onChange={handleChange}
              >
                {SeaExport.map((item, index) => (
                  <option key={index}>{item.title}</option>
                ))}
              </Dropdown>
            )}

            <Datepicker
              label="Invoice Date"
              startDate={InputValues.InvoiceDate}
              setStartDate={(date) =>
                setInputValues((prevState) => ({
                  ...prevState,
                  InvoiceDate: date,
                }))
              }
            />
            <Dropdown
              label="Buying Currency"
              name="Bcurr"
              value={InputValues.Bcurr}
              onChange={handleChange}
            >
              {currList.map((item) => (
                <option>{item.name}</option>
              ))}
            </Dropdown>

            <TextInput
              type="text"
              label="Buying Currency Rate"
              name="BcurrRate"
              value={InputValues.BcurrRate}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Buying Currency Amount"
              name="BcurrAm"
              value={InputValues.BcurrAm}
              onChange={handleChange}
            />
            <Dropdown
              label="Selling Currency"
              name="Scurr"
              value={InputValues.Scurr}
              onChange={handleChange}
            >
              {currList.map((item) => (
                <option>{item.name}</option>
              ))}
            </Dropdown>
            <TextInput
              type="text"
              label="Selling Currency Rate"
              name="ScurrRate"
              value={InputValues.ScurrRate}
              onChange={handleChange}
            />
            {InputValues.InvoiceType &&
            InputValues.InvoiceType.includes("Import") ? (
              <TextInput
                type="text"
                label="L/C No"
                name="LcNo"
                value={InputValues.LcNo}
                onChange={handleChange}
              />
            ) : (
              <TextInput
                type="text"
                label="Com.Invoice.No"
                name="ComInvNo"
                value={InputValues.ComInvNo}
                onChange={handleChange}
              />
            )}
            <Dropdown
              label="TO"
              name="To"
              value={InputValues.To}
              onChange={handleChange}
            >
              {ToList.map((item) => (
                <option>{item.desc}</option>
              ))}
            </Dropdown>

            <Dropdown
              label="Shipper Name"
              name="ShipName"
              value={InputValues.ShipName}
              onChange={handleChange}
            >
              {ShipperNameList.map((item) => (
                <option>{item.name}</option>
              ))}
            </Dropdown>
            <Dropdown
              label="Consignee/Notify"
              name="Consignee"
              value={InputValues.Consignee}
              onChange={handleChange}
            >
              {consinList.map((item) => (
                <option>{item.name}</option>
              ))}
            </Dropdown>

            {InputValues.InvoiceType &&
              InputValues.InvoiceType === "AIR Import Invoice" && (
                <TextInput
                  type="text"
                  label="AIR INV"
                  name="AirInv"
                  value={InputValues.AirInv}
                  onChange={handleChange}
                />
              )}

            {InputValues.InvoiceType &&
            InputValues.InvoiceType.includes("SE") ? (
              <TextInput
                type="text"
                label="TUES"
                name="Tues"
                value={InputValues.Tues}
                onChange={handleChange}
              />
            ) : (
              <TextInput
                type="text"
                label="Ch.Weight(kg.)"
                name="ChWeight"
                value={InputValues.ChWeight}
                onChange={handleChange}
              />
            )}

            {/* <Dropdown
              label="HBL No"
              name="Hbl"
              value={InputValues.Hbl}
              onChange={handleChange}
            >
              {HblList.map((item) => (
                <option>{item.desc}</option>
              ))}
            </Dropdown> */}

            <TextInput
              type="text"
              label={
                InputValues && InputValues.InvoiceType.includes("AIR")
                  ? "HAWB No"
                  : "HBL No"
              }
              name="Hbl"
              value={InputValues.Hbl}
              onChange={handleChange}
            />

            <TextInput
              type="text"
              label={
                InputValues.InvoiceType &&
                InputValues.InvoiceType.includes("SE")
                  ? "MBL No"
                  : "MAWB No"
              }
              name="Mbl"
              value={InputValues.Mbl}
              onChange={handleChange}
            />

            {/* <Dropdown
              label="ETD"
              name="Etd"
              value={InputValues.Etd}
              onChange={handleChange}
            >
              {EtdList.map((item) => (
                <option>{item.desc}</option>
              ))}
            </Dropdown> */}
            <Datepicker
              label="ETD"
              startDate={InputValues.Etd}
              setStartDate={(date) =>
                setInputValues((prevState) => ({
                  ...prevState,
                  Etd: date,
                }))
              }
            />
            <TextInput
              type="text"
              label={
                InputValues.InvoiceType &&
                InputValues.InvoiceType.includes("SE")
                  ? "FDR Vessel"
                  : "Flight No"
              }
              name="FdrVessel"
              value={InputValues.FdrVessel}
              onChange={handleChange}
            />
            {InputValues.InvoiceType &&
            InputValues.InvoiceType.includes("SE") ? (
              <TextInput
                type="text"
                label="VOY No"
                name="Voy"
                value={InputValues.Voy}
                onChange={handleChange}
              />
            ) : (
              ""
            )}

            <Dropdown
              label="POL"
              name="Pol"
              value={InputValues.Pol}
              onChange={handleChange}
            >
              {PolList.map((item) => (
                <option>{item.desc}</option>
              ))}
            </Dropdown>
            {/* <TextInput
              type="text"
              label="ETA"
              name="Eta"
              value={InputValues.Eta}
              onChange={handleChange}
            /> */}
            <Datepicker
              label="ETA"
              startDate={InputValues.Eta}
              setStartDate={(date) =>
                setInputValues((prevState) => ({
                  ...prevState,
                  Eta: date,
                }))
              }
            />

            <Dropdown
              label="Destination"
              name="Destination"
              value={InputValues.Destination}
              onChange={handleChange}
            >
              {DestinationList.map((item) => (
                <option>{item.dest}</option>
              ))}
            </Dropdown>

            <Dropdown
              label="Payment Mode"
              name="PaymentMode"
              value={InputValues.PaymentMode}
              onChange={handleChange}
            >
              {PaymentModeList.map((item) => (
                <option>{item.mode}</option>
              ))}
            </Dropdown>
            {/*  <TextInput
              type="text"
              label="Payment Mode"
              name="PaymentMode"
              value={InputValues.PaymentMode}
              onChange={handleChange}
            /> */}

            <Dropdown
              label="Bank Name"
              name="BName"
              value={InputValues.BName}
              onChange={handleChange}
            >
              {BankNameList.map((item) => (
                <option>{item.name}</option>
              ))}
            </Dropdown>
            <TextInput
              type="text"
              label="Bank Address"
              name="BAddress"
              value={InputValues.BAddress}
              onChange={handleChange}
            />
            {/* {InputValues.InvoiceType &&
            InputValues.InvoiceType.includes("SEA") ? (
              <TextInput
                type="text"
                label="L/C No"
                name="LcNo"
                value={InputValues.LcNo}
                onChange={handleChange}
              />
            ) : (
              ""
            )} */}

            {/* {InputValues.InvoiceType &&
            InputValues.InvoiceType.includes("SEA") ? (
              <TextInput
                type="text"
                label="KG"
                name="Tues"
                value={InputValues.Tues}
                onChange={handleChange}
              />
            ) : (
              ""
            )} */}
            {/* <TextInput
              type="text"
              label={
                InputValues.InvoiceType &&
                InputValues.InvoiceType.includes("SEA")
                  ? "TUES"
                  : "KG"
              }
              name="Tues"
              value={InputValues.Tues}
              onChange={handleChange}
            /> */}

            <Dropdown
              label="Logistics Agent"
              name="Logistics"
              value={InputValues.Logistics}
              onChange={handleChange}
            >
              {logisticsList.map((item) => (
                <option>{item.desc}</option>
              ))}
            </Dropdown>
            <TextInput
              type="text"
              label="Job No"
              name="JobNo"
              value={InputValues.JobNo}
              onChange={handleChange}
            />

            <Dropdown
              label="Job Status"
              name="JobStatus"
              value={InputValues.JobStatus}
              onChange={handleChange}
            >
              {JobStatusList.map((item) => (
                <option>{item.status}</option>
              ))}
            </Dropdown>

            {InputValues.InvoiceType &&
            InputValues.InvoiceType.includes("SE") ? (
              <Dropdown
                label="TOS"
                name="Tos"
                value={InputValues.Tos}
                onChange={handleChange}
              >
                {TosList.map((item) => (
                  <option>{item.desc}</option>
                ))}
              </Dropdown>
            ) : (
              ""
            )}

            <TextInput
              type="text"
              label="Sales Person"
              name="SalePer"
              value={InputValues.SalePer}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Exp No"
              name="ExpNo"
              value={InputValues.ExpNo}
              onChange={handleChange}
            />
            {InputValues.InvoiceType &&
            InputValues.InvoiceType.includes("SE") ? (
              <TextInput
                type="text"
                label="Mother Vessel"
                name="MotherVessel"
                value={InputValues.MotherVessel}
                onChange={handleChange}
              />
            ) : (
              ""
            )}
            {InputValues.InvoiceType &&
            (InputValues.InvoiceType === "SEA Export Invoice" ||
              InputValues.InvoiceType === "Local Charge(SEXP)") ? (
              ""
            ) : (
              <TextInput
                type="text"
                label="Proof of Delivery"
                name="Proof"
                value={InputValues.Proof}
                onChange={handleChange}
              />
            )}

            <TextInput
              type="text"
              label={
                InputValues.InvoiceType &&
                InputValues.InvoiceType.includes("SE")
                  ? "Shipping Lines"
                  : "AirLines"
              }
              name="ShippingLine"
              value={InputValues.ShippingLine}
              onChange={handleChange}
            />

            {InputValues.InvoiceType &&
            (InputValues.InvoiceType === "SEA Export Invoice" ||
              InputValues.InvoiceType === "Local Charge(SEXP)") ? (
              <>
                <div className="flex flex-col w-full md:w-2/3 px-3 pb-3 pt-2">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Container No/Size
                  </label>
                  <input
                    type="text"
                    name="ContainerNum"
                    value={InputValues.ContainerNum}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
            <button
              type="submit"
              className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
            >
              <span className="capitalize">Save</span>
            </button>
          </div>
        </form>
        <hr className=" ml-10 mr-5" />
      </div>
      <div id="DetailsInfo">
        <div>
          <p className="font-bold ml-2 pt-2">Details Information</p>
          <hr className="border border-[#9e0734] opacity-50" />
        </div>
        {InputValues && InputValues.InvoiceType.includes("Local") ? (
          <>
            {showBdt == false && showUSD == false ? (
              <div className="flex flex-row gap-3">
                <div className="flex px-1 pb-3 pt-2">
                  <button
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowBdt(true);
                    }}
                    className="rounded-full text-white py-3 px-5 bg-gray-800 text-xs"
                  >
                    Show BDT Calculation
                  </button>
                </div>
                <div className="flex px-1 pb-3 pt-2">
                  <button
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowUSD(true);
                    }}
                    className="rounded-full text-white py-3 px-5 bg-gray-800 text-xs"
                  >
                    Show USD Calculation
                  </button>
                </div>
              </div>
            ) : (
              <>
                <form className="w-full pt-3 pb-3" onSubmit={AddDetails}>
                  <div className="flex flex-wrap justify-between items-center ml-auto pl-10 pr-5">
                    <Dropdown
                      label="Description"
                      name="Description"
                      value={detailsInfo.Description}
                      onChange={handleDetailsChange}
                    >
                      {descriptionList.map((item) => (
                        <option>{item.desc}</option>
                      ))}
                    </Dropdown>

                    {InputValues.InvoiceType &&
                    (InputValues.InvoiceType == "Local Charge(SEXP)" ||
                      InputValues.InvoiceType == "SEA Export Invoice") ? (
                      ""
                    ) : (
                      <>
                        <TextInput
                          type="text"
                          label={
                            InputValues.InvoiceType &&
                            InputValues.InvoiceType.includes("SEA")
                              ? "Container No"
                              : "HAWB No"
                          }
                          name="ContainerNo"
                          value={detailsInfo.ContainerNo}
                          onChange={handleDetailsChange}
                        />
                        <TextInput
                          type="text"
                          label={
                            InputValues.InvoiceType &&
                            InputValues.InvoiceType.includes("SEA")
                              ? "Container Size"
                              : "Package"
                          }
                          name="ContainerSize"
                          value={detailsInfo.ContainerSize}
                          onChange={handleDetailsChange}
                        />
                      </>
                    )}

                    {InputValues.InvoiceType &&
                    (InputValues.InvoiceType == "Local Charge(SEXP)" ||
                      InputValues.InvoiceType == "SEA Export Invoice") ? (
                      <TextInput
                        type="number"
                        label="QTY"
                        name="ContainerQty"
                        value={detailsInfo.ContainerQty}
                        onChange={handleDetailsChange}
                      />
                    ) : (
                      <TextInput
                        type="number"
                        label={
                          InputValues.InvoiceType &&
                          InputValues.InvoiceType.includes("SEA")
                            ? "Container QTY"
                            : "Ch.Weight(KG)"
                        }
                        name="ContainerQty"
                        value={detailsInfo.ContainerQty}
                        onChange={handleDetailsChange}
                      />
                    )}
                    {InputValues.InvoiceType &&
                      (InputValues.InvoiceType == "SEA Export Invoice" ||
                        InputValues.InvoiceType == "Local Charge(SEXP)") && (
                        <Dropdown
                          label="Unit"
                          name="Unit"
                          value={detailsInfo.Unit}
                          onChange={handleDetailsChange}
                        >
                          {UnitList.map((item) => (
                            <option>{item.name}</option>
                          ))}
                        </Dropdown>
                      )}

                    <TextInput
                      label={showBdt ? "BDT/KG" : "USD/KG"}
                      type="number"
                      name="Usd"
                      value={detailsInfo.Usd}
                      onChange={handleDetailsChange}
                    />

                    {showUSD && (
                      <TextInput
                        disabled
                        label="Total USD"
                        type="number"
                        name="TotalUsd"
                        value={detailsInfo.Usd * detailsInfo.ContainerQty}
                        onChange={handleDetailsChange}
                      />
                    )}
                    <Dropdown
                      label="Currency"
                      name="Currency"
                      value={detailsInfo.Currency}
                      onChange={handleDetailsChange}
                    >
                      {currList.map((item) => (
                        <option>{item.name}</option>
                      ))}
                    </Dropdown>

                    {showUSD && (
                      <TextInput
                        label="Currency Rate"
                        type="number"
                        name="CurrencyRate"
                        value={detailsInfo.CurrencyRate}
                        onChange={handleDetailsChange}
                      />
                    )}
                    {showUSD ? (
                      <TextInput
                        disabled
                        label="BDT"
                        type="number"
                        name="Bdt"
                        value={
                          detailsInfo.CurrencyRate *
                          detailsInfo.Usd *
                          detailsInfo.ContainerQty
                        }
                        onChange={handleDetailsChange}
                      />
                    ) : (
                      <TextInput
                        label="BDT"
                        type="number"
                        name="Bdt"
                        value={detailsInfo.Bdt}
                        onChange={handleDetailsChange}
                      />
                    )}

                    {/* <TextInput
              disabled
              label="BDT"
              type="number"
              name="Bdt"
              value={
                detailsInfo.CurrencyRate *
                detailsInfo.Usd *
                detailsInfo.ContainerQty
              }
              onChange={handleDetailsChange}
            /> */}
                  </div>
                  <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
                    <button
                      type="submit"
                      className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
                    >
                      <span className="capitalize">Add</span>
                    </button>
                  </div>
                </form>
              </>
            )}
          </>
        ) : (
          <form className="w-full pt-3 pb-3" onSubmit={AddDetails}>
            <div className="flex flex-wrap justify-between items-center ml-auto pl-10 pr-5">
              <Dropdown
                label="Description"
                name="Description"
                value={detailsInfo.Description}
                onChange={handleDetailsChange}
              >
                {descriptionList.map((item) => (
                  <option>{item.desc}</option>
                ))}
              </Dropdown>

              {InputValues.InvoiceType &&
              (InputValues.InvoiceType == "Local Charge(SEXP)" ||
                InputValues.InvoiceType == "SEA Export Invoice") ? (
                ""
              ) : (
                <>
                  <TextInput
                    type="text"
                    label={
                      InputValues.InvoiceType &&
                      InputValues.InvoiceType.includes("SEA")
                        ? "Container No"
                        : "HAWB No"
                    }
                    name="ContainerNo"
                    value={detailsInfo.ContainerNo}
                    onChange={handleDetailsChange}
                  />
                  <TextInput
                    type="text"
                    label={
                      InputValues.InvoiceType &&
                      InputValues.InvoiceType.includes("SEA")
                        ? "Container Size"
                        : "Package"
                    }
                    name="ContainerSize"
                    value={detailsInfo.ContainerSize}
                    onChange={handleDetailsChange}
                  />
                </>
              )}

              {InputValues.InvoiceType &&
              (InputValues.InvoiceType == "Local Charge(SEXP)" ||
                InputValues.InvoiceType == "SEA Export Invoice") ? (
                <TextInput
                  type="number"
                  label="QTY"
                  name="ContainerQty"
                  value={detailsInfo.ContainerQty}
                  onChange={handleDetailsChange}
                />
              ) : (
                <TextInput
                  type="number"
                  label={
                    InputValues.InvoiceType &&
                    InputValues.InvoiceType.includes("SEA")
                      ? "Container QTY"
                      : "Ch.Weight(KG)"
                  }
                  name="ContainerQty"
                  value={detailsInfo.ContainerQty}
                  onChange={handleDetailsChange}
                />
              )}
              {InputValues.InvoiceType &&
                (InputValues.InvoiceType == "SEA Export Invoice" ||
                  InputValues.InvoiceType == "Local Charge(SEXP)") && (
                  <Dropdown
                    label="Unit"
                    name="Unit"
                    value={detailsInfo.Unit}
                    onChange={handleDetailsChange}
                  >
                    {UnitList.map((item) => (
                      <option>{item.name}</option>
                    ))}
                  </Dropdown>
                )}
              {InputValues.InvoiceType &&
              InputValues.InvoiceType.includes("SEA") ? (
                <TextInput
                  label="USD/CN"
                  type="number"
                  name="Usd"
                  value={detailsInfo.Usd}
                  onChange={handleDetailsChange}
                />
              ) : (
                <TextInput
                  label="USD/KG"
                  type="number"
                  name="Usd"
                  value={detailsInfo.Usd}
                  onChange={handleDetailsChange}
                />
              )}

              <TextInput
                disabled
                label="Total USD"
                type="number"
                name="TotalUsd"
                value={detailsInfo.Usd * detailsInfo.ContainerQty}
                onChange={handleDetailsChange}
              />
              <Dropdown
                label="Currency"
                name="Currency"
                value={detailsInfo.Currency}
                onChange={handleDetailsChange}
              >
                {currList.map((item) => (
                  <option>{item.name}</option>
                ))}
              </Dropdown>

              <TextInput
                label="Currency Rate"
                type="number"
                name="CurrencyRate"
                value={detailsInfo.CurrencyRate}
                onChange={handleDetailsChange}
              />
              <TextInput
                disabled
                label="BDT"
                type="number"
                name="Bdt"
                value={
                  detailsInfo.CurrencyRate *
                  detailsInfo.Usd *
                  detailsInfo.ContainerQty
                }
                onChange={handleDetailsChange}
              />

              {/* <TextInput
              disabled
              label="BDT"
              type="number"
              name="Bdt"
              value={
                detailsInfo.CurrencyRate *
                detailsInfo.Usd *
                detailsInfo.ContainerQty
              }
              onChange={handleDetailsChange}
            /> */}
            </div>
            <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
              <button
                type="submit"
                className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
              >
                <span className="capitalize">Add</span>
              </button>
            </div>
          </form>
        )}

        <hr className=" ml-10 mr-5 border border-[#FF5C8E] opacity-50" />
        <div className="w-full pt-3 pb-3 pl-10 pr-5 relative overflow-x-auto">
          <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="p-2 border border-gray-400">Description</th>
                {InputValues.InvoiceType &&
                (InputValues.InvoiceType == "SEA Export Invoice" ||
                  InputValues.InvoiceType == "Local Charge(SEXP)") ? (
                  <>
                    <th className="p-2 border border-gray-400">Unit</th>
                    <th className="p-2 border border-gray-400">Qty</th>
                  </>
                ) : (
                  <>
                    {" "}
                    <th className="p-2 border border-gray-400">
                      {InputValues.InvoiceType &&
                      InputValues.InvoiceType.includes("SEA")
                        ? "Container No"
                        : "HAWB No"}
                    </th>
                    <th className="p-2 border border-gray-400">
                      {InputValues.InvoiceType &&
                      InputValues.InvoiceType.includes("SEA")
                        ? "Container Size"
                        : "Package"}
                    </th>
                    <th className="p-2 border border-gray-400">
                      {InputValues.InvoiceType &&
                      InputValues.InvoiceType.includes("SEA")
                        ? "Container Qty"
                        : "Ch.Weight(KG)"}
                    </th>
                  </>
                )}

                {showBdt ? (
                  <th className="p-2 border border-gray-400">BDT/KG</th>
                ) : (
                  <th className="p-2 border border-gray-400">
                    {InputValues.InvoiceType &&
                    InputValues.InvoiceType.includes("SEA")
                      ? "USD/CN"
                      : "USD/KG"}
                  </th>
                )}
                <th className="p-2 border border-gray-400">Total USD</th>
                <th className="p-2 border border-gray-400">BDT Amount</th>
                <th className="p-2 border border-gray-400">Action</th>
              </tr>
            </thead>
            <tbody>
              {DetailsList &&
                DetailsList.map((data, index) => {
                  const totalUsd = Number(data.TotalUsd); // Convert to number
                  const bdt = Number(data.Bdt); // Convert to number

                  return (
                    <tr key={index}>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {data.Description}
                      </td>
                      {InputValues.InvoiceType &&
                      (InputValues.InvoiceType == "SEA Export Invoice" ||
                        InputValues.InvoiceType == "Local Charge(SEXP)") ? (
                        <>
                          <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                            {data.Unit}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                            {data.ContainerNo}
                          </td>
                          <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                            {data.ContainerSize}
                          </td>
                        </>
                      )}
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {data.ContainerQty}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {data.Usd}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {Number(totalUsd.toFixed(2)).toLocaleString()}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {Number(bdt.toFixed(2)).toLocaleString()}
                      </td>
                      <td className="p-2 border border-gray-400 items-center text-gray-800 font-semibold">
                        <button
                          onClick={() => handleDeleteRow(index)}
                          className="rounded-full p-2 bg-gray-800 text-white"
                        >
                          <AiOutlineClose />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <hr className=" ml-10 mr-5 border border-[#4a4a4a] opacity-50" />
        <div className="w-full flex  pt-3 pb-3 mb-5 pl-10 pr-5">
          <button
            type="button"
            onClick={uploadData}
            disabled={!isInvGenerated}
            className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
          >
            <span className="capitalize">Save Invoice</span>
          </button>
          <button
            type="button"
            onClick={ClearAll}
            className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#3e3e3e] bg-[#3e3e3e]  rounded-3xl hover:bg-[#2c2c2c] hover:text-white"
          >
            <span className="capitalize">Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SeaEntry;
