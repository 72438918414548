import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { db } from "../firebase";
import { Link } from "react-router-dom";
import { collection, orderBy, getDocs, query } from "firebase/firestore";

const DraftsEntry = () => {
  const [AirReports, setAirReports] = useState([]);
  const [SeaReports, setSeaReports] = useState([]);
  const [AirSearch, setAirSearch] = useState();
  const [SeaSearch, setSeaSearch] = useState();

  useEffect(() => {
    const unsubscribe = async () => {
      const q = query(
        collection(db, "SEA Reports"),
        orderBy("GeneralInfo.InvoiceNum", "asc")
      );
      const querySnapshot = await getDocs(q);
      setSeaReports(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    unsubscribe();

    const unsubscribe2 = async () => {
      const q = query(
        collection(db, "AIR Reports"),
        orderBy("GeneralInfo.InvoiceNum", "asc")
      );
      const querySnapshot = await getDocs(q);

      setAirReports(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    unsubscribe2();
  }, []);

  //Air reports Pagination

  const [currentAirPage, setCurrentAirPage] = useState(1);
  const AirreportsPerPage = 8;

  const filteredAirReports = AirReports
    ? AirReports.filter(
        (data) =>
          !AirSearch ||
          (data.GeneralInfo.InvoiceNum &&
            data.GeneralInfo.InvoiceNum.toLowerCase().includes(
              AirSearch.toLowerCase()
            ))
      )
    : [];
  const indexOfLastAirUser = currentAirPage * AirreportsPerPage;
  const indexOfFirstAirUser = indexOfLastAirUser - AirreportsPerPage;
  const currentAirList = filteredAirReports.slice(
    indexOfFirstAirUser,
    indexOfLastAirUser
  );
  //Sea Reports Pagination

  const [currentSeaPage, setCurrentSeaPage] = useState(1);
  const SeareportsPerPage = 8;

  const filteredSeaReports = SeaReports
    ? SeaReports.filter(
        (data) =>
          !SeaSearch ||
          (data.GeneralInfo.InvoiceNum &&
            data.GeneralInfo.InvoiceNum.toLowerCase().includes(
              SeaSearch.toLowerCase()
            ))
      )
    : [];
  const indexOfLastSeaUser = currentSeaPage * SeareportsPerPage;
  const indexOfFirstSeaUser = indexOfLastSeaUser - SeareportsPerPage;
  const currentSeaList = filteredSeaReports.slice(
    indexOfFirstSeaUser,
    indexOfLastSeaUser
  );

  return (
    <>
      <div className="w-full pt-3 pb-3 pl-10 pr-5 relative overflow-x-auto">
        <div className="flex flex-row justify-between ">
          <div className="flex flex-row items-center justify-between bg-gray-800 p-3 w-full rounded-tl-[10px] rounded-tr-[10px]">
            <p className="text-14 font-semibold  text-white">Air Reports</p>
            <div className="flex gap-3 py-3">
              <input
                type="text"
                name="search"
                id="search"
                value={AirSearch}
                onChange={(e) => setAirSearch(e.target.value)}
                className="bg-gray-50 border w-96 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Invoice No...."
                required
              />
            </div>
          </div>
        </div>

        <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="p-2 border border-gray-400">Invoice Num</th>
              <th className="p-2 border border-gray-400">Invoice Type</th>
              <th className="p-2 border border-gray-400">Invoice Date</th>
              <th className="p-2 border border-gray-400">Destination</th>
              <th className="p-2 border border-gray-400">ETD</th>
              <th className="p-2 border border-gray-400">ETA</th>
              <th className="p-2 border border-gray-400">Action</th>
            </tr>
          </thead>
          <tbody>
            {AirReports &&
              currentAirList.map((data, index) => (
                <tr key={index}>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.InvoiceNum}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.InvoiceType}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.InvoiceDate
                      ? data.GeneralInfo.InvoiceDate
                      : " "}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.Destination}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.Etd}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.Eta}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    <Link
                      to={`/EntryReview/${data.id}?invoiceType=${data.GeneralInfo.InvoiceType}`}
                      className="flex w-fit text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="pagination flex  justify-start mt-4 ">
          <Pagination
            activePage={currentAirPage}
            itemsCountPerPage={AirreportsPerPage}
            totalItemsCount={filteredAirReports.length}
            pageRangeDisplayed={5}
            onChange={setCurrentAirPage}
            itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
            linkClass="block"
            activeLinkClass="text-gray-200"
            disabledClass="opacity-50 cursor-not-allowed"
          />
        </div>
      </div>

      {/* Sea Reports */}

      <div className="w-full pt-6 pb-3 pl-10 pr-5 relative overflow-x-auto">
        <div className="flex flex-row justify-between ">
          <div className="flex flex-row items-center justify-between bg-gray-800 p-3 w-full rounded-tl-[10px] rounded-tr-[10px]">
            <p className="text-14 font-semibold  text-white">Sea Reports</p>
            <div className="flex gap-3 py-3">
              <input
                type="text"
                name="search"
                id="search"
                value={SeaSearch}
                onChange={(e) => setSeaSearch(e.target.value)}
                className="bg-gray-50 border w-96 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Invoice No...."
                required
              />
            </div>
          </div>
        </div>

        <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="p-2 border border-gray-400">Invoice Num</th>
              <th className="p-2 border border-gray-400">Invoice Type</th>
              <th className="p-2 border border-gray-400">Invoice Date</th>
              <th className="p-2 border border-gray-400">Destination</th>
              <th className="p-2 border border-gray-400">ETD</th>
              <th className="p-2 border border-gray-400">ETA</th>
              <th className="p-2 border border-gray-400">Action</th>
            </tr>
          </thead>
          <tbody>
            {SeaReports &&
              currentSeaList.map((data, index) => (
                <tr key={index}>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.InvoiceNum}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.InvoiceType}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.InvoiceDate
                      ? data.GeneralInfo.InvoiceDate
                      : " "}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.Destination}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.Etd}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.GeneralInfo.Eta}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    <Link
                      to={`/EntryReview/${data.id}?invoiceType=${data.GeneralInfo.InvoiceType}`}
                      className="flex w-fit text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="pagination flex  justify-start mt-4 ">
          <Pagination
            activePage={currentSeaPage}
            itemsCountPerPage={SeareportsPerPage}
            totalItemsCount={filteredSeaReports.length}
            pageRangeDisplayed={5}
            onChange={setCurrentSeaPage}
            itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
            linkClass="block"
            activeLinkClass="text-gray-200"
            disabledClass="opacity-50 cursor-not-allowed"
          />
        </div>
      </div>
    </>
  );
};

export default DraftsEntry;
