import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Address, setAddress] = useState(" ");
  const [role, setRole] = useState("");
  const [designation, setDesignation] = useState("");
  const [EmpName, setEmpName] = useState();
  const [image, setImage] = useState(null);

  const { createUser, user } = useStateContext();

  const ToastError = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleCreateUser = async (e) => {
    e.preventDefault();
    if (image != null) {
      try {
        const storage = getStorage();
        const signRef = ref(storage, `signature/${image.name}`);
        await uploadBytes(signRef, image);
        const signURL = await getDownloadURL(signRef);
        await createUser(username, password)
          .then(async (userCred) => {
            try {
              const docRef = doc(db, "users", userCred.user.uid);
              const data = {
                username: username,
                password: password,
                role: role,
                address: Address,
                designation: designation,
                signURL: signURL,
                CreatedBy: user.email,
                EmpName: EmpName,
              };

              setDoc(docRef, data).then(() => {
                toast.success("Successfully added", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                setPassword("");
                setRole("..Select..");
                setUsername("");
                setAddress("");
                setDesignation("");
                setEmpName(" ");
                setImage(null);
              });
            } catch (e) {
              ToastError(e.message);
            }
          })
          .catch((err) => {
            ToastError(err.message);
          });
      } catch (e) {
        ToastError(e.message);
      }
    } else {
      await createUser(username, password)
        .then(async (userCred) => {
          try {
            const docRef = doc(db, "users", userCred.user.uid);
            const data = {
              username: username,
              password: password,
              role: role,
              address: Address,
              designation: designation,
              signURL: " ",
              CreatedBy: user.email,
              EmpName: EmpName,
            };

            setDoc(docRef, data).then(() => {
              toast.success("Successfully added", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setPassword("");
              setRole("..Select..");
              setUsername("");
              setAddress("");
              setDesignation("");
              setEmpName(" ");
              setImage(null);
            });
          } catch (e) {
            ToastError(e.message);
          }
        })
        .catch((err) => {
          ToastError(err.message);
        });
    }
  };
  return (
    <div className="flex flex-col items-center mt-20 px-6 py-8 mx-auto md:h-screen lg:py-0">
      <ToastContainer />
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            Add a New User
          </h1>
          <form className="space-y-4 md:space-y-6" action="#">
            <div className="flex gap-3">
              <div className="w-1/2">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Username
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-1/2">
                <label
                  htmlFor="EmpName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Employee Name
                </label>
                <input
                  type="text"
                  name="EmpName"
                  value={EmpName}
                  onChange={(e) => setEmpName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Employee Name.."
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="role"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select Role
                </label>
                <select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>..Select.. </option>
                  <option>Admin</option>
                  <option>Air Export & Local Charge(AEXP)</option>
                  <option>Air Import & Sea Import</option>
                  <option>Sea Export & Local Charge(SEXP)</option>
                  <option>Viewer & Downloader</option>
                  <option>Sea Import & Export</option>
                </select>
              </div>
            </div>

            <div className="flex gap-3">
              <div className="w-1/2">
                <label
                  htmlFor="role"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Designation
                </label>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="User Designation"
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Dhaka Bangladesh"
                />
              </div>
            </div>

            <div className="flex gap-3">
              <div className="w-1/2">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Signatue
                </label>
                <input
                  type="file"
                  name="image"
                  value={undefined}
                  onChange={(e) => setImage(e.target.files[0])}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Upload signature"
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-32 text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={(e) => handleCreateUser(e)}
            >
              Create
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
