import React from "react";

const AdminDashboardCard = ({
  title,
  number,
  descHead,
  descText,
  icon,
  fromColor,
  toColor,
}) => {
  return (
    <div className="w-1/4 h-36  bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-between  font-semibold ">
        <div className="flex flex-col justify-center gap-3 ml-5 mt-5">
          <h4 className="text-[16px] text-gray-600 capitalize">{title}</h4>
          <p className="text-[24px] text-gray-900 font-bold">{number}</p>
          <p className="text-[12px] text-gray-600">
            <span className="text-[#FF5C8E] pr-1">{descHead}</span> {descText}
          </p>
        </div>
        <div
          className="text-[14px] text-white flex justify-center items-center rounded-[50%] p-3 h-10 mt-4 mr-4"
          style={{
            backgroundImage: `linear-gradient(to right, ${fromColor}, ${toColor})`,
          }}
        >
          {icon}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardCard;
