import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import numberToWords from "number-to-words";

import html2pdf from "html2pdf.js";

import Pam_logo from "../data/pam_logo (1).png";
import Pam_icon from "../data/pam_icon.png";
import Partex_logo from "../data/partex_logo.png";
import AuthrizeSignature from "../data/AuthrizeSignature.jpg";

import { useStateContext } from "../contexts/ContextProvider";

const AirImportExport = () => {
  const [DetailsInfo, setDetailsInfo] = useState([]);
  const [GeneralInfo, setGeneralInfo] = useState({});
  const [bankDetails, setBankDetails] = useState();
  const [userData, setUserData] = useState();
  const [signURL, setSignURL] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();

  const { user } = useStateContext();

  const handleDownload = () => {
    const downloadSection = componentRef.current;

    if (downloadSection) {
      html2pdf()
        .set({
          margin: 2,
          filename: `${GeneralInfo.InvoiceNum}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },

          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
        .from(downloadSection)
        .save();
    }
  };

  useEffect(() => {
    const getData = async () => {
      const singleData = await getDoc(
        doc(collection(db, "AIR Reports"), id)
      ).then((snapshot) => {
        if (snapshot.exists()) {
          setGeneralInfo(snapshot.data().GeneralInfo);
          setDetailsInfo(snapshot.data().DetailsInfo);
        }
      });
    };

    getData();
  }, [id]);

  useEffect(() => {
    const getBankDetails = async () => {
      const q = query(
        collection(db, "BankName"),
        where("name", "==", GeneralInfo.BName)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          setBankDetails(doc.data());
        });
      }
    };

    getBankDetails();
  }, [GeneralInfo.BName]);

  useEffect(() => {
    const getUserData = async () => {
      const docRef = doc(db, "users", GeneralInfo.CreatedBy);
      const docSnap = await getDoc(docRef);
      setUserData(docSnap.data());
      setSignURL(docSnap.data().signURL);
    };

    getUserData();
  }, [GeneralInfo.CreatedBy]);

  const TotalAmountCalculation = DetailsInfo.reduce((sum, data) => {
    if (Number(data.Bdt) > 0.0) {
      return sum + Number(data.Bdt);
    } else {
      return sum + Number(data.TotalUsd);
    }
  }, 0).toFixed(2);

  const TotalBdtCalculation = DetailsInfo.reduce((sum, data) => {
    return sum + Number(data.Bdt);
  }, 0).toFixed(2);

  const isBdtCalculation = TotalBdtCalculation > 0; // Check if BDT calculation was used
  //const amountInWords = numberToWords.toWords(TotalAmountCalculation);

  const integerPart = Math.floor(TotalAmountCalculation);
  const decimalPart = Number(TotalAmountCalculation) % 1;

  const amountInWords = numberToWords.toWords(integerPart);

  let decimalInWords = "";
  if (decimalPart > 0) {
    decimalInWords =
      "point " + numberToWords.toWords(Math.round(decimalPart * 100));
  }
  function capitalizeFirstLetters(str) {
    const words = str.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  }

  const capitalizedAmountInWords =
    capitalizeFirstLetters(amountInWords) + " " + decimalInWords;

  return (
    <>
      <div className="w-full border-1 text-white flex px-5 py-3 bg-[#c7c7c7] justify-between mt-20 md:mt-0 lg:mt-0">
        <p className="text-gray-800 px-2 py-1 font-bold text-14">Preview</p>
        <div className="">
          <ReactToPrint
            trigger={() => (
              <button className="bg-[#ff5c8e] rounded-lg px-2 py-1 mx-1 text-14 hover:bg-[#9e103b]">
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
          <button
            onClick={handleDownload}
            className="bg-[#1d1d1d] rounded-lg px-2 py-1 mx-1 text-14 hover:bg-[#0b0a0b] "
          >
            Downlaod
          </button>
        </div>
      </div>
      <div
        className="w-full flex mt-5 px-8 py-10 flex-col justify-center items-center"
        ref={componentRef}
      >
        <div className="h-[900px]">
          <div className="w-full flex flex-row justify-between">
            <div className="ml-2 tracking-wider">
              <img className="w-24 " src={Pam_logo} />
              <p className="text-base font-bold">
                Partex Aeromarine Logistics Ltd.
              </p>
            </div>
            <div className="mr-5">
              <img className="w-28" src={Pam_icon} />
              <p className="text-[8px]">Your ultimate logistic partner</p>
            </div>
          </div>
          <hr className="w-full" />
          <p className="font-bold text-sm text-center">
            {GeneralInfo && GeneralInfo.InvoiceType}
          </p>
          <div className="w-full  flex flex-row justify-start">
            <div className="w-1/2">
              <div className="w-[250px]">
                <p className="text-xs font-bold">TO</p>
                <div className="border-1 border-gray-800 h-24">
                  {GeneralInfo.To && (
                    <p
                      className="text-[10px] font-bold"
                      dangerouslySetInnerHTML={{ __html: GeneralInfo.To }}
                    ></p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/2 pl-28">
              <table className="leading-4">
                <tr>
                  <td className="font-bold pr-8 text-[10px]">Invoice Date</td>
                  <td>:</td>
                  <td className="text-[10px]">
                    {GeneralInfo && GeneralInfo.InvoiceDate}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold text-[10px] pr-8">Invoice No</td>
                  <td>:</td>
                  <td className="text-[10px]">
                    {GeneralInfo && GeneralInfo.InvoiceNum}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold text-[10px] pr-8">Currency</td>
                  <td>:</td>
                  <td className="text-[10px]">
                    {GeneralInfo && GeneralInfo.Bcurr}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold text-[10px] pr-8">Currency Rate</td>
                  <td>:</td>
                  <td className="text-[10px]">
                    {GeneralInfo && GeneralInfo.BcurrRate}
                  </td>
                </tr>
                {GeneralInfo.InvoiceType &&
                GeneralInfo.InvoiceType == "AIR Import Invoice" ? (
                  <tr>
                    <td className="font-bold text-[10px] pr-8">L/C No</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.LcNo}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className="font-bold text-[10px] pr-8">
                      Com.Invoice No
                    </td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.ComInvNo}
                    </td>
                  </tr>
                )}

                <tr>
                  <td className="font-bold text-[10px] pr-8">Job No</td>
                  <td>:</td>
                  <td className="text-[10px]">
                    {GeneralInfo && GeneralInfo.JobNo}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="w-full flex flex-row justify-start border-1 border-gray-800 mt-1">
            <div className="w-[60%]">
              <table className="leading-4">
                <tbody>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">Shipper Name</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.ShipName}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">
                      Consignee/Notify
                    </td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.Consignee}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">CH.Weight</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.ChWeight}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">HAWB No</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.Hbl}
                    </td>
                  </tr>
                  {GeneralInfo.InvoiceType &&
                    GeneralInfo.InvoiceType == "AIR Import Invoice" && (
                      <tr>
                        <td className="font-bold pr-8 text-[10px]">AIR INV</td>
                        <td>:</td>
                        <td className="text-[10px]">
                          {GeneralInfo && GeneralInfo.AirInv}
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">MAWB No</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.Mbl}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">ETD</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.Etd}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-[40%]">
              <table className="leading-4">
                <tbody>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">AIR LINES</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.ShippingLine}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">Flight No</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.FdrVessel}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-bold pr-8 text-[10px]">POL</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.Pol}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">ETA</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.Eta}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">Destination</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.Destination}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold pr-8 text-[10px]">Payment Mode</td>
                    <td>:</td>
                    <td className="text-[10px]">
                      {GeneralInfo && GeneralInfo.PaymentMode}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full pt-1">
            <div>
              <table className="border-collapse border  border-gray-800 w-full leading-4">
                <thead>
                  <tr>
                    <th className="p-1 border border-gray-800 text-[10px]">
                      Description
                    </th>
                    <th className="p-1 border border-gray-800 text-[10px]">
                      HAWB No
                    </th>
                    <th className="p-1 border border-gray-800 text-[10px]">
                      PKG
                    </th>
                    <th className="p-1 border border-gray-800 text-[10px]">
                      CH.W.
                    </th>
                    <th className="p-1 border border-gray-800 text-[10px]">
                      Rate
                    </th>
                    <th className="p-1 border border-gray-800 text-[10px]">
                      Currency
                    </th>
                    {GeneralInfo &&
                    GeneralInfo.InvoiceType == "Local Charge(AEXP)" &&
                    isBdtCalculation ? (
                      <></>
                    ) : (
                      <th className="p-1 border border-gray-800 text-[10px]">
                        Amount({GeneralInfo.Bcurr})
                      </th>
                    )}
                    <th className="p-1 border border-gray-800 text-[10px]">
                      Amout(BDT)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {DetailsInfo &&
                    DetailsInfo.map((item, index) => {
                      const totalUsd = Number(item.TotalUsd); // Convert to number
                      const bdt = Number(item.Bdt); // Convert to number
                      return (
                        <tr key={index}>
                          <td className="p-1 border border-gray-800 text-[10px]">
                            {item.Description}
                          </td>
                          <td className="p-1 border border-gray-800 text-[10px]">
                            {item.ContainerNo}
                          </td>
                          <td className="p-1 text-center border border-gray-800 text-[10px]">
                            {item.ContainerSize}
                          </td>
                          <td className="p-1 text-center border border-gray-800 text-[10px]">
                            {item.ContainerQty}
                          </td>
                          <td className="p-1 text-center border border-gray-800 text-[10px]">
                            {item.Usd}
                          </td>
                          <td className="p-1 text-center border  border-gray-800 text-[10px]">
                            {item.Currency}
                          </td>
                          {GeneralInfo &&
                          GeneralInfo.InvoiceType == "Local Charge(AEXP)" &&
                          isBdtCalculation ? (
                            <></>
                          ) : (
                            <td className="p-1 border text-center border-gray-800 text-[10px]">
                              {Number(totalUsd.toFixed(2)).toLocaleString()}
                            </td>
                          )}
                          <td className="p-1 border text-center border-gray-800 text-[10px]">
                            {Number(bdt.toFixed(2)).toLocaleString()}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      className="p-1 border text-left border-gray-800 text-gray-800 font-bold text-[10px]"
                      colSpan="6"
                    >
                      Total
                    </td>
                    {GeneralInfo &&
                    GeneralInfo.InvoiceType == "Local Charge(AEXP)" &&
                    isBdtCalculation ? (
                      <></>
                    ) : (
                      <td className="p-1 border text-center border-gray-800 text-gray-800 font-bold text-[10px]">
                        {Number(
                          DetailsInfo.reduce(
                            (sum, data) => sum + Number(data.TotalUsd),
                            0
                          ).toFixed(2)
                        ).toLocaleString()}
                      </td>
                    )}

                    <td className="p-1 border text-center border-gray-800 text-gray-800 font-bold text-[10px]">
                      {Number(
                        DetailsInfo.reduce(
                          (sum, data) => sum + Number(data.Bdt),
                          0
                        ).toFixed(2)
                      ).toLocaleString()}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="pt-1 text-[10px]">
              <p className="font-bold">
                Amount in Words:{" "}
                <span className="font-normal pl-2">
                  {isBdtCalculation ? "Taka" : GeneralInfo.Bcurr}{" "}
                  {capitalizedAmountInWords} Only
                </span>
              </p>
            </div>
          </div>
          <hr className="border-1 mt-1 border-gray-800 w-full" />
          <div className="w-full pt-1">
            <p className="font-bold text-[10px]">Note: </p>
            <p className="underline capitalize text-[10px]">
              Bank Details for Wire Transfer:
            </p>

            <table className="leading-4">
              <tr>
                <td className="font-bold pr-8 text-[10px]">ACCOUNT NAME</td>
                <td>:</td>
                <td className="text-[10px]">
                  {bankDetails && bankDetails.ac_name}
                </td>
              </tr>
              <tr>
                <td className="font-bold pr-8 text-[10px]">ACCOUNT NUMBER</td>
                <td>:</td>
                <td className="text-[10px]">
                  {bankDetails && bankDetails.ac_no}
                </td>
              </tr>
              <tr>
                <td className="font-bold pr-8 text-[10px]">BANK NAME</td>
                <td>:</td>
                <td className="text-[10px]">
                  {bankDetails && bankDetails.name}
                </td>
              </tr>
              <tr>
                <td className="font-bold pr-8 text-[10px]">BANK ADDRESS</td>
                <td>:</td>
                <td className="text-[10px]">
                  {bankDetails && bankDetails.address}
                  <span>
                    &nbsp;&nbsp;Router No: {bankDetails && bankDetails.routing}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="font-bold pr-8 text-[10px]">SWIFT CODE</td>
                <td>:</td>
                <td className="text-[10px]">
                  {bankDetails && bankDetails.swift}
                </td>
              </tr>
            </table>
          </div>
          <div className="w-full pt-1">
            <p className="text-[10px] border-1 p-2 border-gray-800">
              <span className="underline font-bold">Terms:</span>
              &nbsp;Service rendered as per our trading conditions.
              Inconsistancy in account must be notified within 7(seven) days
              from submission.{" "}
              <b>
                Payment must be made by Account payee or Pay Order/Demand Draft
                drawn in favour of Partex Aeromarine Logistics Limited.
              </b>
            </p>
          </div>
          <div className="w-full flex pt-2 gap-56 ml-2">
            <p className="font-bold text-[10px] w-1/3">Prepared by</p>
            <p className="font-bold text-[10px] w-1/3">Created by</p>
            <p className="font-bold text-[10px] w-1/3">Authorised by</p>
          </div>
          <div className="w-full flex pt-2 gap-28 ml-2 ">
            <p className="flex flex-col  text-[10px]  w-1/3">
              <span>{userData && userData.EmpName}</span>
              <span>{userData && userData.designation}</span>
              <span className="font-bold">
                Partex Aeromarine Logistics Ltd.
              </span>
            </p>
            <p className="font-bold text-[10px] pl-5  w-1/3"></p>
            <div className="w-1/3 pl-10">
              <img className="w-32 h-11 pl-1" src={AuthrizeSignature} />
            </div>
          </div>
        </div>
        <div className="footer w-[95%]">
          <div className="w-full ml-1 mr-2 mt-5">
            <p className="font-bold text-[10px] mb-1">
              www.partexstargroup.com
            </p>
            <hr className="border-1 border-black" />
            <div className="flex flex-row justify-between">
              <div>
                <p className="font-bold text-[10px]">
                  Partex Aeromarine Logistics Ltd.
                </p>
                <p className="text-[10px]">(A Concern of Partex Star Group)</p>
              </div>
              <img className="w-20" src={Partex_logo} />
            </div>
          </div>
          <p className="w-full text-[8px] ml-2">
            <span className="font-bold ">Corporate Office:</span> Shanta Western
            Tower, Level-13, Bir Uttam Mir Shawkat Road, 186 Tejgaon I/A, Dhaka
            -1208, Bangladesh, Phone:+88 02 887 8800, Fax:+88 02 887 8815
          </p>
        </div>
      </div>
    </>
  );
};

export default AirImportExport;
