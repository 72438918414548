import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useParams, useNavigate } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import html2pdf from "html2pdf.js";

import "./Hawb.css";

import Pam_logo from "../data/pam_logo (1).png";

const HawbReports = () => {
  const [DetailsInfo, setDetailsInfo] = useState({});
  const [GeneralInfo, setGeneralInfo] = useState({});

  const { id } = useParams();

  const navigate = useNavigate();
  const componentRef = useRef();
  useEffect(() => {
    const getData = async () => {
      const singleData = await getDoc(
        doc(collection(db, "HAWB Reports"), id)
      ).then((snapshot) => {
        if (snapshot.exists()) {
          setGeneralInfo(snapshot.data().MainInfo);
          setDetailsInfo(snapshot.data().DetailsInfo);
        }
      });
    };

    getData();
  }, []);
  const handleDownload = () => {
    const downloadSection = componentRef.current;

    if (downloadSection) {
      html2pdf()
        .set({
          margin: 5,
          filename: `${GeneralInfo.hawbNo}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
        .from(downloadSection)
        .save();
    }
  };

  return (
    <>
      <div className="w-full border-1 text-white flex px-5 py-3 bg-[#c7c7c7] justify-between items-center mt-20 md:mt-0 lg:mt-0">
        <p className="text-gray-800 px-3 py-2 font-bold">Preview</p>
        <div className="">
          <ReactToPrint
            trigger={() => (
              <button className="bg-[#ff5c8e] rounded-lg px-3 py-2 mx-2 hover:bg-[#9e103b]">
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
          <button
            onClick={handleDownload}
            className="bg-[#1d1d1d] rounded-lg px-3 py-2 mx-2 hover:bg-[#0b0a0b] "
          >
            Downlaod
          </button>
        </div>
      </div>
      <div className="" ref={componentRef}>
        <div className="hawbBody ml-auto mr-auto mt-5 w-[90%] mb-3 ">
          <div className="flex pb-1">
            <p className="text-center font-bold text-[10px] w-1/2"></p>
            <p className="text-center font-bold text-[11px] w-1/2 flex justify-center">
              HAWB NO:{" "}
              <span className="pl-1">{GeneralInfo && GeneralInfo.hawbNo}</span>
            </p>
          </div>
          <div className="flex w-full">
            <div className="border-b border-l border-t border-gray-800 w-1/2 ">
              <div className="border-b border-gray-800 h-[110px] p-[2px]">
                <p className="text-[11px] font-bold">
                  Shipper's Name and Address
                </p>
                <p className="text-[8.5px] font-bold">
                  {GeneralInfo &&
                    GeneralInfo.ShipperName &&
                    GeneralInfo.ShipperName.split("\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                </p>
              </div>
              <div className="border-b border-gray-800 h-48 ">
                <div className="border-b border-gray-800 p-[2px]">
                  <p className="text-[11px] font-bold">
                    Consignee's Name and Address
                  </p>
                  <p className="text-[8.5px] font-bold">
                    {GeneralInfo &&
                      GeneralInfo.Consignee &&
                      GeneralInfo.Consignee.split("\n").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </p>
                </div>
                <div className="p-[2px]">
                  {" "}
                  <p className="text-[11px] font-bold">Also Notify</p>
                  <p className="text-[8.5px] font-bold">
                    {GeneralInfo && GeneralInfo.AlsoNotify}
                  </p>
                </div>
              </div>
              <div className="border-b border-gray-800 h-[45px] pl-1">
                <p className="text-[11px] font-bold">
                  Airport of Departure and Requested Routing
                </p>
                <p className="text-[8.5px] font-bold">
                  {GeneralInfo && GeneralInfo.AirportofDep}
                </p>
              </div>
              <div className="border-b border-gray-800 h-12 flex">
                <div className="w-1/3 border-r border-gray-800">
                  <p className="pl-[2px] text-[11px] font-bold">
                    I.A.T.A. Code
                  </p>
                </div>
                <div className="w-2/3">
                  <p className="pl-[2px] text-[11px] font-bold">MAWB No</p>
                  <p className="pl-[2px] text-[12px] font-bold">
                    {GeneralInfo && GeneralInfo.mawb}
                  </p>
                </div>
              </div>
              <div className=" h-12 flex">
                <div className="w-1/2 border-r border-gray-800 pl-1">
                  <p className="text-[11px] font-bold">
                    Airport of Destination
                  </p>
                  <p className="text-[8.5px] font-bold">
                    {GeneralInfo && GeneralInfo.AirportofDest}
                  </p>
                </div>
                <div className="w-1/2 flex">
                  <div className="w-1/2 border-r border-gray-800">
                    <p className="text-[11px] font-bold pl-1">Flight No</p>
                    <p className="text-[9px] font-bold pl-1">
                      {GeneralInfo && GeneralInfo.FlightNo}
                    </p>
                  </div>
                  <div className="">
                    <p className="text-[11px] font-bold pl-1">Flight Date</p>
                    <p className="text-[9px] font-bold pl-1">
                      {GeneralInfo && GeneralInfo.FlightDate}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-1 border-gray-800 w-1/2">
              <div className="border-b border-gray-800 h-[110px]">
                <div className="border-b border-gray-800 p-[2px]">
                  <p className="text-[8.5px] font-bold">Not Negotiable</p>
                  <p className="text-[12px] font-bold">Air Waybill</p>
                  <p className="text-[8.5px] font-bold">
                    (Air Consignment Note)
                  </p>
                  <img className="w-[60px]" src={Pam_logo} />
                  <p className="font-bold text-[11px] p-[2px]">
                    Partex Aeromarine Logistics Ltd.
                  </p>
                </div>
                <p className=" text-[8.5px] font-bold p-[2px]">
                  Copies 1,2 and 3 of this Air waybill are originals and have
                  the same validity
                </p>
              </div>
              <div className="border-b border-gray-800 h-48">
                <div className="border-b border-gray-800">
                  <p className="text-[8.5px] font-bold p-[2px]">
                    It is agreed that the goods described,herein are accepted in
                    apparent in apparent in good order and condition(except as
                    noted) for carriage SUBJECT TO THE CONDITIONS OF CONTRACT ON
                    THE REVERSE HERE OF THE SHIPPERS ATTENTION IS DRAWN TO THE
                    NOTICE CONCERNONG CARRIERS LIMITATION OF LIABILITY. Shipper
                    may increase such limitation of liability by declaring a
                    higher value for carriage and paying supplement charge if
                    required.
                  </p>
                </div>
                <div className="border-b border-gray-800 pl-1">
                  <p className="text-[8.5px] font-bold">
                    Acounting Information
                  </p>
                  <p className="text-[8.5px] font-bold pl-[2px] pb-11">
                    {GeneralInfo && GeneralInfo.Accounting}
                  </p>
                </div>
                <div className="flex w-full">
                  <div className="border-r  border-gray-800 w-1/2 pb-[53px]">
                    <p className="text-[8.5px] font-bold p-[2px]">
                      Cargo Arrival Date
                    </p>
                  </div>
                  <div>
                    <p className="text-[8.5px] font-bold p-[2px]">
                      Time and Date Consignee Notified
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-gray-800 h-[45px]">
                <div className="flex w-full">
                  <div className="border-r h-full border-gray-800 w-1/2 pb-[28px]">
                    <p className="text-[8.5px] font-bold p-[2px]">
                      Time & Date Broker Notified
                    </p>
                  </div>
                  <div>
                    <p className="text-[8.5px] font-bold p-[2px]">
                      Time and Date Cargo Collected
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-gray-800 h-12 flex">
                <div className="w-1/3 border-r border-gray-800">
                  <div className="flex w-full">
                    <div className="flex flex-col border-r border-gray-800 w-1/2 pb-[16px]">
                      <p className="text-[8px] font-bold pl-1 pr-1">Currency</p>
                      <hr className="border-[.75px] border-gray-800 w-full" />
                      <p className="text-[8.5px] font-bold"></p>
                    </div>
                    <div className="flex flex-col w-full">
                      <p className="text-[8.5px] font-bold p-1">WTNAL</p>
                      <hr className="border-[.75px] border-gray-800 w-full" />
                      <p className="flex w-full">
                        <span className="border-r border-gray-800 text-[8.5px] font-bold w-1/2 pb-[14px]">
                          PPD
                        </span>
                        <span className="text-[8.5px] font-bold">COLL</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-1/3 border-r border-gray-800 flex p-1">
                  <p className="text-[8.5px] font-bold flex flex-col justify-between">
                    <span>Other</span> <span> PPD COLL</span>
                  </p>
                  <p className="flex flex-col text-[8.5px] font-bold text-center justify-between">
                    <span>Declared value for Carriage</span>
                    <span>
                      {GeneralInfo && GeneralInfo.DectaredValueCarriage}
                    </span>
                  </p>
                </div>
                <div className="w-1/3  p-[2px]">
                  <p className="flex flex-col text-[8.5px] font-bold text-center justify-between">
                    <span>Declared value for Customs</span>

                    <span>
                      {GeneralInfo && GeneralInfo.DeclaredValueCustoms}
                    </span>
                  </p>
                </div>
              </div>
              <div className=" h-12 flex">
                <div className="w-1/3  border-r border-gray-800">
                  <p className="text-[8.5px] font-bold pl-1">
                    Amount of Insurance
                  </p>
                </div>
                <div className="w-2/3">
                  <p className="text-[8.5px] font-bold pl-1">
                    INSURANCE-If Carrier offers insurance, and such insurance is
                    requested in accordance with condition on reverse hereof,
                    indicate amount to be insured in figures in box marked
                    amount of insurance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex border-l border-r border-gray-800 pt-1 pb-1 h-9">
            <p className="text-[9px] font-bold pl-[2px] pr-3">
              Handling Information
            </p>
            <p className="text-[9px] font-bold pl-5">
              HANDLING AGENT:{" "}
              <span>{GeneralInfo && GeneralInfo.HandlingInfo}</span>
            </p>
          </div>

          <div className="w-full flex">
            <div className="border-b border-l border-t border-gray-800 w-1/2 ">
              <div className="  h-10 flex">
                <div className="border-b border-r border-gray-800 w-[23%]">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    No. of Pieces RCP
                  </p>
                </div>
                <div className="border-b border-r border-gray-800 w-[15%]">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    Gross Weight
                  </p>
                </div>
                <div className="border-b border-r border-gray-800 w-[5%]">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    Kg lb
                  </p>
                </div>
                <div className="border-b border-r border-gray-800 w-[22%]">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    Rate Class
                  </p>
                  <hr className="border-gray-800" />
                  <p className="text-[8.5px] font-bold text-center">
                    Commodity Item No
                  </p>
                </div>
                <div className="border-b border-r border-gray-800 w-[15%]">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    Chargable Weight
                  </p>
                </div>
                <div className="border-b border-gray-800 w-[20%]">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    Rate/Charge
                  </p>
                </div>
              </div>
              <div className=" h-48 flex">
                <div className="border-b border-r border-gray-800 w-[23%]">
                  <p className="text-[10px] font-bold text-center pl-1">
                    {DetailsInfo && DetailsInfo.NoOfPiecesRCP}
                  </p>

                  <p className="text-[8.5px] font-bold text-center pl-1 pt-[30%] relative">
                    {DetailsInfo &&
                      DetailsInfo.ShippingMarks &&
                      DetailsInfo.ShippingMarks.split("\n").map(
                        (line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        )
                      )}
                  </p>
                </div>
                <div className="border-b border-r border-gray-800 w-[15%]">
                  <p className="text-[10px] font-bold text-center pl-1">
                    {DetailsInfo && DetailsInfo.GrossWeight}
                  </p>
                </div>
                <div className="border-b border-r border-gray-800 w-[5%]">
                  <p className="text-[8.5px] font-bold text-center pl-1"></p>
                </div>
                <div className="border-b border-r border-gray-800 w-[22%]">
                  <p className="text-[10px] font-bold text-center pl-1">
                    {" "}
                    {DetailsInfo && DetailsInfo.CommodityItemNo}
                  </p>
                </div>
                <div className="border-b border-r border-gray-800 w-[15%]">
                  <p className="text-[10px] font-bold text-center pl-1">
                    {DetailsInfo && DetailsInfo.ChargableWeight}
                  </p>
                </div>
                <div className="border-b border-gray-800 w-[20%]">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    {DetailsInfo && DetailsInfo.RateCharge}
                  </p>
                </div>
              </div>
              <div className="border-b border-gray-800 h-9 flex justify-between">
                <div className="flex flex-col  ml-2 w-28">
                  <p className="border-b border-l border-r pl-1 pr-1 pt-[1px] pb-[2px]  border-gray-800 text-[8.5px] font-bold text-center">
                    Prepaid
                  </p>
                  <p className="pl-1 pr-1 pt-[1px] pb-[2px] text-[8.5px] font-bold text-center">
                    {GeneralInfo && GeneralInfo.WeightChargePrpaid}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="border-b border-l border-r border-gray-800 text-[8.5px] font-bold text-center pl-1 pr-1 pt-[1px] pb-[2px] ">
                    Weight Charge
                  </p>
                  <p className="flex items-center justify-center">
                    <hr className=" w-[1px] h-5 border-l border-gray-800 ml-[7px]" />
                  </p>
                </div>
                <div className="flex flex-col mr-2 w-28">
                  <p className="border-b border-l  border-r pl-1 pr-1 pt-[1px] pb-[2px]  border-gray-800 text-[8.5px] font-bold text-center">
                    Collect
                  </p>
                  <p className="pl-1 pr-1 pt-[1px] pb-[2px]  text-[8.5px] font-bold text-center">
                    {GeneralInfo && GeneralInfo.WeightChargeCollect}
                  </p>
                </div>
              </div>
              <div className="border-b border-gray-800 h-8 flex justify-between">
                <div className="flex flex-col  ml-2">
                  <p className=" text-[8.5px] font-bold text-center w-28"></p>
                  <p className="pl-1 pr-1 pt-[1px] pb-[2px] text-[8.5px] font-bold text-center">
                    {GeneralInfo && GeneralInfo.ValuationChargePrepaid}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="border-b border-l  border-r border-gray-800  text-[8.5px] font-bold text-center pl-1 pr-1 pt-[1px] pb-[2px]">
                    Valuation Charge
                  </p>
                  <p className="flex items-center justify-center">
                    <hr className=" w-[1px] h-4 border-l border-gray-800 ml-[7px]" />
                  </p>
                </div>
                <div className="flex flex-col mr-2">
                  <p className="text-[8.5px] font-bold text-center w-28"></p>
                  <p className="pl-1 pr-1 pt-[1px] pb-[2px] text-[8.5px] font-bold text-center">
                    {GeneralInfo && GeneralInfo.ValuationChargeCollect}
                  </p>
                </div>
              </div>
              <div className="border-b border-gray-800 h-[101px] ">
                <div className="border-b border-gray-800">
                  <div className="flex justify-center items-center flex-col ">
                    <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit ml-[4px]">
                      Tax
                    </p>
                    <hr className="text-center w-[1px] h-4 border-l border-gray-800 ml-[7px]" />
                  </div>
                </div>
                <div className="border-b border-gray-800">
                  <div className="flex justify-center items-center flex-col ">
                    <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit">
                      Total other Charges Due Agent
                    </p>
                    <hr className="text-center w-[1px] h-4 border-l border-gray-800 ml-[7px]" />
                  </div>
                </div>
                <div className="">
                  <div className="flex justify-center items-center flex-col ">
                    <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit">
                      Total other Charges Due Carrier
                    </p>
                    <hr className="text-center w-[1px] h-4 border-l border-gray-800 ml-[7px]" />
                  </div>
                </div>
              </div>
              <div className=" h-9  flex justify-between border-b border-gray-800">
                <div className="border-r border-gray-800 w-1/2 ml-1 mr-1">
                  <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit">
                    Total Prepaid
                  </p>
                  <p className="text-[8.5px] font-bold pt-1">
                    {GeneralInfo && GeneralInfo.TotalPrepaid}
                  </p>
                </div>
                <div className="ml-1 mr-1">
                  <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit">
                    Total Collect
                  </p>
                  <p className="text-[8.5px] font-bold pl-[2px] pt-1">
                    {GeneralInfo && GeneralInfo.TotalCollect}
                  </p>
                </div>
              </div>
              <div className=" h-7  flex justify-between border-b border-gray-800">
                <div className="border-r border-gray-800 w-1/2 ml-1 mr-1">
                  <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit">
                    Currency Conversion Ratio
                  </p>
                </div>
                <div className="ml-1 mr-1">
                  <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit">
                    CC Charges in Dest.Currency
                  </p>
                </div>
              </div>
              <div className=" h-8  flex justify-between ">
                <div className="border-r border-gray-800 w-1/2 ml-1 mr-1">
                  <p className=" p-[2px] text-[8.5px] font-bold w-fit">
                    For Carrier's Use only at Destination
                  </p>
                </div>
                <div className="ml-1 mr-1">
                  <p className="border-b border-l border-r border-gray-800 p-[2px] text-[8.5px] font-bold w-fit">
                    CC Charges in Dest.Currency
                  </p>
                </div>
              </div>
            </div>

            <div className="border-1 border-gray-800 w-1/2 ">
              <div className=" h-10 flex">
                <div className="border-b border-r border-gray-800 w-1/4">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    Total
                  </p>
                </div>
                <div className="border-b  border-gray-800 w-3/4">
                  <p className="text-[8.5px] font-bold text-center pl-1">
                    Nature and Quantity of Goods(Incl. Dimentions of Volume)
                  </p>
                </div>
              </div>
              <div className=" h-48 flex">
                <div className="border-b border-r border-gray-800 w-1/4">
                  <p className="text-[8.5px] font-bold text-center pl-1"></p>
                </div>
                <div className="border-b  border-gray-800 w-3/4 pl-1 pt-1">
                  <p className="text-[8.5px] font-bold pl-[2px] mr-5">
                    {DetailsInfo &&
                      DetailsInfo.NatureQuantity &&
                      DetailsInfo.NatureQuantity.split("\n").map(
                        (line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        )
                      )}
                  </p>
                </div>
              </div>
              <div className="border-b border-gray-800 h-9 ">
                <p className="text-[8.5px] font-bold pl-1 pb-1 flex">
                  <span className="pr-10">Other Charges:</span>
                  <span>
                    {GeneralInfo && GeneralInfo.OtherCharges == ""
                      ? 0.0
                      : GeneralInfo.OtherCharges}
                  </span>
                </p>
                <p className="text-[8.5px] font-bold pl-1 flex">
                  <span className="pr-10">AWS Fee:</span>
                  <span>
                    {GeneralInfo && GeneralInfo.AWSFee == ""
                      ? 0.0
                      : GeneralInfo.AWSFee}
                  </span>
                </p>
              </div>
              <div className="border-b border-gray-800 h-8 flex flex-col justify-between ">
                <p className="text-[8.5px] font-bold pl-1">Carriage: </p>
              </div>
              <div className="border-b border-gray-800 h-[101px] flex flex-col">
                <p className="text-[8.5px] font-bold ml-1 pb-[5%]">
                  Shipper certifies that the particulators on face hereof are
                  correct tht insofar as any part of the consignment contains
                  restricted articles, such part is properly described by name
                  and is in proper condition for carriage by air according to
                  the applicable Dangerous Goods Regulations.
                </p>
                <div className="flex justify-center items-center flex-col">
                  <p className="text-xs font-bold border-b border-gray-800">
                    {GeneralInfo && GeneralInfo.SignatureOfShipperoragent}
                  </p>
                  <p className="text-[8.5px] font-bold">
                    Signature of shipper or his agent
                  </p>
                </div>
              </div>
              <div className="border-b border-gray-800 h-16 flex pl-[2px] pr-1 pt-[5%] justify-between ">
                <p className="flex flex-col">
                  <span className="border-b border-gray-800 text-[8.5px] font-bold">
                    {GeneralInfo && GeneralInfo.ExecutedOn}
                  </span>
                  <span className="text-[8.5px] font-bold">
                    Executed on(Date)
                  </span>
                </p>
                <p className="flex flex-col">
                  <span className="border-b border-gray-800 text-[8.5px] font-bold">
                    {GeneralInfo && GeneralInfo.Place}
                  </span>
                  <span className="text-[8.5px] font-bold">at(Place)</span>
                </p>
                <p className="flex flex-col">
                  <span className="border-b border-gray-800 text-[8.5px] font-bold">
                    {GeneralInfo && GeneralInfo.SignatureofissuingCarrier}
                  </span>
                  <span className="text-[8.5px] font-bold">
                    Signature of Issuing Carrier or Its Agent
                  </span>
                </p>
              </div>
              <div className=" h-8 flex pl-[2px] pt-[2%]">
                <p className="text-[8.5px] font-bold w-1/2">
                  Total Collect Charges
                </p>
                <p className="text-[8.5px] font-bold">Please Code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HawbReports;
