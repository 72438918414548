import React from "react";

const ViewFiled = ({ name, value, rest }) => {
  return (
    <div className="flex flex-col md:w-1/3 ">
      <p className="text-sm px-3 mb-2 mt-2 pb-4 pt-4 w-[98%] bg-gray-100 drop-shadow rounded">
        {name}:<span className="font-bold text-sm pl-5">{value}</span>
      </p>
    </div>
  );
};

export default ViewFiled;
