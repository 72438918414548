import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";

import Pagination from "react-js-pagination";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const To = () => {
  const [To, setTo] = useState();
  const [ToList, setToList] = useState([]);
  const [selectedTO, setSelectedTO] = useState(null);

  const ToastError = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const AddTo = async (e) => {
    e.preventDefault();
    const formattedTo = To.replace(/\n/g, "<br>");
    await addDoc(collection(db, "To"), {
      desc: formattedTo,
    }).then(() => {
      setTo("");
      toast.success("Successfully added", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    const q = query(collection(db, "To"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data(), id: doc.id });
      });
      setToList(tempArr);
    });
    return () => unsubscribe();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 8;

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const currentToList = ToList
    ? ToList.slice(indexOfFirstUser, indexOfLastUser)
    : null;

  const handleEditClick = (item) => {
    setSelectedTO(item);
    setTo(item.desc);
  };

  const handleUpdateTo = async (e) => {
    e.preventDefault();
    const formattedTo = To.replace(/\n/g, "<br>");
    const toRef = doc(db, "To", selectedTO.id);
    await updateDoc(toRef, {
      desc: formattedTo,
    }).then(() => {
      setTo("");
      selectedTO(null);
      toast.success("Successfully updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };
  return (
    <div className="flex flex-col items-center md:items-start gap-3  mt-20 px-6 py-8 mx-auto lg:py-0">
      <ToastContainer />
      <div className="w-full bg-white rounded-xl shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            Add TO Details
          </h1>
          <form className="space-y-4 md:space-y-6" action="#">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                To
              </label>
              <textarea
                rows={2}
                name="to"
                id="to"
                value={To}
                onChange={(e) => setTo(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="to..."
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={selectedTO ? handleUpdateTo : AddTo}
            >
              {selectedTO ? "Update" : "Add"}
            </button>
          </form>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <p className="p-2 text-14 font-semibold bg-gray-800 text-white">
            List of To's
          </p>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 w-32 font-medium text-center text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                  Address
                </th>
                <th className="px-4 py-2 w-32 font-medium text-center text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentToList.map((item, index) => (
                <tr key={index}>
                  <td
                    className="px-4 py-2 w-32 font-medium text-left text-xs tracking-wider uppercase border-1 border-gray-700 bg-gray"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  ></td>
                  <td className="px-4 py-2 w-32 font-medium text-xs text-center tracking-wider uppercase border-1 border-gray-700 bg-gray">
                    <button
                      onClick={() => handleEditClick(item)}
                      className="text-[#FF5C8E] hover:underline focus:outline-none"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination flex  justify-start mt-4 ">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={usersPerPage}
              totalItemsCount={ToList ? ToList.length : 0}
              pageRangeDisplayed={5}
              onChange={setCurrentPage}
              itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
              linkClass="block"
              activeLinkClass="text-gray-200"
              disabledClass="opacity-50 cursor-not-allowed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default To;
