import React, { useState, useEffect, useRef } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import TextAreaInput from "../components/TextAreaInput";
import TextInput from "../components/TextInput";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditHawbPage = () => {
  const [DetailsInfo, setDetailsInfo] = useState({});
  const [GeneralInfo, setGeneralInfo] = useState({});

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const singleData = await getDoc(
        doc(collection(db, "HAWB Reports"), id)
      ).then((snapshot) => {
        if (snapshot.exists()) {
          setGeneralInfo(snapshot.data().MainInfo);
          setDetailsInfo(snapshot.data().DetailsInfo);
        }
      });
    };

    getData();
  }, []);

  const goBackHandler = () => {
    navigate(-1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGeneralInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDetailsChange = (event) => {
    const { name, value } = event.target;

    setDetailsInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const UpdateHandler = async () => {
    try {
      await updateDoc(doc(collection(db, "HAWB Reports"), id), {
        MainInfo: GeneralInfo,
        DetailsInfo: DetailsInfo,
      });
      toast.success("Updated Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {}
  };

  return (
    <div className="pt-20 md:pt-2 xl:pt-2">
      <ToastContainer />
      <div className="ml-auto mr-2 mt-2 mb-2 ">
        <p className="w-full flex justify-between font-semibold  p-3 bg-gray-300 rounded-lg hover:bg-gray-400">
          HAWB Edit
          <span className="text-xs text-gray-700">{GeneralInfo.hawbNo}</span>
        </p>
      </div>
      <div id="MainInfo">
        <div>
          <p className="font-bold ml-2 pt-2">Main Information</p>
          <hr className="border border-[#9e0734] opacity-50" />
        </div>
        <div className="w-full flex justify-between ml-auto pr-3 pl-5 pt-3"></div>
        <hr />

        <form className="w-full pt-3 pb-3">
          <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
            <TextInput
              type="text"
              label="HAWB No"
              name="hawbNo"
              value={GeneralInfo.hawbNo}
              onChange={handleChange}
            />
            <TextAreaInput
              label="Shipper Name and Address"
              name="ShipperName"
              value={GeneralInfo.ShipperName}
              onChange={handleChange}
            />
            <TextAreaInput
              type="text"
              label="Consignee's Name and Address"
              name="Consignee"
              value={GeneralInfo.Consignee}
              onChange={handleChange}
            />

            <div className="flex flex-col w-full px-3 pb-3 pt-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Also Notify
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                rows="1"
                name="AlsoNotify"
                value={GeneralInfo.AlsoNotify}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="flex flex-col w-full px-3 pb-3 pt-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Airport of Departure and Requested Routing
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                name="AirportofDep"
                value={GeneralInfo.AirportofDep}
                onChange={handleChange}
              />
            </div>
            <TextInput
              type="text"
              label="I.A.T.A. Code"
              name="Iata"
              value={GeneralInfo.Iata}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="MAWB No"
              name="mawb"
              value={GeneralInfo.mawb}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Airport of Destination"
              name="AirportofDest"
              value={GeneralInfo.AirportofDest}
              onChange={handleChange}
            />
            <TextAreaInput
              label="Flight Date"
              name="FlightDate"
              value={GeneralInfo.FlightDate}
              onChange={handleChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Flight No"
              name="FlightNo"
              value={GeneralInfo.FlightNo}
              onChange={handleChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Handling Information"
              name="HandlingInfo"
              value={GeneralInfo.HandlingInfo}
              onChange={handleChange}
            ></TextAreaInput>
            <TextAreaInput
              type="text"
              label="Accounting Information"
              name="Accounting"
              value={GeneralInfo.Accounting}
              onChange={handleChange}
            ></TextAreaInput>
            <TextInput
              type="text"
              label="Cargo Arrival Date"
              name="CargoArrival"
              value={GeneralInfo.CargoArrival}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Time & Date Consignee Notified"
              name="ConsigneeDate"
              value={GeneralInfo.ConsigneeDate}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Time & Date Broker Notified"
              name="BrokerNotifiedDate"
              value={GeneralInfo.BrokerNotifiedDate}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Time & Date Cargo Collected"
              name="CargoCollectedDate"
              value={GeneralInfo.CargoCollectedDate}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Currency"
              name="Currency"
              value={GeneralInfo.Currency}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Dectared Value for Carriage"
              name="DectaredValueCarriage"
              value={GeneralInfo.DectaredValueCarriage}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Declared Value for Customs"
              name="DeclaredValueCustoms"
              value={GeneralInfo.DeclaredValueCustoms}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Weight Charge Collect"
              name="WeightChargeCollect"
              value={GeneralInfo.WeightChargeCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Weight Charge Prepaid"
              name="WeightChargePrpaid"
              value={GeneralInfo.WeightChargePrpaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Amount of Insurance"
              name="InsAmount"
              value={GeneralInfo.InsAmount}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Valuation Charge Prepaid"
              name="ValuationChargePrepaid"
              value={GeneralInfo.ValuationChargePrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Valuation Charge Collect"
              name="ValuationChargeCollect"
              value={GeneralInfo.ValuationChargeCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Tax Prepaid"
              name="TaxPrepaid"
              value={GeneralInfo.TaxPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Tax Collect"
              name="TaxCollect"
              value={GeneralInfo.TaxCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Due Agent Prepaid"
              name="TotalOtherDueAgentPrepaid"
              value={GeneralInfo.TotalOtherDueAgentPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Due Agent Collect"
              name="TotalOthersDueAgentCollect"
              value={GeneralInfo.TotalOthersDueAgentCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Charges Due Carrier Prepaid"
              name="TotalOtherCarrierPrepaid"
              value={GeneralInfo.TotalOtherCarrierPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Charges Due Carrier Collect"
              name="TotalOtherCarrierCollect"
              value={GeneralInfo.TotalOtherCarrierCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Prepaid"
              name="TotalPrepaid"
              value={GeneralInfo.TotalPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Collect"
              name="TotalCollect"
              value={GeneralInfo.TotalCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Currency Conversion Rate Prepaid"
              name="CurrencyConvRatePrepaid"
              value={GeneralInfo.CurrencyConvRatePrepaid}
              onChange={handleChange}
            />

            <TextInput
              type="text"
              label="CC Charges in Dest. Currency"
              name="CCChargeDestCurrency"
              value={GeneralInfo.CCChargeDestCurrency}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Other Charges"
              name="OtherCharges"
              value={GeneralInfo.OtherCharges}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="AWS Fee"
              name="AWSFee"
              value={GeneralInfo.AWSFee}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Carriage"
              name="Carriage"
              value={GeneralInfo.Carriage}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Signature of Shipper or his Agent"
              name="SignatureOfShipperoragent"
              value={GeneralInfo.SignatureOfShipperoragent}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Executed On"
              name="ExecutedOn"
              value={GeneralInfo.ExecutedOn}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Place"
              name="Place"
              value={GeneralInfo.Place}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Signature of Issuing Carrier"
              name="SignatureofissuingCarrier"
              value={GeneralInfo.SignatureofissuingCarrier}
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="font-bold ml-2 pt-2">Details Information</p>
            <hr className="border border-[#9e0734] opacity-50" />
          </div>
          <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
            <TextAreaInput
              label="No of Pieces RCP"
              name="NoOfPiecesRCP"
              value={DetailsInfo.NoOfPiecesRCP}
              onChange={handleDetailsChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Gross Weight"
              name="GrossWeight"
              value={DetailsInfo.GrossWeight}
              onChange={handleDetailsChange}
            ></TextAreaInput>
            <TextInput
              type="text"
              label="Kg lb"
              name="Kglb"
              value={DetailsInfo.Kglb}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Rate Class"
              name="RateClass"
              value={DetailsInfo.RateClass}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Commodity Item No"
              name="CommodityItemNo"
              value={DetailsInfo.CommodityItemNo}
              onChange={handleDetailsChange}
            />

            <TextInput
              type="text"
              label="Chargeable Weight"
              name="ChargableWeight"
              value={DetailsInfo.ChargableWeight}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Rate/Charge"
              name="RateCharge"
              value={DetailsInfo.RateCharge}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Total"
              name="Total"
              value={DetailsInfo.Total}
              onChange={handleDetailsChange}
            />
            <TextAreaInput
              label="Nature and Quantity of Goods"
              name="NatureQuantity"
              value={DetailsInfo.NatureQuantity}
              onChange={handleDetailsChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Shipping Marks"
              name="ShippingMarks"
              value={DetailsInfo.ShippingMarks}
              onChange={handleDetailsChange}
            ></TextAreaInput>
          </div>
        </form>
        <hr className=" ml-10 mr-5" />
      </div>
      <hr className=" ml-10 mr-5 border border-[#4a4a4a] opacity-50" />
      <div className="w-full flex  pt-3 pb-3 mb-5 pl-10 pr-5">
        <button
          type="button"
          onClick={UpdateHandler}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
        >
          <span className="capitalize">Update HAWB</span>
        </button>
        <button
          type="button"
          onClick={goBackHandler}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#3e3e3e] bg-[#3e3e3e]  rounded-3xl hover:bg-[#2c2c2c] hover:text-white"
        >
          <span className="capitalize">Go Back</span>
        </button>
      </div>
    </div>
  );
};

export default EditHawbPage;
