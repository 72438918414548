import React, { useState, useEffect, useRef } from "react";

import Pagination from "react-js-pagination";

import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";

import { useStateContext } from "../contexts/ContextProvider";

const UserDetails = () => {
  const [userData, setUserData] = useState();
  const [selectedUser, setSelectedUser] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 8;
  useEffect(() => {
    const q = query(collection(db, "users"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data(), id: doc.id });
      });
      setUserData(tempArr);
    });
    return () => unsubscribe();
  }, []);

  const handleEditClick = (item) => {
    setSelectedUser(item);
  };

  /*   const handleUpdateBankName = async (e) => {
    e.preventDefault();

    const bankRef = doc(db, "users", selectedUser.id);
    await updateDoc(bankRef, {
      name: BankName,
      address: Address,
      ac_name: AcName,
      ac_no: AcNo,
      routing: routing,
      swift: swift,
    }).then(() => {
      setSelectedUser(null);
      
      toast.success("Successfully updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  }; */

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const currentUsers = userData
    ? userData.slice(indexOfFirstUser, indexOfLastUser)
    : null;

  return (
    <div className="w-full pt-3 pb-3 pl-10 pr-5 relative overflow-x-auto">
      <p className="p-2 text-14 font-semibold bg-gray-800 text-white">
        List of Users
      </p>
      <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="p-2 border border-gray-400">SL No.</th>
            <th className="p-2 border border-gray-400">User Name</th>
            <th className="p-2 border border-gray-400">Email</th>
            <th className="p-2 border border-gray-400">Designation</th>
            <th className="p-2 border border-gray-400">Role</th>
            <th className="p-2 border border-gray-400">Address</th>
            <th className="p-2 border border-gray-400">Action</th>
          </tr>
        </thead>
        <tbody>
          {userData &&
            currentUsers.map((item, index) => {
              const serialNumber = indexOfFirstUser + index + 1;
              return (
                <tr key={index}>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {serialNumber}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {item.EmpName}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {item.username}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {item.designation}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {item.role}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {item.address}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    <button className="rounded-full bg-gray-800 text-white p-2 text-xs">
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="pagination flex  justify-start mt-4 ">
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={usersPerPage}
          totalItemsCount={userData ? userData.length : 0}
          pageRangeDisplayed={5}
          onChange={setCurrentPage}
          itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
          linkClass="block"
          activeLinkClass="text-gray-200"
          disabledClass="opacity-50 cursor-not-allowed"
        />
      </div>
    </div>
  );
};

export default UserDetails;
