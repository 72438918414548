import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";

import Pagination from "react-js-pagination";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { parse, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useStateContext } from "../contexts/ContextProvider";

import { useNavigate } from "react-router-dom";
const AirReportSearch = () => {
  const [SearchValue, setSearchValue] = useState();
  const { user, role } = useStateContext();

  const [Data, setData] = useState();
  const [Data2, setData2] = useState();

  const [OriginalData1, setOriginalData1] = useState();
  const [OriginalData2, setOriginalData2] = useState();
  const [isFilterApplied, setisFilterApplied] = useState(false);

  const [startDateFilter, setStartDateFilter] = useState(new Date());
  const [endDateFilter, setEndDateFilter] = useState(new Date());

  const navigate = useNavigate();

  const HandleSearch = async (e) => {
    e.preventDefault();
    const q = query(
      collection(db, "AIR Reports"),
      where("GeneralInfo.InvoiceNum", "==", SearchValue)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        navigate(`/AirReports/${doc.id}`);
      });
    } else {
      toast.warning("Wrong Invoice Number", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSearchValue();
    }
  };

  const getData = async () => {
    if (role == "Air Export & Local Charge(AEXP)") {
      const q = query(
        collection(db, "AIR Reports"),
        where("GeneralInfo.InvoiceType", "==", "AIR Export Invoice")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let tempArr = [];
        querySnapshot.forEach((doc) => {
          tempArr.push({ ...doc.data() });
        });
        tempArr.sort((a, b) =>
          a.GeneralInfo.InvoiceNum.localeCompare(b.GeneralInfo.InvoiceNum)
        );

        setData(tempArr);
        setOriginalData1(tempArr);
      });

      const q2 = query(
        collection(db, "AIR Reports"),
        where("GeneralInfo.InvoiceType", "==", "Local Charge(AEXP)")
      );
      const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
        let tempArr = [];
        querySnapshot.forEach((doc) => {
          tempArr.push({ ...doc.data() });
        });
        if (tempArr.length > 0) {
          tempArr.sort((a, b) =>
            a.GeneralInfo.InvoiceNum.localeCompare(b.GeneralInfo.InvoiceNum)
          );

          setData2(tempArr);
          setOriginalData2(tempArr);
        }
      });

      return () => {
        unsubscribe();
        unsubscribe2();
      };
    } else if (role == "Air Import & Sea Import") {
      const q = query(
        collection(db, "AIR Reports"),
        where("GeneralInfo.InvoiceType", "==", "AIR Import Invoice")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let tempArr = [];
        querySnapshot.forEach((doc) => {
          tempArr.push({ ...doc.data() });
        });
        tempArr.sort((a, b) =>
          a.GeneralInfo.InvoiceNum.localeCompare(b.GeneralInfo.InvoiceNum)
        );

        setData(tempArr);
        setOriginalData1(tempArr);
      });

      return () => {
        unsubscribe();
      };
    }
  };

  const convertToDateObject = (dateString) => {
    try {
      // Parse the date string into a Date object
      const dateObject = parse(dateString, "MMMM dd, yyyy", new Date());

      // Check if the Date object is valid
      if (!isNaN(dateObject.getTime())) {
        return dateObject;
      }
    } catch (error) {
      // If parsing fails, handle the error (optional)
      console.error("Error while parsing the date:", error);
    }
  };

  const filterDataByDateRange = (data) => {
    // If both start and end dates are not selected, return the original data
    if (!startDateFilter || !endDateFilter) {
      return data;
    }

    const newStartDate = startDateFilter.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const newEndDate = endDateFilter.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    // Convert the selected date range to Date objects
    const startDate = convertToDateObject(newStartDate);
    const endDate = convertToDateObject(newEndDate);

    console.log(startDate, endDate);

    // If the conversion failed, return the original data
    if (!startDate || !endDate) {
      return data;
    }

    // Filter the data based on the selected date range
    return data.filter((item) => {
      const invoiceDate = convertToDateObject(item.GeneralInfo.InvoiceDate);

      return invoiceDate >= startDate && invoiceDate <= endDate;
    });
  };

  useEffect(() => {
    getData();
  }, [role]);

  const ApplyFilter = () => {
    setisFilterApplied(true);
    const filteredData = filterDataByDateRange(Data);
    setData(filteredData);
    const filteredData2 = filterDataByDateRange(Data2);

    setData2(filteredData2);
  };
  const clearFilter = () => {
    setStartDateFilter(new Date());
    setEndDateFilter(new Date());
    setData(OriginalData1);
    setData2(OriginalData2);
    setisFilterApplied(false);
  };

  //Pagination 1
  const [currentAirPage, setCurrentAirPage] = useState(1);
  const AirreportsPerPage = 8;

  const filteredAirReports = Data
    ? Data.filter(
        (data) =>
          !SearchValue ||
          (data.GeneralInfo.InvoiceNum &&
            data.GeneralInfo.InvoiceNum.toLowerCase().includes(
              SearchValue.toLowerCase()
            ))
      )
    : [];
  const indexOfLastAirUser = currentAirPage * AirreportsPerPage;
  const indexOfFirstAirUser = indexOfLastAirUser - AirreportsPerPage;
  const currentAirList = filteredAirReports.slice(
    indexOfFirstAirUser,
    indexOfLastAirUser
  );

  //pagination 2
  const [currentAirPage2, setCurrentAirPage2] = useState(1);
  const AirreportsPerPage2 = 8;

  const filteredAirReports2 = Data2
    ? Data2.filter(
        (data) =>
          !SearchValue ||
          (data.GeneralInfo.InvoiceNum &&
            data.GeneralInfo.InvoiceNum.toLowerCase().includes(
              SearchValue.toLowerCase()
            ))
      )
    : [];
  const indexOfLastAirUser2 = currentAirPage2 * AirreportsPerPage2;
  const indexOfFirstAirUser2 = indexOfLastAirUser2 - AirreportsPerPage2;
  const currentAirList2 = filteredAirReports2.slice(
    indexOfFirstAirUser2,
    indexOfLastAirUser2
  );

  return (
    <section className="bg-gray-100 dark:bg-gray-900">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
        <div className="w-full bg-slate-200 rounded-lg shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="w-full pt-3 pb-10 pl-10 pr-5 relative overflow-x-auto">
            <p className="text-center font-bold text-xl underline mt-5 mb-3">
              AIR Reports
            </p>

            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center justify-between bg-gray-800 p-3 w-full rounded-tl-[10px] rounded-tr-[10px]">
                <div className="flex gap-3 mb-3">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    value={SearchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="bg-gray-50 border w-96 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Invoice No...."
                    required
                  />
                  <button
                    type="submit"
                    className=" text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    onClick={(e) => HandleSearch(e)}
                  >
                    Search
                  </button>
                </div>
                <div className="flex items-center gap-2 justify-start">
                  <div className="flex flex-row gap-2 items-center justify-center w-full">
                    <p className="text-white">From</p>
                    <DatePicker
                      selected={startDateFilter}
                      onChange={(date) => setStartDateFilter(date)}
                      className="text-12 p-2 w-[120px] rounded-lg"
                    />
                  </div>
                  <div className="flex flex-row gap-1 items-center justify-center w-full">
                    <p className="text-white">To</p>
                    <DatePicker
                      selected={endDateFilter}
                      onChange={(date) => setEndDateFilter(date)}
                      className="text-12 p-2 w-[120px] rounded-lg"
                    />
                  </div>
                  <button
                    type="submit"
                    className=" text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    onClick={() =>
                      isFilterApplied ? clearFilter() : ApplyFilter()
                    }
                  >
                    {isFilterApplied ? "Clear" : "Filter"}
                  </button>
                </div>
              </div>
            </div>

            {Data && (
              <>
                <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th className="p-2 border border-gray-400">
                        Invoice Num
                      </th>
                      <th className="p-2 border border-gray-400">
                        Invoice Date
                      </th>
                      <th className="p-2 border border-gray-400">
                        Invoice Type
                      </th>

                      <th className="p-2 border border-gray-400">ETA</th>
                      <th className="p-2 border border-gray-400">ETD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAirList.map((item, index) => (
                      <tr key={index}>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceNum}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceDate}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceType}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Eta}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Etd}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination flex  justify-start mt-4 ">
                  <Pagination
                    activePage={currentAirPage}
                    itemsCountPerPage={AirreportsPerPage}
                    totalItemsCount={filteredAirReports.length}
                    pageRangeDisplayed={5}
                    onChange={setCurrentAirPage}
                    itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
                    linkClass="block"
                    activeLinkClass="text-gray-200"
                    disabledClass="opacity-50 cursor-not-allowed"
                  />
                </div>
              </>
            )}
            {Data2 && (
              <>
                <table className="w-full mt-5 text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th className="p-2 border border-gray-400">
                        Invoice Num
                      </th>
                      <th className="p-2 border border-gray-400">
                        Invoice Date
                      </th>
                      <th className="p-2 border border-gray-400">
                        Invoice Type
                      </th>

                      <th className="p-2 border border-gray-400">ETA</th>
                      <th className="p-2 border border-gray-400">ETD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAirList2.map((item, index) => (
                      <tr key={index}>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceNum}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceDate}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceType}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Eta}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Etd}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination flex  justify-start mt-4 ">
                  <Pagination
                    activePage={currentAirPage2}
                    itemsCountPerPage={AirreportsPerPage2}
                    totalItemsCount={filteredAirReports2.length}
                    pageRangeDisplayed={5}
                    onChange={setCurrentAirPage2}
                    itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
                    linkClass="block"
                    activeLinkClass="text-gray-200"
                    disabledClass="opacity-50 cursor-not-allowed"
                  />
                </div>
              </>
            )}
          </div>
          {/* <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <p className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 dark:text-white">
              Search for Air Import/Export Reports
            </p>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={SearchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Invoice No...."
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={(e) => HandleSearch(e)}
              >
                Search
              </button>
            </form>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default AirReportSearch;
