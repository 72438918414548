import React, { useEffect, useState } from "react";
import TextInput from "../components/TextInput";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../contexts/ContextProvider";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import TextAreaInput from "../components/TextAreaInput";

const Hawb = () => {
  const [isPaymentCleared, setIsPaymentCleared] = useState(true);
  const { user, role } = useStateContext();
  const [InputValues, setInputValues] = useState({
    CreatedBy: user.uid,
    hawbNo: "",
    ShipperName: "",
    CreatedDate: "",
    Consignee: "",
    AlsoNotify: "",
    AirportofDep: "",
    Iata: "",
    mawb: "",
    AirportofDest: "",
    FlightDate: "",
    FlightNo: "",
    HandlingInfo: "",
    Accounting: "",
    CargoArrival: "",
    ConsigneeDate: "",
    BrokerNotifiedDate: "",
    CargoCollectedDate: "",
    Currency: "",
    DectaredValueCarriage: "",
    DeclaredValueCustoms: "",
    InsAmount: "",
    WeightChargePrpaid: "",
    WeightChargeCollect: "",
    TaxPrepaid: "",
    ValuationChargePrepaid: "",
    ValuationChargeCollect: "",
    TaxCollect: "",
    TotalOtherDueAgentPrepaid: "",
    TotalOthersDueAgentCollect: "",
    TotalOtherCarrierPrepaid: "",
    TotalOtherCarrierCollect: "",
    TotalPrepaid: "",
    TotalCollect: "",
    CurrencyConvRatePrepaid: "",
    CCChargeDestCurrency: "",
    OtherCharges: "",
    AWSFee: "",
    Carriage: "",
    SignatureOfShipperoragent: " ",
    ExecutedOn: " ",
    Place: "",
    SignatureofissuingCarrier: "",
  });

  const [detailsInfo, setDetailsInfo] = useState({
    NoOfPiecesRCP: "",
    GrossWeight: " ",
    Kglb: "",

    RateClass: "",
    CommodityItemNo: "",
    ChargableWeight: "",
    RateCharge: "",
    Total: "",
    NatureQuantity: "",
    ShippingMarks: "",
  });

  const ClearAll = () => {
    setDetailsInfo({
      NoOfPiecesRCP: "",
      GrossWeight: " ",
      Kglb: "",

      RateClass: "",
      CommodityItemNo: "",
      ChargableWeight: "",
      RateCharge: "",
      Total: "",
      NatureQuantity: "",
      ShippingMarks: "",
    });

    setInputValues({
      CreatedBy: user.uid,
      hawbNo: "",
      ShipperName: "",
      CreatedDate: "",
      Consignee: "",
      AlsoNotify: "",
      AirportofDep: "",
      Iata: "",
      mawb: "",
      AirportofDest: "",
      FlightDate: "",
      HandlingInfo: "",
      Accounting: "",
      CargoArrival: "",
      ConsigneeDate: "",
      BrokerNotifiedDate: "",
      CargoCollectedDate: "",
      Currency: "",
      DectaredValueCarriage: "",
      DeclaredValueCustoms: "",
      InsAmount: "",
      WeightChargePrpaid: "",
      WeightChargeCollect: "",
      TaxPrepaid: "",
      ValuationChargePrepaid: "",
      ValuationChargeCollect: "",
      TaxCollect: "",
      TotalOtherDueAgentPrepaid: "",
      TotalOthersDueAgentCollect: "",
      TotalOtherCarrierPrepaid: "",
      TotalOtherCarrierCollect: "",
      TotalPrepaid: "",
      TotalCollect: "",
      CurrencyConvRatePrepaid: "",
      CCChargeDestCurrency: "",
      OtherCharges: "",
      AWSFee: "",
      Carriage: "",
      SignatureOfShipperoragent: " ",
      ExecutedOn: " ",
      Place: "",
      SignatureofissuingCarrier: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDetailsChange = (event) => {
    const { name, value } = event.target;

    setDetailsInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const uploadData = async () => {
    await addDoc(
      collection(db, "HAWB Reports"),

      {
        MainInfo: InputValues,
        DetailsInfo: detailsInfo,
      }
    ).then(async () => {
      toast.success("Successfully added", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
    ClearAll();
  };
  return (
    <div className="pt-20 md:pt-2 xl:pt-2">
      <ToastContainer />
      <div className="ml-auto mr-2 mt-2 mb-2">
        <h4 className="w-full flex justify-center font-semibold  p-3 bg-gray-300 rounded-lg hover:bg-gray-400">
          HAWB Entry Form
        </h4>
      </div>
      <div id="MainInfo">
        <div>
          <p className="font-bold ml-2 pt-2">Main Information</p>
          <hr className="border border-[#9e0734] opacity-50" />
        </div>
        <div className="w-full flex justify-between ml-auto pr-3 pl-5 pt-3">
          {/* <div>
            <p className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              USER NAME : <span className="font-normal">{user && user.name}</span>
            </p>
            <p className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Hawb No : <span className="font-normal">4958SWAFD</span>
            </p>
          </div>
          <div>
            <p className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              user Information : <span className="font-normal">{}</span>
            </p>
            
          </div> */}
        </div>
        <hr />

        <form className="w-full pt-3 pb-3">
          <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
            <TextInput
              type="text"
              label="HAWB No"
              name="hawbNo"
              value={InputValues.hawbNo}
              onChange={handleChange}
            />
            <TextAreaInput
              label="Shipper Name and Address"
              name="ShipperName"
              value={InputValues.ShipperName}
              onChange={handleChange}
            />
            <TextAreaInput
              type="text"
              label="Consignee's Name and Address"
              name="Consignee"
              value={InputValues.Consignee}
              onChange={handleChange}
            />

            <div className="flex flex-col w-full px-3 pb-3 pt-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Also Notify
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                rows="1"
                name="AlsoNotify"
                value={InputValues.AlsoNotify}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="flex flex-col w-full px-3 pb-3 pt-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Airport of Departure and Requested Routing
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                name="AirportofDep"
                value={InputValues.AirportofDep}
                onChange={handleChange}
              />
            </div>
            <TextInput
              type="text"
              label="I.A.T.A. Code"
              name="Iata"
              value={InputValues.Iata}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="MAWB No"
              name="mawb"
              value={InputValues.mawb}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Airport of Destination"
              name="AirportofDest"
              value={InputValues.AirportofDest}
              onChange={handleChange}
            />
            <TextAreaInput
              label="Flight Date"
              name="FlightDate"
              value={InputValues.FlightDate}
              onChange={handleChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Flight No"
              name="FlightNo"
              value={InputValues.FlightNo}
              onChange={handleChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Handling Information"
              name="HandlingInfo"
              value={InputValues.HandlingInfo}
              onChange={handleChange}
            ></TextAreaInput>
            <TextAreaInput
              type="text"
              label="Accounting Information"
              name="Accounting"
              value={InputValues.Accounting}
              onChange={handleChange}
            ></TextAreaInput>
            <TextInput
              type="text"
              label="Cargo Arrival Date"
              name="CargoArrival"
              value={InputValues.CargoArrival}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Time & Date Consignee Notified"
              name="ConsigneeDate"
              value={InputValues.ConsigneeDate}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Time & Date Broker Notified"
              name="BrokerNotifiedDate"
              value={InputValues.BrokerNotifiedDate}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Time & Date Cargo Collected"
              name="CargoCollectedDate"
              value={InputValues.CargoCollectedDate}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Currency"
              name="Currency"
              value={InputValues.Currency}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Dectared Value for Carriage"
              name="DectaredValueCarriage"
              value={InputValues.DectaredValueCarriage}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Declared Value for Customs"
              name="DeclaredValueCustoms"
              value={InputValues.DeclaredValueCustoms}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Weight Charge Collect"
              name="WeightChargeCollect"
              value={InputValues.WeightChargeCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Weight Charge Prepaid"
              name="WeightChargePrpaid"
              value={InputValues.WeightChargePrpaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Amount of Insurance"
              name="InsAmount"
              value={InputValues.InsAmount}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Valuation Charge Prepaid"
              name="ValuationChargePrepaid"
              value={InputValues.ValuationChargePrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Valuation Charge Collect"
              name="ValuationChargeCollect"
              value={InputValues.ValuationChargeCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Tax Prepaid"
              name="TaxPrepaid"
              value={InputValues.TaxPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Tax Collect"
              name="TaxCollect"
              value={InputValues.TaxCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Due Agent Prepaid"
              name="TotalOtherDueAgentPrepaid"
              value={InputValues.TotalOtherDueAgentPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Due Agent Collect"
              name="TotalOthersDueAgentCollect"
              value={InputValues.TotalOthersDueAgentCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Charges Due Carrier Prepaid"
              name="TotalOtherCarrierPrepaid"
              value={InputValues.TotalOtherCarrierPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Other Charges Due Carrier Collect"
              name="TotalOtherCarrierCollect"
              value={InputValues.TotalOtherCarrierCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Prepaid"
              name="TotalPrepaid"
              value={InputValues.TotalPrepaid}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Total Collect"
              name="TotalCollect"
              value={InputValues.TotalCollect}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Currency Conversion Rate Prepaid"
              name="CurrencyConvRatePrepaid"
              value={InputValues.CurrencyConvRatePrepaid}
              onChange={handleChange}
            />

            <TextInput
              type="text"
              label="CC Charges in Dest. Currency"
              name="CCChargeDestCurrency"
              value={InputValues.CCChargeDestCurrency}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Other Charges"
              name="OtherCharges"
              value={InputValues.OtherCharges}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="AWS Fee"
              name="AWSFee"
              value={InputValues.AWSFee}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Carriage"
              name="Carriage"
              value={InputValues.Carriage}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Signature of Shipper or his Agent"
              name="SignatureOfShipperoragent"
              value={InputValues.SignatureOfShipperoragent}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Executed On"
              name="ExecutedOn"
              value={InputValues.ExecutedOn}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Place"
              name="Place"
              value={InputValues.Place}
              onChange={handleChange}
            />
            <TextInput
              type="text"
              label="Signature of Issuing Carrier"
              name="SignatureofissuingCarrier"
              value={InputValues.SignatureofissuingCarrier}
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="font-bold ml-2 pt-2">Details Information</p>
            <hr className="border border-[#9e0734] opacity-50" />
          </div>
          <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
            <TextAreaInput
              label="No of Pieces RCP"
              name="NoOfPiecesRCP"
              value={detailsInfo.NoOfPiecesRCP}
              onChange={handleDetailsChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Gross Weight"
              name="GrossWeight"
              value={detailsInfo.GrossWeight}
              onChange={handleDetailsChange}
            ></TextAreaInput>
            <TextInput
              type="text"
              label="Kg lb"
              name="Kglb"
              value={detailsInfo.Kglb}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Rate Class"
              name="RateClass"
              value={detailsInfo.RateClass}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Commodity Item No"
              name="CommodityItemNo"
              value={detailsInfo.CommodityItemNo}
              onChange={handleDetailsChange}
            />

            <TextInput
              type="text"
              label="Chargeable Weight"
              name="ChargableWeight"
              value={detailsInfo.ChargableWeight}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Rate/Charge"
              name="RateCharge"
              value={detailsInfo.RateCharge}
              onChange={handleDetailsChange}
            />
            <TextInput
              type="text"
              label="Total"
              name="Total"
              value={detailsInfo.Total}
              onChange={handleDetailsChange}
            />
            <TextAreaInput
              label="Nature and Quantity of Goods"
              name="NatureQuantity"
              value={detailsInfo.NatureQuantity}
              onChange={handleDetailsChange}
            ></TextAreaInput>
            <TextAreaInput
              label="Shipping Marks"
              name="ShippingMarks"
              value={detailsInfo.ShippingMarks}
              onChange={handleDetailsChange}
            ></TextAreaInput>
          </div>
        </form>
        <hr className=" ml-10 mr-5" />
      </div>
      <hr className=" ml-10 mr-5 border border-[#4a4a4a] opacity-50" />
      <div className="w-full flex  pt-3 pb-3 mb-5 pl-10 pr-5">
        <button
          type="button"
          onClick={uploadData}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
        >
          <span className="capitalize">Save Invoice</span>
        </button>
        <button
          type="button"
          onClick={ClearAll}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#3e3e3e] bg-[#3e3e3e]  rounded-3xl hover:bg-[#2c2c2c] hover:text-white"
        >
          <span className="capitalize">Cancel</span>
        </button>
      </div>
    </div>
  );
};

export default Hawb;
