import React, { useEffect, useState } from "react";
import Dropdown from "../components/Dropdown";
import TextInput from "../components/TextInput";
import ViewFiled from "../components/ViewFiled";

import { AirExport, AirImport, SeaExport } from "../data/dummy";
import { useParams, useNavigate, Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../contexts/ContextProvider";

import {
  collection,
  addDoc,
  query,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Datepicker from "../components/Datepicker";
import { AiOutlineClose } from "react-icons/ai";

const EditInvoice = () => {
  //Dropdown Data Load
  const [DraftData, setDraftData] = useState({});
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const [currList, setCurrList] = useState([]);
  const [ToList, setToList] = useState([]);
  const [TosList, setTosList] = useState([]);
  const [ShipperNameList, setShipperNameList] = useState([]);
  const [consinList, setConsinList] = useState([]);
  const [PolList, setPolList] = useState([]);
  const [DestinationList, setDestinationList] = useState([]);
  const [BankNameList, setBankNameList] = useState([]);
  const [logisticsList, setLogisticsList] = useState([]);
  const [JobStatusList, setJobStatusList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const [PaymentModeList, setPaymentModeList] = useState([]);
  const [UnitList, setUnitList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showBdt, setShowBdt] = useState(false);
  const [showUSD, setShowUSD] = useState(false);
  const [Serial, setSerial] = useState(0);
  const { user, role } = useStateContext();

  const [DetailsInfo, setDetailsInfo] = useState({
    Description: "",
    ContainerNo: " ",
    ContainerSize: "",

    ContainerQty: 0.0,
    Currency: "",
    CurrencyRate: 0.0,
    Unit: "",
    Usd: 0.0,
    TotalUsd: 0.0,
    Bdt: 0.0,
  });

  const [DetailsList, setDetailsList] = useState([]);

  const [GeneralInfo, setGeneralInfo] = useState({});

  const { id } = useParams();

  const invoiceType = new URLSearchParams(window.location.search).get(
    "invoiceType"
  );

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (invoiceType.includes("AIR") || invoiceType.includes("AEXP")) {
        await getDoc(doc(collection(db, "AIR Reports"), id)).then(
          async (snapshot) => {
            if (snapshot.exists()) {
              setGeneralInfo(snapshot.data().GeneralInfo);

              setDetailsList(snapshot.data().DetailsInfo);
              console.log(snapshot.data().DetailsInfo);
            }
          }
        );
      } else {
        await getDoc(doc(collection(db, "SEA Reports"), id)).then(
          async (snapshot) => {
            if (snapshot.exists()) {
              setGeneralInfo(snapshot.data().GeneralInfo);
              setDetailsList(snapshot.data().DetailsInfo);
            }
          }
        );
      }
    };

    getData();
  }, []);

  //Dropdown Data Load
  useEffect(() => {
    //Dropdown Data Load
    const invoiceRef = query(collection(db, "InvoiceType"));
    const currencyRef = query(collection(db, "Currency"));
    const ToRef = query(collection(db, "To"));
    const TosRef = query(collection(db, "Tos"));
    const PolRef = query(collection(db, "Pol"));
    const LogisticsRef = query(collection(db, "Logistics"));
    const JobStatusRef = query(collection(db, "JobStatus"));
    const UnitRef = query(collection(db, "Unit"));
    const DestinationRef = query(collection(db, "Destination"));
    const DescriptionRef = query(collection(db, "Description"));
    const EtdRef = query(collection(db, "Etd"));
    const PaymentModeRef = query(collection(db, "PaymentMode"));
    const BankNameRef = query(collection(db, "BankName"));
    const ShipperNameRef = query(collection(db, "ShipperName"));
    const ConsignRef = query(collection(db, "Consigne"));

    //Dropdown Data Load
    const invoiceList = onSnapshot(invoiceRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setInvoiceTypeList(tempArr);
    });

    const Currencylist = onSnapshot(currencyRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setCurrList(tempArr);
    });

    const Tolist2 = onSnapshot(ToRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setToList(tempArr);
    });

    const Unitlist2 = onSnapshot(UnitRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setUnitList(tempArr);
    });

    const ShipperNameList2 = onSnapshot(ShipperNameRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setShipperNameList(tempArr);
    });

    const ConsigneeList2 = onSnapshot(ConsignRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setConsinList(tempArr);
    });

    const PolList2 = onSnapshot(PolRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setPolList(tempArr);
    });

    const DestList = onSnapshot(DestinationRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setDestinationList(tempArr);
    });

    const BNList = onSnapshot(BankNameRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setBankNameList(tempArr);
    });

    const LogList = onSnapshot(LogisticsRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setLogisticsList(tempArr);
    });

    const JSList = onSnapshot(JobStatusRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setJobStatusList(tempArr);
    });

    const TosList2 = onSnapshot(TosRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setTosList(tempArr);
    });

    const DesList = onSnapshot(DescriptionRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setDescriptionList(tempArr);
    });

    const Paymodelist = onSnapshot(PaymentModeRef, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data() });
      });
      setPaymentModeList(tempArr);
    });

    return () => {
      //Dropdown Data Load
      invoiceList();
      Currencylist();
      Tolist2();
      Unitlist2();
      PolList2();
      DestList();
      BNList();
      LogList();
      JSList();
      TosList2();
      DesList();
      Paymodelist();
      ShipperNameList2();
      ConsigneeList2();
    };
  }, [user.uid]);

  useEffect(() => {
    const getBankDetails = async () => {
      if (GeneralInfo.BName != " ") {
        const q = query(
          collection(db, "BankName"),
          where("name", "==", GeneralInfo.BName)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setGeneralInfo((prevState) => ({
              ...prevState,
              BAddress: doc.data().address,
            }));
          });
        }
      }
    };

    getBankDetails();
  }, [GeneralInfo.BName]);

  const goBackHandler = () => {
    navigate(-1);
  };

  const updateDocument = async () => {
    try {
      if (invoiceType.includes("AIR") || invoiceType.includes("AEXP")) {
        await updateDoc(doc(collection(db, "AIR Reports"), id), {
          GeneralInfo: GeneralInfo,
          DetailsInfo: DetailsList,
        });
      } else {
        await updateDoc(doc(collection(db, "SEA Reports"), id), {
          GeneralInfo: GeneralInfo,
          DetailsInfo: DetailsList,
        });
      }
      toast.success("Updated Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const UpdateHandler = () => {
    updateDocument().then(() => {
      const q = query(
        collection(db, "EditReports"),
        where("id", "==", `${id}`)
      );
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          deleteDoc(doc.ref)
            .then(() => {})
            .catch((error) => {
              console.error("Error removing document: ", error);
            });
        });
      });
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGeneralInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;

    setDetailsInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const AddDetails = (event) => {
    event.preventDefault();
    if (
      GeneralInfo.InvoiceType == "SEA Export Invoice" ||
      GeneralInfo.InvoiceType == "Local Charge(SEXP)"
    ) {
      const newDetails = {
        Description: event.target.Description.value,
        ContainerNo: "none",
        ContainerSize: "none",
        ContainerQty: event.target.ContainerQty.value,
        Currency: event.target.Currency.value,
        CurrencyRate: showBdt ? event.target.Usd.value : 0.0,
        Unit: event.target.Unit.value,
        Usd: event.target.Usd.value,
        TotalUsd: showBdt ? 0.0 : event.target.TotalUsd.value,
        Bdt: event.target.Bdt.value,
      };
      setDetailsList([...DetailsList, newDetails]);
    } else if (GeneralInfo.InvoiceType == "Local Charge(AEXP)") {
      const newDetails = {
        Description: event.target.Description.value,
        ContainerNo: event.target.ContainerNo.value,
        ContainerSize: event.target.ContainerSize.value,
        ContainerQty: event.target.ContainerQty.value,
        Currency: event.target.Currency.value,
        CurrencyRate: showUSD ? event.target.CurrencyRate.value : 0.0,
        Unit: " None",
        Usd: event.target.Usd.value,
        TotalUsd: showUSD ? event.target.TotalUsd.value : 0.0,
        Bdt: event.target.Bdt.value,
      };
      setDetailsList([...DetailsList, newDetails]);
    } else {
      const newDetails = {
        Description: event.target.Description.value,
        ContainerNo: event.target.ContainerNo.value,
        ContainerSize: event.target.ContainerSize.value,
        ContainerQty: event.target.ContainerQty.value,
        Currency: event.target.Currency.value,
        CurrencyRate: event.target.CurrencyRate.value,
        Unit: " None",
        Usd: event.target.Usd.value,
        TotalUsd: event.target.TotalUsd.value,
        Bdt: event.target.Bdt.value,
      };
      setDetailsList([...DetailsList, newDetails]);
    }
    setDetailsInfo({
      Description: "",
      ContainerNo: "",
      ContainerSize: "",
      ContainerQty: 0.0,
      Currency: "",
      CurrencyRate: 0.0,
      Unit: "",
      Usd: 0.0,
      TotalUsd: 0.0,
      Bdt: 0.0,
    });
  };

  const handleDeleteRow = (index) => {
    const updatedList = [...DetailsList];
    updatedList.splice(index, 1);
    setDetailsList(updatedList);
  };

  return (
    <div className="pt-20 md:pt-2 xl:pt-2">
      <ToastContainer />
      <div className="ml-auto pl-10 pr-5">
        <p className="flex justify-between text-[#FF5C8E] font-bold pt-2 pb-2">
          General Information
          <span className="font-normal text-gray-700 text-xs">
            {GeneralInfo.InvoiceNum}
          </span>
        </p>
        <hr />
        <div className="flex flex-wrap pl-10 justify-between items-center">
          <ViewFiled name="Invoice Type" value={GeneralInfo.InvoiceType} />
          <TextInput
            label="Invoice Date"
            name="InvoiceDate"
            value={GeneralInfo.InvoiceDate}
            onChange={handleInputChange}
          />
          <Dropdown
            label="Destination"
            name="Destination"
            value={GeneralInfo.Destination}
            onChange={handleInputChange}
          >
            {DestinationList.map((item) => (
              <option>{item.dest}</option>
            ))}
          </Dropdown>

          <TextInput
            label="ETA"
            name="Eta"
            value={GeneralInfo.Eta}
            onChange={handleInputChange}
          />
          <TextInput
            label="ETD"
            name="Etd"
            value={GeneralInfo.Etd}
            onChange={handleInputChange}
          />
          <Dropdown
            label="Buying Currency"
            name="Bcurr"
            value={GeneralInfo.Bcurr}
            onChange={handleInputChange}
          >
            {currList.map((item) => (
              <option>{item.name}</option>
            ))}
          </Dropdown>
          <TextInput
            type="text"
            label="Buying Currency Rate"
            name="BcurrRate"
            value={GeneralInfo.BcurrRate}
            onChange={handleInputChange}
          />

          <Dropdown
            label="Selling Currency"
            name="Bcurr"
            value={GeneralInfo.Scurr}
            onChange={handleInputChange}
          >
            {currList.map((item) => (
              <option>{item.name}</option>
            ))}
          </Dropdown>
          <TextInput
            type="text"
            label="Selling Currency Rate"
            name="ScurrRate"
            value={GeneralInfo.ScurrRate}
            onChange={handleInputChange}
          />
          <Dropdown
            label="Consignee/Notify"
            name="Consignee"
            value={GeneralInfo.Consignee}
            onChange={handleInputChange}
          >
            {consinList.map((item) => (
              <option>{item.name}</option>
            ))}
          </Dropdown>
          {GeneralInfo.InvoiceType &&
          GeneralInfo.InvoiceType.includes("Import") ? (
            <TextInput
              type="text"
              label="L/C No"
              name="LcNo"
              value={GeneralInfo.LcNo}
              onChange={handleInputChange}
            />
          ) : (
            <TextInput
              type="text"
              label="Com.Invoice.No"
              name="ComInvNo"
              value={GeneralInfo.ComInvNo}
              onChange={handleInputChange}
            />
          )}

          <Dropdown
            label="TO"
            name="To"
            value={GeneralInfo.To}
            onChange={handleInputChange}
          >
            {ToList.map((item) => (
              <option>{item.desc}</option>
            ))}
          </Dropdown>

          <Dropdown
            label="Shipper Name"
            name="ShipName"
            value={GeneralInfo.ShipName}
            onChange={handleInputChange}
          >
            {ShipperNameList.map((item) => (
              <option>{item.name}</option>
            ))}
          </Dropdown>
          {GeneralInfo.InvoiceType &&
            GeneralInfo.InvoiceType === "AIR Import Invoice" && (
              <TextInput
                type="text"
                label="AIR INV"
                name="AirInv"
                value={GeneralInfo.AirInv}
                onChange={handleInputChange}
              />
            )}
          {GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE") ? (
            <TextInput
              type="text"
              label="TUES"
              name="Tues"
              value={GeneralInfo.Tues}
              onChange={handleInputChange}
            />
          ) : (
            <TextInput
              type="text"
              label="Ch.Weight(kg.)"
              name="ChWeight"
              value={GeneralInfo.ChWeight}
              onChange={handleInputChange}
            />
          )}
          <TextInput
            type="text"
            label={
              GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE")
                ? "HBL No"
                : "HAWB No"
            }
            name="Hbl"
            value={GeneralInfo.Hbl}
            onChange={handleInputChange}
          />
          <TextInput
            type="text"
            label={
              GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE")
                ? "MBL No"
                : "MAWB No"
            }
            name="Mbl"
            value={GeneralInfo.Mbl}
            onChange={handleInputChange}
          />
          <TextInput
            type="text"
            label={
              GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE")
                ? "FDR Vessel"
                : "Flight No"
            }
            name="FdrVessel"
            value={GeneralInfo.FdrVessel}
            onChange={handleInputChange}
          />
          {GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE") ? (
            <TextInput
              type="text"
              label="VOY No"
              name="Voy"
              value={GeneralInfo.Voy}
              onChange={handleInputChange}
            />
          ) : (
            ""
          )}
          <Dropdown
            label="POL"
            name="Pol"
            value={GeneralInfo.Pol}
            onChange={handleInputChange}
          >
            {PolList.map((item) => (
              <option>{item.desc}</option>
            ))}
          </Dropdown>
          <Dropdown
            label="Payment Mode"
            name="PaymentMode"
            value={GeneralInfo.PaymentMode}
            onChange={handleInputChange}
          >
            {PaymentModeList.map((item) => (
              <option>{item.mode}</option>
            ))}
          </Dropdown>

          <Dropdown
            label="Bank Name"
            name="BName"
            value={GeneralInfo.BName}
            onChange={handleInputChange}
          >
            {BankNameList.map((item) => (
              <option>{item.name}</option>
            ))}
          </Dropdown>
          <TextInput
            type="text"
            label="Bank Address"
            name="BAddress"
            value={GeneralInfo.BAddress}
            onChange={handleInputChange}
          />

          <Dropdown
            label="Logistics Agent"
            name="Logistics"
            value={GeneralInfo.Logistics}
            onChange={handleInputChange}
          >
            {logisticsList.map((item) => (
              <option>{item.desc}</option>
            ))}
          </Dropdown>
          <TextInput
            type="text"
            label="Job No"
            name="JobNo"
            value={GeneralInfo.JobNo}
            onChange={handleInputChange}
          />

          <Dropdown
            label="Job Status"
            name="JobStatus"
            value={GeneralInfo.JobStatus}
            onChange={handleInputChange}
          >
            {JobStatusList.map((item) => (
              <option>{item.status}</option>
            ))}
          </Dropdown>
          <Dropdown
            label="Destination"
            name="Destination"
            value={GeneralInfo.Destination}
            onChange={handleInputChange}
          >
            {DestinationList.map((item) => (
              <option>{item.dest}</option>
            ))}
          </Dropdown>
          {GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE") ? (
            <Dropdown
              label="TOS"
              name="Tos"
              value={GeneralInfo.Tos}
              onChange={handleInputChange}
            >
              {TosList.map((item) => (
                <option>{item.desc}</option>
              ))}
            </Dropdown>
          ) : (
            ""
          )}

          <TextInput
            type="text"
            label="Sales Person"
            name="SalePer"
            value={GeneralInfo.SalePer}
            onChange={handleInputChange}
          />
          <TextInput
            type="text"
            label="Exp No"
            name="ExpNo"
            value={GeneralInfo.ExpNo}
            onChange={handleInputChange}
          />

          {GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE") ? (
            <TextInput
              type="text"
              label="Mother Vessel"
              name="MotherVessel"
              value={GeneralInfo.MotherVessel}
              onChange={handleInputChange}
            />
          ) : (
            ""
          )}
          {GeneralInfo.InvoiceType &&
          (GeneralInfo.InvoiceType === "SEA Export Invoice" ||
            GeneralInfo.InvoiceType === "Local Charge(SEXP)") ? (
            ""
          ) : (
            <TextInput
              type="text"
              label="Proof of Delivery"
              name="Proof"
              value={GeneralInfo.Proof}
              onChange={handleInputChange}
            />
          )}
          <TextInput
            type="text"
            label={
              GeneralInfo.InvoiceType && GeneralInfo.InvoiceType.includes("SE")
                ? "Shipping Lines"
                : "AirLines"
            }
            name="ShippingLine"
            value={GeneralInfo.ShippingLine}
            onChange={handleInputChange}
          />
          {GeneralInfo.InvoiceType &&
          (GeneralInfo.InvoiceType === "SEA Export Invoice" ||
            GeneralInfo.InvoiceType === "Local Charge(SEXP)") ? (
            <>
              <div className="flex flex-col w-full md:w-2/3 px-3 pb-3 pt-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Container No/Size
                </label>
                <input
                  type="text"
                  name="ContainerNum"
                  value={GeneralInfo.ContainerNum}
                  onChange={handleInputChange}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="ml-auto pl-10 pr-5">
        <p className="text-[#FF5C8E] font-bold pt-2 pb-2">Detail Information</p>
        <hr />

        {GeneralInfo.InvoiceType &&
        GeneralInfo.InvoiceType.includes("Local") ? (
          <>
            {showBdt == false && showUSD == false ? (
              <div className="flex flex-row gap-3">
                <div className="flex px-1 pb-3 pt-2">
                  <button
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowBdt(true);
                    }}
                    className="rounded-full text-white py-3 px-5 bg-gray-800 text-xs"
                  >
                    Show BDT Calculation
                  </button>
                </div>
                <div className="flex px-1 pb-3 pt-2">
                  <button
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowUSD(true);
                    }}
                    className="rounded-full text-white py-3 px-5 bg-gray-800 text-xs"
                  >
                    Show USD Calculation
                  </button>
                </div>
              </div>
            ) : (
              <>
                <form className="w-full pt-3 pb-3" onSubmit={AddDetails}>
                  <div className="flex flex-wrap justify-between items-center ml-auto pl-10 pr-5">
                    <Dropdown
                      label="Description"
                      name="Description"
                      value={DetailsInfo.Description}
                      onChange={handleDetailsChange}
                    >
                      {descriptionList.map((item) => (
                        <option>{item.desc}</option>
                      ))}
                    </Dropdown>

                    {GeneralInfo.InvoiceType &&
                    (GeneralInfo.InvoiceType == "Local Charge(SEXP)" ||
                      GeneralInfo.InvoiceType == "SEA Export Invoice") ? (
                      ""
                    ) : (
                      <>
                        <TextInput
                          type="text"
                          label={
                            GeneralInfo.InvoiceType &&
                            GeneralInfo.InvoiceType.includes("SEA")
                              ? "Container No"
                              : "HAWB No"
                          }
                          name="ContainerNo"
                          value={DetailsInfo.ContainerNo}
                          onChange={handleDetailsChange}
                        />
                        <TextInput
                          type="text"
                          label={
                            GeneralInfo.InvoiceType &&
                            GeneralInfo.InvoiceType.includes("SEA")
                              ? "Container Size"
                              : "Package"
                          }
                          name="ContainerSize"
                          value={DetailsInfo.ContainerSize}
                          onChange={handleDetailsChange}
                        />
                      </>
                    )}

                    {GeneralInfo.InvoiceType &&
                    (GeneralInfo.InvoiceType == "Local Charge(SEXP)" ||
                      GeneralInfo.InvoiceType == "SEA Export Invoice") ? (
                      <TextInput
                        type="number"
                        label="QTY"
                        name="ContainerQty"
                        value={DetailsInfo.ContainerQty}
                        onChange={handleDetailsChange}
                      />
                    ) : (
                      <TextInput
                        type="number"
                        label={
                          GeneralInfo.InvoiceType &&
                          GeneralInfo.InvoiceType.includes("SEA")
                            ? "Container QTY"
                            : "Ch.Weight(KG)"
                        }
                        name="ContainerQty"
                        value={DetailsInfo.ContainerQty}
                        onChange={handleDetailsChange}
                      />
                    )}
                    {GeneralInfo.InvoiceType &&
                      (GeneralInfo.InvoiceType == "SEA Export Invoice" ||
                        GeneralInfo.InvoiceType == "Local Charge(SEXP)") && (
                        <Dropdown
                          label="Unit"
                          name="Unit"
                          value={DetailsInfo.Unit}
                          onChange={handleDetailsChange}
                        >
                          {UnitList.map((item) => (
                            <option>{item.name}</option>
                          ))}
                        </Dropdown>
                      )}

                    <TextInput
                      label={showBdt ? "BDT/KG" : "USD/KG"}
                      type="number"
                      name="Usd"
                      value={DetailsInfo.Usd}
                      onChange={handleDetailsChange}
                    />

                    {showUSD && (
                      <TextInput
                        disabled
                        label="Total USD"
                        type="number"
                        name="TotalUsd"
                        value={DetailsInfo.Usd * DetailsInfo.ContainerQty}
                        onChange={handleDetailsChange}
                      />
                    )}
                    <Dropdown
                      label="Currency"
                      name="Currency"
                      value={DetailsInfo.Currency}
                      onChange={handleDetailsChange}
                    >
                      {currList.map((item) => (
                        <option>{item.name}</option>
                      ))}
                    </Dropdown>

                    {showUSD && (
                      <TextInput
                        label="Currency Rate"
                        type="number"
                        name="CurrencyRate"
                        value={DetailsInfo.CurrencyRate}
                        onChange={handleDetailsChange}
                      />
                    )}
                    {showUSD ? (
                      <TextInput
                        disabled
                        label="BDT"
                        type="number"
                        name="Bdt"
                        value={
                          DetailsInfo.CurrencyRate *
                          DetailsInfo.Usd *
                          DetailsInfo.ContainerQty
                        }
                        onChange={handleDetailsChange}
                      />
                    ) : (
                      <TextInput
                        label="BDT"
                        type="number"
                        name="Bdt"
                        value={DetailsInfo.Bdt}
                        onChange={handleDetailsChange}
                      />
                    )}

                    {/* <TextInput
              disabled
              label="BDT"
              type="number"
              name="Bdt"
              value={
                DetailsInfo.CurrencyRate *
                DetailsInfo.Usd *
                DetailsInfo.ContainerQty
              }
              onChange={handleDetailsChange}
            /> */}
                  </div>
                  <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
                    <button
                      type="submit"
                      className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
                    >
                      <span className="capitalize">Add</span>
                    </button>
                  </div>
                </form>
              </>
            )}
          </>
        ) : (
          <form className="w-full pt-3 pb-3" onSubmit={AddDetails}>
            <div className="flex flex-wrap justify-between items-center ml-auto pl-10 pr-5">
              <Dropdown
                label="Description"
                name="Description"
                value={DetailsInfo.Description}
                onChange={handleDetailsChange}
              >
                {descriptionList.map((item) => (
                  <option>{item.desc}</option>
                ))}
              </Dropdown>

              {GeneralInfo.InvoiceType &&
              (GeneralInfo.InvoiceType == "Local Charge(SEXP)" ||
                GeneralInfo.InvoiceType == "SEA Export Invoice") ? (
                ""
              ) : (
                <>
                  <TextInput
                    type="text"
                    label={
                      GeneralInfo.InvoiceType &&
                      GeneralInfo.InvoiceType.includes("SEA")
                        ? "Container No"
                        : "HAWB No"
                    }
                    name="ContainerNo"
                    value={DetailsInfo.ContainerNo}
                    onChange={handleDetailsChange}
                  />
                  <TextInput
                    type="text"
                    label={
                      GeneralInfo.InvoiceType &&
                      GeneralInfo.InvoiceType.includes("SEA")
                        ? "Container Size"
                        : "Package"
                    }
                    name="ContainerSize"
                    value={DetailsInfo.ContainerSize}
                    onChange={handleDetailsChange}
                  />
                </>
              )}

              {GeneralInfo.InvoiceType &&
              (GeneralInfo.InvoiceType == "Local Charge(SEXP)" ||
                GeneralInfo.InvoiceType == "SEA Export Invoice") ? (
                <TextInput
                  type="number"
                  label="QTY"
                  name="ContainerQty"
                  value={DetailsInfo.ContainerQty}
                  onChange={handleDetailsChange}
                />
              ) : (
                <TextInput
                  type="number"
                  label={
                    GeneralInfo.InvoiceType &&
                    GeneralInfo.InvoiceType.includes("SEA")
                      ? "Container QTY"
                      : "Ch.Weight(KG)"
                  }
                  name="ContainerQty"
                  value={DetailsInfo.ContainerQty}
                  onChange={handleDetailsChange}
                />
              )}
              {GeneralInfo.InvoiceType &&
                (GeneralInfo.InvoiceType == "SEA Export Invoice" ||
                  GeneralInfo.InvoiceType == "Local Charge(SEXP)") && (
                  <Dropdown
                    label="Unit"
                    name="Unit"
                    value={DetailsInfo.Unit}
                    onChange={handleDetailsChange}
                  >
                    {UnitList.map((item) => (
                      <option>{item.name}</option>
                    ))}
                  </Dropdown>
                )}
              {GeneralInfo.InvoiceType &&
              GeneralInfo.InvoiceType.includes("SEA") ? (
                <TextInput
                  label="USD/CN"
                  type="number"
                  name="Usd"
                  value={DetailsInfo.Usd}
                  onChange={handleDetailsChange}
                />
              ) : (
                <TextInput
                  label="USD/KG"
                  type="number"
                  name="Usd"
                  value={DetailsInfo.Usd}
                  onChange={handleDetailsChange}
                />
              )}

              <TextInput
                disabled
                label="Total USD"
                type="number"
                name="TotalUsd"
                value={DetailsInfo.Usd * DetailsInfo.ContainerQty}
                onChange={handleDetailsChange}
              />
              <Dropdown
                label="Currency"
                name="Currency"
                value={DetailsInfo.Currency}
                onChange={handleDetailsChange}
              >
                {currList.map((item) => (
                  <option>{item.name}</option>
                ))}
              </Dropdown>

              <TextInput
                label="Currency Rate"
                type="number"
                name="CurrencyRate"
                value={DetailsInfo.CurrencyRate}
                onChange={handleDetailsChange}
              />
              <TextInput
                disabled
                label="BDT"
                type="number"
                name="Bdt"
                value={
                  DetailsInfo.CurrencyRate *
                  DetailsInfo.Usd *
                  DetailsInfo.ContainerQty
                }
                onChange={handleDetailsChange}
              />

              {/* <TextInput
              disabled
              label="BDT"
              type="number"
              name="Bdt"
              value={
                DetailsInfo.CurrencyRate *
                DetailsInfo.Usd *
                DetailsInfo.ContainerQty
              }
              onChange={handleDetailsChange}
            /> */}
            </div>
            <div className="flex flex-wrap  items-center ml-auto pl-10 pr-5">
              <button
                type="submit"
                className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
              >
                <span className="capitalize">Add</span>
              </button>
            </div>
          </form>
        )}

        <div className="w-full pt-3 pb-3 pl-10 pr-5 relative overflow-x-auto">
          <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="p-2 border border-gray-400">Description</th>
                {GeneralInfo.InvoiceType &&
                (GeneralInfo.InvoiceType == "SEA Export Invoice" ||
                  GeneralInfo.InvoiceType == "Local Charge(SEXP)") ? (
                  <>
                    <th className="p-2 border border-gray-400">Unit</th>
                    <th className="p-2 border border-gray-400">Qty</th>
                  </>
                ) : (
                  <>
                    {" "}
                    <th className="p-2 border border-gray-400">
                      {GeneralInfo.InvoiceType &&
                      GeneralInfo.InvoiceType.includes("SEA")
                        ? "Container No"
                        : "HAWB No"}
                    </th>
                    <th className="p-2 border border-gray-400">
                      {GeneralInfo.InvoiceType &&
                      GeneralInfo.InvoiceType.includes("SEA")
                        ? "Container Size"
                        : "Package"}
                    </th>
                    <th className="p-2 border border-gray-400">
                      {GeneralInfo.InvoiceType &&
                      GeneralInfo.InvoiceType.includes("SEA")
                        ? "Container Qty"
                        : "Ch.Weight(KG)"}
                    </th>
                  </>
                )}

                {showBdt ? (
                  <th className="p-2 border border-gray-400">BDT/KG</th>
                ) : (
                  <th className="p-2 border border-gray-400">
                    {GeneralInfo.InvoiceType &&
                    GeneralInfo.InvoiceType.includes("SEA")
                      ? "USD/CN"
                      : "USD/KG"}
                  </th>
                )}
                <th className="p-2 border border-gray-400">Total USD</th>
                <th className="p-2 border border-gray-400">BDT Amount</th>
                <th className="p-2 border border-gray-400">Action</th>
              </tr>
            </thead>
            <tbody>
              {DetailsList &&
                DetailsList.map((data, index) => {
                  const totalUsd = Number(data.TotalUsd); // Convert to number
                  const bdt = Number(data.Bdt); // Convert to number

                  return (
                    <tr key={index}>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {data.Description}
                      </td>
                      {GeneralInfo.InvoiceType &&
                      (GeneralInfo.InvoiceType == "SEA Export Invoice" ||
                        GeneralInfo.InvoiceType == "Local Charge(SEXP)") ? (
                        <>
                          <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                            {data.Unit}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                            {data.ContainerNo}
                          </td>
                          <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                            {data.ContainerSize}
                          </td>
                        </>
                      )}
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {data.ContainerQty}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {data.Usd}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {Number(totalUsd.toFixed(2)).toLocaleString()}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {Number(bdt.toFixed(2)).toLocaleString()}
                      </td>
                      <td className="p-2 border border-gray-400 items-center text-gray-800 font-semibold">
                        <button
                          onClick={() => handleDeleteRow(index)}
                          className="rounded-full p-2 bg-gray-800 text-white"
                        >
                          <AiOutlineClose />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {/* <div className="w-full pt-3 pb-3 pl-10 pr-5 relative overflow-x-auto">
          <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              {GeneralInfo.InvoiceType &&
              GeneralInfo.InvoiceType.includes("AIR") ? (
                <tr>
                  <th className="p-2 border border-gray-400">Description</th>
                  <th className="p-2 border border-gray-400">HAWB No</th>
                  <th className="p-2 border border-gray-400">PKG</th>
                  <th className="p-2 border border-gray-400">CH.W.</th>

                  <th className="p-2 border border-gray-400">Amount/KG</th>
                  <th className="p-2 border border-gray-400">Total USD</th>
                  <th className="p-2 border border-gray-400">BDT Amount</th>
                </tr>
              ) : (
                <tr>
                  <th className="p-2 border border-gray-400">Description</th>
                  <th className="p-2 border border-gray-400">Container No</th>
                  <th className="p-2 border border-gray-400">Cotainer Size</th>
                  <th className="p-2 border border-gray-400">Container Qty</th>

                  <th className="p-2 border border-gray-400">USD</th>
                  <th className="p-2 border border-gray-400">Total USD</th>
                  <th className="p-2 border border-gray-400">BDT Amount</th>
                </tr>
              )}
            </thead>
            <tbody>
              {DetailsInfo.map((data, index) => (
                <tr>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.Description}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.ContainerNo}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.ContainerSize}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.ContainerQty}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.Usd}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.TotalUsd}
                  </td>
                  <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                    {data.Bdt}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </div>

      <div className="flex ml-auto pl-10 mt-5 mb-10 pr-5">
        <button
          onClick={UpdateHandler}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#2e2e2e] bg-[#2e2e2e]  rounded-full hover:bg-[#181818] hover:text-white"
        >
          Update
        </button>
        <button
          onClick={goBackHandler}
          className="flex text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#1b9d80] bg-[#1b9d80]  rounded-full hover:bg-white hover:text-black"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default EditInvoice;
