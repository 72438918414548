import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

const HawbEdit = () => {
  const [SearchValue, setSearchValue] = useState();
  const { user, role } = useStateContext();

  const navigate = useNavigate();

  const [Data, setData] = useState();

  const getData = () => {
    if (role == "Air Export & Local Charge(AEXP)") {
      const q = query(collection(db, "HAWB Reports"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let tempArr = [];
        querySnapshot.forEach((doc) => {
          tempArr.push({ ...doc.data(), id: doc.id });
        });
        tempArr.sort((a, b) =>
          a.MainInfo.hawbNo.localeCompare(b.MainInfo.hawbNo)
        );

        setData(tempArr);
      });
    }
  };

  const HandleSearch = async (e) => {
    e.preventDefault();
    const q = query(
      collection(db, "HAWB Reports"),
      where("MainInfo.hawbNo", "==", SearchValue)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        navigate(`/HawbEdit/${doc.id}`);
      });
    } else {
      toast.warning("Wrong Invoice Number", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSearchValue();
    }
  };

  useEffect(() => {
    getData();
  }, [role]);
  return (
    <section className="bg-gray-100 dark:bg-gray-900">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
        <div className="w-full bg-slate-200 rounded-lg shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="w-full pt-3 pb-10 pl-10 pr-5 relative overflow-x-auto">
            <p className="text-center font-bold text-xl underline mt-5 mb-3">
              Edit Reports
            </p>

            <div className="flex flex-row justify-between ">
              <p className="text-14 font-bold pt-1">Report Search: </p>
              <div className="flex gap-3 mb-3">
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={SearchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="bg-gray-50 border w-96 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Hawb No...."
                  required
                />
                <button
                  type="submit"
                  className=" text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={(e) => HandleSearch(e)}
                >
                  Search
                </button>
              </div>
            </div>

            {Data && (
              <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="p-2 border border-gray-400">Hawb Num</th>
                    <th className="p-2 border border-gray-400">Place</th>
                    <th className="p-2 border border-gray-400">Executed On</th>

                    <th className="p-2 border border-gray-400">Flight Date</th>
                    <th className="p-2 border border-gray-400">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((item, index) => (
                    <tr key={index}>
                      <td className="p-2 border border-gray-400">
                        {item.MainInfo.hawbNo}
                      </td>
                      <td className="p-2 border border-gray-400">
                        {item.MainInfo.Place}
                      </td>
                      <td className="p-2 border border-gray-400">
                        {item.MainInfo.ExecutedOn}
                      </td>
                      <td className="p-2 border border-gray-400">
                        {item.MainInfo.FlightDate}
                      </td>
                      <td className="p-2 border border-gray-400">
                        <Link
                          to={`/HawbEdit/${item.id}`}
                          className="flex w-fit text-md ml-2 p-2 pr-5 pl-5 border text-white border-[#FF5C8E] bg-[#FF5C8E]  rounded-full hover:bg-white hover:text-black"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HawbEdit;
