import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import Pagination from "react-js-pagination";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Description = () => {
  const [descripton, setDescription] = useState();
  const [DescriptionList, setDescriptionList] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const ToastError = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const AddDescription = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "Description"), {
      desc: descripton,
    }).then(() => {
      setDescription("");
      toast.success("Successfully added", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    const q = query(collection(db, "Description"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        tempArr.push({ ...doc.data(), id: doc.id });
      });
      setDescriptionList(tempArr);
    });
    return () => unsubscribe();
  }, []);

  const handleEditClick = (item) => {
    setSelectedDesc(item);
    setDescription(item.desc);
  };

  const handleUpdateBankName = async (e) => {
    e.preventDefault();

    const bankRef = doc(db, "Description", selectedDesc.id);
    await updateDoc(bankRef, {
      desc: descripton,
    }).then(() => {
      setDescription(" ");
      setSelectedDesc(null);
      toast.success("Successfully updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemperpage = 8;

  const indexOfLastUser = currentPage * itemperpage;
  const indexOfFirstUser = indexOfLastUser - itemperpage;

  const currentList = DescriptionList
    ? DescriptionList.slice(indexOfFirstUser, indexOfLastUser)
    : null;
  return (
    <div className="flex flex-col items-center md:items-start gap-3  mt-20 px-6 py-8 mx-auto lg:py-0">
      <ToastContainer />
      <div className="w-full bg-white rounded-xl shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-md font-bold text-center leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            Add Description Type
          </h1>
          <form className="space-y-4 md:space-y-6" action="#">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Description
              </label>
              <input
                type="text"
                name="description"
                id="description"
                value={descripton}
                onChange={(e) => setDescription(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="description..."
                required
              />
            </div>

            <button
              type="submit"
              className="w-32 text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              onClick={selectedDesc ? handleUpdateBankName : AddDescription}
            >
              {selectedDesc ? "Update" : "Add"}
            </button>
          </form>
        </div>
      </div>

      <div className="w-full bg-white rounded-xl shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <p className="p-2 text-14 font-semibold bg-gray-800 text-white">
            List of Description
          </p>
          <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="p-2 border border-gray-400">SL No.</th>
                <th className="p-2 border border-gray-400">Description Name</th>
                <th className="p-2 border border-gray-400">Action</th>
              </tr>
            </thead>
            <tbody>
              {DescriptionList &&
                currentList.map((item, index) => {
                  const serialNumber = indexOfFirstUser + index + 1;
                  return (
                    <tr key={index}>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {serialNumber}
                      </td>
                      <td className="p-2 border border-gray-400 text-gray-800 font-semibold">
                        {item.desc}
                      </td>

                      <td className="px-4 py-2 w-32 font-medium text-xs text-center tracking-wider uppercase border-1 border-gray-700 bg-gray">
                        <button
                          onClick={() => handleEditClick(item)}
                          className="text-[#FF5C8E] hover:underline focus:outline-none"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="pagination flex  justify-start mt-4 ">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemperpage}
              totalItemsCount={DescriptionList ? DescriptionList.length : 0}
              pageRangeDisplayed={5}
              onChange={setCurrentPage}
              itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
              linkClass="block"
              activeLinkClass="text-gray-200"
              disabledClass="opacity-50 cursor-not-allowed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
