import React from "react";
import logo from "../data/logo-black.png";
import {
  MdLocalAirport,
  MdLocalOffer,
  MdOutlinePendingActions,
} from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { HiOutlineClipboardDocument } from "react-icons/hi2";

import { useStateContext } from "../contexts/ContextProvider";
import AdminDashboardCard from "../components/AdminDashboardCard";
import { AiOutlineUsergroupDelete } from "react-icons/ai";
import { RiShipFill } from "react-icons/ri";

const Home = () => {
  const { user, role } = useStateContext();

  return (
    <>
      {role && role == "Admin" ? (
        <>
          <div className="flex mt-20 ml-5 mr-5 gap-5 lg:py-0 md:mt-5 lg:mt-5">
            <AdminDashboardCard
              title="USER"
              number="15"
              descHead="Active"
              descText="Users registered"
              icon={<AiOutlineUsergroupDelete />}
              fromColor="#F79007"
              toColor="#F79007"
            />

            <AdminDashboardCard
              title="DRAFT"
              number="45"
              descHead="Reports"
              descText="are pending"
              icon={<MdOutlinePendingActions />}
              fromColor="#F04337"
              toColor="#f62415"
            />

            <AdminDashboardCard
              title="REPORTS"
              number="375"
              descHead="Total"
              descText="Reports
            Generated"
              icon={<TbReportAnalytics />}
              fromColor="#43d04a"
              toColor="#14b91c"
            />

            <AdminDashboardCard
              title="UPDATES"
              number="10"
              descHead="Reports"
              descText="needs
            correction"
              icon={<HiOutlineClipboardDocument />}
              fromColor="#4f52f2"
              toColor="#2a2ddc"
            />
          </div>
          <div className="flex mt-20 ml-5 mr-5 gap-5 lg:py-0 md:mt-5 lg:mt-5">
            <div className="w-1/3 h-48  bg-[#c6e4fc] rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="flex flex-col gap-3 items-center justify-between  font-semibold pt-5">
                <div className="text-[18px] text-white flex justify-center items-center bg-gradient-to-r from-[#2c6b9e] to-[#1e83d6] rounded-[50%] p-4 h-12 ">
                  <RiShipFill />
                </div>
                <h1 className="text-[32px] text-gray-900 font-bold">200</h1>
                <p className="text-[12px] text-gray-600">
                  <span className="text-[#FF5C8E]">Sea Export/Import</span>{" "}
                  Reports Generated
                </p>
              </div>
            </div>

            <div className="w-1/3 h-48  bg-[#fcd4bc] rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="flex flex-col gap-3 items-center justify-between  font-semibold pt-5">
                <div className="text-[18px] text-white flex justify-center items-center bg-gradient-to-r from-[#b66d40] to-[#bb6936] rounded-[50%] p-4 h-12 ">
                  <MdLocalAirport />
                </div>
                <h1 className="text-[32px] text-gray-900 font-bold">100</h1>
                <p className="text-[12px] text-gray-600">
                  <span className="text-[#FF5C8E]">Air Export/Import</span>{" "}
                  Reports Generated
                </p>
              </div>
            </div>

            <div className="w-1/3 h-48  bg-[#b2e4f6] rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="flex flex-col gap-3 items-center justify-between  font-semibold pt-5">
                <div className="text-[18px] text-white flex justify-center items-center bg-gradient-to-r from-[#1d8bb4] to-[#149acb] rounded-[50%] p-4 h-12 ">
                  <MdLocalOffer />
                </div>
                <h1 className="text-[32px] text-gray-900 font-bold">75</h1>
                <p className="text-[12px] text-gray-600">
                  <span className="text-[#FF5C8E]">
                    Local Charge Export/Import
                  </span>{" "}
                  Reports Generated
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex mt-20 ml-5 mr-5 gap-5 md:h-screen lg:py-0 md:mt-5 lg:mt-5">
          <div className="w-1/3 h-36  bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-between  font-semibold ">
              <div className="flex flex-col justify-center gap-3 ml-5 mt-5">
                <h4 className="text-[18px] text-gray-600 capitalize">DRAFT</h4>
                <h1 className="text-[32px] text-gray-900 font-bold">45</h1>
                <p className="text-[12px] text-gray-600">
                  <span className="text-[#FF5C8E] pr-1">Reports</span> are
                  pending in drafts
                </p>
              </div>
              <div className="text-[18px] text-white flex justify-center items-center bg-gradient-to-r from-[#F04337] to-[#f62415] rounded-[50%] p-4 h-12 mt-5 mr-6">
                <MdOutlinePendingActions />
              </div>
            </div>
          </div>

          <div className="w-1/3 h-36  bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-between  font-semibold ">
              <div className="flex flex-col justify-center gap-3 ml-5 mt-5">
                <h4 className="text-[18px] text-gray-600 capitalize">
                  REPORTS
                </h4>
                <h1 className="text-[32px] text-gray-900 font-bold">30</h1>
                <p className="text-[12px] text-gray-600">
                  <span className="text-[#FF5C8E] pr-1">Total</span> Reports
                  Generated
                </p>
              </div>
              <div className="text-[18px] text-white flex justify-center items-center bg-gradient-to-r from-[#43d04a] to-[#14b91c] rounded-[50%] p-4 h-12 mt-5 mr-6">
                <TbReportAnalytics />
              </div>
            </div>
          </div>

          <div className="w-1/3 h-36  bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-between  font-semibold ">
              <div className="flex flex-col justify-center gap-3 ml-5 mt-5">
                <h4 className="text-[18px] text-gray-600 capitalize">
                  UPDATES
                </h4>
                <h1 className="text-[32px] text-gray-900 font-bold">10</h1>
                <p className="text-[12px] text-gray-600">
                  <span className="text-[#FF5C8E] pr-1">Reports</span> may needs
                  a correction
                </p>
              </div>
              <div className="text-[18px] text-white flex justify-center items-center bg-gradient-to-r from-[#4f52f2] to-[#2a2ddc] rounded-[50%] p-4 h-12 mt-5 mr-6">
                <HiOutlineClipboardDocument />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
