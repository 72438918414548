import React from "react";
import {
  AiOutlineCalendar,
  AiOutlineShoppingCart,
  AiOutlineAreaChart,
  AiOutlineBarChart,
  AiOutlineStock,
  AiOutlineLogin,
  AiOutlineLogout,
  AiOutlineFileText,
  AiOutlineUnorderedList,
  AiFillContainer,
  AiFillEuroCircle,
  AiFillRightCircle,
  AiFillFolderAdd,
  AiFillHdd,
  AiFillRocket,
  AiFillShop,
  AiFillSliders,
  AiFillInsurance,
  AiFillProfile,
  AiFillRead,
  AiFillFileAdd,
} from "react-icons/ai";

import {
  BsKanban,
  BsBarChart,
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsChatLeft,
  BsMinecartLoaded,
} from "react-icons/bs";

import { MdBorderColor, MdOutlineSupervisorAccount } from "react-icons/md";

import { GiEarthAsiaOceania, GiLouvrePyramid } from "react-icons/gi";

export const AdminLinks = [
  {
    title: "User",
    links: [
      {
        name: "Add User",
        icon: <AiOutlineLogin />,
        url: "addUser",
      },
      {
        name: "User Details",
        icon: <AiOutlineUnorderedList />,
        url: "userDetails",
      },
    ],
  },

  {
    title: "Final Report Entry",
    links: [
      {
        name: "All Entry Reports",
        icon: <AiFillFileAdd />,
        url: "draftEntry",
      },
    ],
  },
  /* {
    title: "Entry",
    links: [
      {
        name: "SEA Entry",
        icon: <AiOutlineFileText />,
        url: "AdminSeaEntry",
        subMenu: [
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
          {
            name: "Invoice Type",
            url: "AdminSeaEntry",
          },
        ],
      },
      {
        name: "Shipping Order",
        icon: <BsMinecartLoaded />,
        url: "AdminShippingOrder",
      },
      {
        name: "S.Order Update",
        icon: <MdBorderColor />,
        url: "AdminOrderUpdate",
      },
    ],
  }, */

  {
    title: "Reports",
    links: [
      {
        name: "SEA Import/Export",
        icon: <GiEarthAsiaOceania />,
        url: "ViewSeaReports",
      },
      {
        name: "Air Import/Export",
        icon: <AiOutlineAreaChart />,
        url: "ViewAirReports",
      },

      /*  {
        name: "SEA Import Invoice Status",
        icon: <GrStatusCritical />,
        url: "SeaImportStatus",
      },
      {
        name: "SEA Export Invoice Status",
        icon: <GrStatusDisabled />,
        url: "SeaExportStatus",
      },
      {
        name: "AIR Import Invoice Status",
        icon: <GrStatusCritical />,
        url: "AirImportStatus",
      },
      {
        name: "AIR Export Invoice Status",
        icon: <GrStatusDisabled />,
        url: "AirExportStatus",
      }, */
    ],
  },
];

export const AirExport = [
  {
    title: "AIR Export Invoice",
  },
  {
    title: "Local Charge(AEXP)",
  },
];

export const AirImport = [
  {
    title: "AIR Import Invoice",
  },
  {
    title: "SEA Import Invoice",
  },
];

export const SeaImportExport = [
  {
    title: "SEA Import Invoice",
  },
  {
    title: "SEA Export Invoice",
  },
  {
    title: "Local Charge(SEXP)",
  },
];

export const SeaExport = [
  {
    title: "SEA Export Invoice",
  },
  {
    title: "Local Charge(SEXP)",
  },
];

export const Hawb = [
  {
    title: "Air Way Bill",
    links: [
      {
        name: "HAWB Entry",
        icon: <AiOutlineFileText />,
        url: "hawb",
      },
      {
        name: "Hawb Reports",
        icon: <BsMinecartLoaded />,
        url: "hawbsearch",
      },
      {
        name: "Edit Hawb Reports",
        icon: <AiOutlineFileText />,
        url: "hawbEditList",
      },
    ],
  },
];

export const Downloader = [
  {
    title: "Reports",
    links: [
      {
        name: "SEA Import/Export",
        icon: <GiEarthAsiaOceania />,
        url: "ViewSeaReports",
      },
      {
        name: "Air Import/Export",
        icon: <AiOutlineAreaChart />,
        url: "ViewAirReports",
      },
    ],
  },
];

export const SeaIEx = [
  {
    title: "Sea Import Export",
    links: [
      {
        name: "SEA Import",
        icon: <GiEarthAsiaOceania />,
        url: "ViewSeaReports",
      },
      {
        name: "Sea Export",
        icon: <AiOutlineAreaChart />,
        url: "ViewAirReports",
      },
    ],
  },
];

export const links = [
  {
    title: "Entry",
    links: [
      {
        name: "Invoice Entry",
        icon: <AiOutlineFileText />,
        url: "SeaEntry",
      },
      {
        name: "Shipping Order",
        icon: <BsMinecartLoaded />,
        url: "ShippingOrder",
      },
      {
        name: "S.Order Update",
        icon: <MdBorderColor />,
        url: "OrderUpdate",
      },
    ],
  },
  /* {
    title: "Draft Entry",
    links: [
      {
        name: "All Entry",
        icon: <AiFillFileAdd />,
        url: "draftEntry",
      },
    ],
  }, */

  {
    title: "Reports",
    links: [
      {
        name: "SEA Import/Export",
        icon: <GiEarthAsiaOceania />,
        url: "SeaImportExport",
      },
      {
        name: "Air Import/Export",
        icon: <AiOutlineAreaChart />,
        url: "AirImportExport",
      },
      {
        name: "Edit Reports",
        icon: <AiOutlineAreaChart />,
        url: "EditReports",
      },
      /* {
        name: "SEA Import Invoice Status",
        icon: <GrStatusCritical />,
        url: "SeaImportStatus",
      },
      {
        name: "SEA Export Invoice Status",
        icon: <GrStatusDisabled />,
        url: "SeaExportStatus",
      },
      {
        name: "AIR Import Invoice Status",
        icon: <GrStatusCritical />,
        url: "AirImportStatus",
      },
      {
        name: "AIR Export Invoice Status",
        icon: <GrStatusDisabled />,
        url: "AirExportStatus",
      }, */
    ],
  },
  {
    title: "DropDown Entry",
    links: [
      /* {
        name: "Invoice Type",
        url: "InvoiceType",
        icon: <AiFillFileAdd />,
      }, */
      {
        name: "Currency",
        url: "CurrancyInfo",
        icon: <AiFillEuroCircle />,
      },
      {
        name: "To",
        url: "To",
        icon: <AiFillRightCircle />,
      },
      /* {
        name: "HBL",
        url: "hbl",
        icon: <AiFillFolderAdd />,
      }, */
      /*  {
        name: "ETD",
        url: "etd",
        icon: <AiFillInsurance />,
      },
       */

      {
        name: "Payment Mode",
        url: "PaymentMode",
        icon: <AiFillInsurance />,
      },
      {
        name: "POL",
        url: "pol",
        icon: <AiFillHdd />,
      },
      {
        name: "Destination",
        url: "destination",
        icon: <AiFillRocket />,
      },
      {
        name: "Bank Details",
        url: "bankName",
        icon: <AiFillShop />,
      },
      {
        name: "Consignee",
        url: "consignee",
        icon: <AiFillProfile />,
      },
      {
        name: "Logistic Agent",
        url: "Logistics",
        icon: <AiFillSliders />,
      },
      {
        name: "Job Status",
        url: "JobStatus",
        icon: <AiFillInsurance />,
      },
      {
        name: "TOS",
        url: "tos",
        icon: <AiFillProfile />,
      },
      {
        name: "Description",
        url: "description",
        icon: <AiFillRead />,
      },
      {
        name: "Shipper Name",
        url: "shippername",
        icon: <AiFillProfile />,
      },
      {
        name: "Unit",
        url: "unit",
        icon: <AiFillFolderAdd />,
      },
    ],
  },

  /*  {
    title: "User",
    links: [
      {
        name: "Login",
        icon: <AiOutlineLogin />,
        url: "Login",
      },
      {
        name: "Logout",
        icon: <AiOutlineLogout />,
        url: "Logout",
      },
    ],
  }, */
];
