import React from "react";

const TextAreaInput = ({ label, children, ...rest }) => {
  return (
    <div className="flex flex-col px-3 pb-3 pt-2 ">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </label>
      <div className="resize-container">
        <textarea
          className=" resize-both appearance-none block w-full bg-gray-200 text-gray-700  border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          rows="1"
          cols="1"
          {...rest}
        >
          {children}
        </textarea>
      </div>
    </div>
  );
};

export default TextAreaInput;
