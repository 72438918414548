import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import Pagination from "react-js-pagination";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { parse, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStateContext } from "../contexts/ContextProvider";

import { useNavigate } from "react-router-dom";

const SeaReportView = () => {
  const [OriginalData, setOriginalData] = useState();
  const [isFilterApplied, setisFilterApplied] = useState(false);

  const [startDateFilter, setStartDateFilter] = useState(new Date());
  const [endDateFilter, setEndDateFilter] = useState(new Date());
  const { user, role } = useStateContext();
  const [SearchValue, setSearchValue] = useState();

  const [Data, setData] = useState();

  const navigate = useNavigate();

  const HandleSearch = async (e) => {
    e.preventDefault();
    const q = query(
      collection(db, "SEA Reports"),
      where("GeneralInfo.InvoiceNum", "==", SearchValue)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        navigate(`/SeaReports/${doc.id}`);
      });
    } else {
      toast.warning("Wrong Invoice Number", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSearchValue();
    }
  };

  const getData = async () => {
    if (role == "Viewer & Downloader" || role == "Admin") {
      const q = query(
        collection(db, "SEA Reports"),
        orderBy("GeneralInfo.InvoiceNum", "asc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let tempArr = [];
        querySnapshot.forEach((doc) => {
          tempArr.push({ ...doc.data() });
        });
        tempArr.sort((a, b) =>
          a.GeneralInfo.InvoiceNum.localeCompare(b.GeneralInfo.InvoiceNum)
        );

        setData(tempArr);
        setOriginalData(tempArr);
      });

      return () => {
        unsubscribe();
      };
    }
  };

  const convertToDateObject = (dateString) => {
    try {
      // Parse the date string into a Date object
      const dateObject = parse(dateString, "MMMM dd, yyyy", new Date());

      // Check if the Date object is valid
      if (!isNaN(dateObject.getTime())) {
        return dateObject;
      }
    } catch (error) {
      // If parsing fails, handle the error (optional)
      console.error("Error while parsing the date:", error);
    }
  };

  const filterDataByDateRange = (data) => {
    // If both start and end dates are not selected, return the original data
    if (!startDateFilter || !endDateFilter) {
      return data;
    }

    const newStartDate = startDateFilter.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const newEndDate = endDateFilter.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    // Convert the selected date range to Date objects
    const startDate = convertToDateObject(newStartDate);
    const endDate = convertToDateObject(newEndDate);

    console.log(startDate, endDate);

    // If the conversion failed, return the original data
    if (!startDate || !endDate) {
      return data;
    }

    // Filter the data based on the selected date range
    return data.filter((item) => {
      const invoiceDate = convertToDateObject(item.GeneralInfo.InvoiceDate);

      return invoiceDate >= startDate && invoiceDate <= endDate;
    });
  };

  const ApplyFilter = () => {
    setisFilterApplied(true);
    const filteredData = filterDataByDateRange(Data);
    setData(filteredData);
  };
  const clearFilter = () => {
    setStartDateFilter(new Date());
    setEndDateFilter(new Date());
    setData(OriginalData);
    setisFilterApplied(false);
  };

  //Sea reports Pagination

  const [currentAirPage, setCurrentAirPage] = useState(1);
  const AirreportsPerPage = 12;

  const filteredAirReports = Data
    ? Data.filter(
        (data) =>
          !SearchValue ||
          (data.GeneralInfo.InvoiceNum &&
            data.GeneralInfo.InvoiceNum.toLowerCase().includes(
              SearchValue.toLowerCase()
            ))
      )
    : [];
  const indexOfLastAirUser = currentAirPage * AirreportsPerPage;
  const indexOfFirstAirUser = indexOfLastAirUser - AirreportsPerPage;
  const currentAirList = filteredAirReports.slice(
    indexOfFirstAirUser,
    indexOfLastAirUser
  );

  useEffect(() => {
    getData();
  }, [role]);

  const exportFilteredToExcel = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    const fileName = `${formattedDate}-SeaReports.xlsx`;
    /*  const filteredData = Data.filter((item) => {
      const invoiceDate = new Date(item.GeneralInfo.InvoiceDate);
      // console.log(invoiceDate, formattedDate);
      return invoiceDate >= startDateFilter && invoiceDate <= endDateFilter;
    }); */
    const adjustedStartDate = startDateFilter
      ? new Date(startDateFilter)
      : null;
    if (adjustedStartDate) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
    }
    const filteredData = Data.filter((item) => {
      const invoiceDate = new Date(item.GeneralInfo.InvoiceDate);
      // console.log(invoiceDate, formattedDate);
      return invoiceDate >= adjustedStartDate && invoiceDate <= endDateFilter;
    });

    const formattedData = filteredData.map((item) => {
      //console.log(item);
      const toValue = item.GeneralInfo.To.split("<br>")[0];
      const totalAmount = item.DetailsInfo.reduce(
        (total, detail) => total + Number(detail.TotalUsd),
        0
      );
      const roundedTotalAmount = Number(
        totalAmount.toFixed(2)
      ).toLocaleString();
      const totalBdt = item.DetailsInfo.reduce(
        (total, detail) => total + Number(detail.Bdt),
        0
      );
      const roundedTotalBdt = Number(totalBdt.toFixed(2)).toLocaleString();
      return {
        "Name of Customer(TO)": toValue,
        "Invoice No.": item.GeneralInfo.InvoiceNum,
        "Invoice Date": item.GeneralInfo.InvoiceDate,
        Currency: item.GeneralInfo.Bcurr,
        "Currency Rate": item.GeneralInfo.BcurrRate,
        "Commercial InV No.": item.GeneralInfo.ComInvNo,
        "Job No.": item.GeneralInfo.JobNo,
        "Shipper Name": item.GeneralInfo.ShipName,
        "Consignee/Notify": item.GeneralInfo.Consignee,
        "CH Weight": item.GeneralInfo.ChWeight,
        "AIR INV": item.GeneralInfo.AirInv,
        "MAWB No.": item.GeneralInfo.Mbl,
        ETD: item.GeneralInfo.Etd,
        "Air Lines": item.GeneralInfo.ShippingLine,
        "Flight No.": item.GeneralInfo.FdrVessel,
        POL: item.GeneralInfo.Pol,
        ETA: item.GeneralInfo.Eta,
        Destination: item.GeneralInfo.Destination,
        "Payment Mode": item.GeneralInfo.PaymentMode,
        "HAWB No.": item.GeneralInfo.Hbl,
        "Total Amount": roundedTotalAmount,
        "Total BDT": roundedTotalBdt,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, fileName);
  };

  return (
    <section className="bg-gray-100 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
        <div className="w-full bg-slate-200 rounded-lg shadow dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="w-full pt-3 pb-10 pl-10 pr-5 relative overflow-x-auto">
            <p className="text-center font-bold text-xl underline mt-5 mb-3">
              Sea Reports
            </p>
            <div className="flex justify-end p-1">
              <button
                className="w-[1/5] rounded-lg p-3 font-bold font-xs text-white bg-gray-800 hover:bg-slate-900"
                onClick={exportFilteredToExcel}
              >
                Download Report
              </button>
            </div>
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center justify-between bg-gray-800 p-3 w-full rounded-tl-[10px] rounded-tr-[10px]">
                <div className="flex gap-3 mb-3 items-center justify-center pt-2">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    value={SearchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="bg-gray-50 border w-96 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Invoice No...."
                    required
                  />
                  <button
                    type="submit"
                    className=" text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    onClick={(e) => HandleSearch(e)}
                  >
                    Search
                  </button>
                </div>
                <div className="flex items-center gap-2 justify-start">
                  <div className="flex flex-row gap-2 items-center justify-center w-full">
                    <p className="text-white">From</p>
                    <DatePicker
                      selected={startDateFilter}
                      onChange={(date) => setStartDateFilter(date)}
                      className="text-12 p-2 w-[120px] rounded-lg"
                    />
                  </div>
                  <div className="flex flex-row gap-1 items-center justify-center w-full">
                    <p className="text-white">To</p>
                    <DatePicker
                      selected={endDateFilter}
                      onChange={(date) => setEndDateFilter(date)}
                      className="text-12 p-2 w-[120px] rounded-lg"
                    />
                  </div>
                  <button
                    type="submit"
                    className=" text-white bg-[#FF5C8E] hover:bg-[#b33058] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    onClick={() =>
                      isFilterApplied ? clearFilter() : ApplyFilter()
                    }
                  >
                    {isFilterApplied ? "Clear" : "Filter"}
                  </button>
                </div>
              </div>
            </div>
            {Data && (
              <>
                <table
                  id="table-to-export"
                  className="w-full text-xs text-left text-gray-500 dark:text-gray-400 border-collapse border border-gray-400"
                >
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th className="p-2 border border-gray-400">
                        Invoice Num
                      </th>
                      <th className="p-2 border border-gray-400">
                        Invoice Date
                      </th>
                      <th className="p-2 border border-gray-400">
                        Invoice Type
                      </th>

                      <th className="p-2 border border-gray-400">ETA</th>
                      <th className="p-2 border border-gray-400">ETD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAirList.map((item, index) => (
                      <tr key={index}>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceNum}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceDate}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.InvoiceType}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Eta}
                        </td>
                        <td className="p-2 border border-gray-400">
                          {item.GeneralInfo.Etd}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="pagination flex  justify-start mt-4 ">
                  <Pagination
                    activePage={currentAirPage}
                    itemsCountPerPage={AirreportsPerPage}
                    totalItemsCount={filteredAirReports.length}
                    pageRangeDisplayed={5}
                    onChange={setCurrentAirPage}
                    itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
                    linkClass="block"
                    activeLinkClass="text-gray-200"
                    disabledClass="opacity-50 cursor-not-allowed"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeaReportView;
